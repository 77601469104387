export function getHealthProfessional(jobDescription) {
  const healthProfessionalType = {
    'médico(a)': 'Sim, médico(a)',
    'enfermeiro(a)': 'Sim, enfermeiro(a)',
    default: 'Não',
  };

  return (
    healthProfessionalType[jobDescription] || healthProfessionalType.default
  );
}

export function getGender(type) {
  const genderType = {
    M: 'Masculino',
    F: 'Feminino',
  };

  return genderType[type];
}

export function getDocumentType(type) {
  const documentType = {
    CPF: 'cpf',
    RNE: 'rne',
    Passaporte: 'passport',
  };

  return documentType[type];
}
