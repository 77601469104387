import { useEffect, useState } from 'react';

import * as I from '@tempo/tempo-assets/dist/svgs/icons';
import * as DB2B from '@tempo/tempo-design-system-b2b/dist';
import * as D from '@tempo/tempo-design-system-core/dist';

import { getAgentCount } from '@b2b/core/src/services/agent.service';
import { updateStatusInstitute } from '@b2b/core/src/services/institute.service';
import propTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { ReactSVG } from 'react-svg';

// eslint-disable-next-line import/no-cycle
import { Analytics, Components, Hooks, Utils } from '../../index';
import { successMessages } from './enums';
import * as S from './styles';

export function Dashboard({ isBackOffice }) {
  const { getUser } = Hooks.useUser();
  const {
    getInstitute,
    getTranslateStatus,
    handleInstitute,
    loadingBackoffice,
    errorBackoffice,
    loadingDashBoardBackOffice,
  } = Hooks.useBackOffice();
  const { addDrawer, removeDrawer } = Hooks.useDrawer();
  const { code, name, cnpj } = isBackOffice ? getInstitute() : getUser();
  const [agentCount, setAgentCount] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const { addToast } = Hooks.useToast();

  function handleGetCountAgent(value) {
    setLoading(true);
    getAgentCount(value)
      .then(response => {
        setError(false);
        setAgentCount(response);
      })
      .catch(() => {
        setError(true);
      })
      .finally(() => setLoading(false));
  }

  function handleChangeStatus(statusCode) {
    function getToast(type, status) {
      const findMessage = successMessages.find(
        item => item.statusCode === status
      );
      addToast({
        type,
        title: findMessage.title,
        message: findMessage.message,
      });
    }
    updateStatusInstitute(getInstitute().code, statusCode)
      .then(() => {
        handleInstitute();
        getToast('success', statusCode);
        Analytics.logEvent(
          successMessages.find(item => item.statusCode === statusCode)
            .analyticsEvent,
          {},
          isBackOffice ? 'backoffice' : 'institute'
        );
      })
      .catch(({ response }) => {
        setError(true);
        Analytics.logEvent(
          successMessages.find(item => item.statusCode === statusCode)
            .analyticsEventError,
          {},
          isBackOffice ? 'backoffice' : 'institute'
        );
        addToast({
          type: 'error',
          title: Utils.getError('title', response) || 'Erro desconhecido',
          message: Utils.getError('message', response) || 'Erro desconhecido',
        });
      });
  }

  function drawerStatusChange() {
    addDrawer({
      title: `Deseja mesmo ${
        getInstitute().status === 'A' ? 'desativar' : 'ativar'
      } a instituição?
      `,
      label:
        getInstitute().status === 'A'
          ? 'Os dados da instituição ainda ficarão visíveis, caso deseje torná-lo ativo novamente.'
          : 'Os dados da instituição ficarão visíveis e o acesso está disponivel.',
      buttonLabelPrimary: `Sim, quero ${
        getInstitute().status === 'A' ? 'desativar' : 'ativar'
      }`,
      buttonLabelSecondary: `Não quero ${
        getInstitute().status === 'A' ? 'desativar' : 'ativar'
      }`,
      handleButtonClickPrimary: () => {
        if (getInstitute().status === 'A') {
          handleChangeStatus('I');
          removeDrawer();
        } else {
          handleChangeStatus('A');
          removeDrawer();
        }
      },
      handleButtonClickSecondary: () => {
        removeDrawer();
      },
    });
  }

  // function handleExcludeInstitute() {
  //   addDrawer({
  //     title: 'Deseja mesmo excluir a instituição?',
  //     label: 'Os dados da instituição serão excluídos definitivamente.',
  //     buttonLabelPrimary: 'Sim, quero excluir',
  //     buttonLabelSecondary: 'Não quero excluir',
  //     handleButtonClickPrimary: () => {
  //       removeDrawer();
  //     },
  //     handleButtonClickSecondary: () => {
  //       removeDrawer();
  //     },
  //   });
  // }

  function loadingFeedback(heightParam, marginTop) {
    function getLoading() {
      if (loadingBackoffice) return loadingBackoffice;
      if (loadingDashBoardBackOffice) return loadingDashBoardBackOffice;
      return loading;
    }
    return (isBackOffice && errorBackoffice) || (!loading && error) ? (
      <Components.ReloadError
        reload={() => {
          if (errorBackoffice) {
            handleInstitute();
          } else {
            handleGetCountAgent(code);
          }
        }}
        loading={getLoading()}
        height={heightParam}
      />
    ) : (
      loading && (
        <Components.Loading
          marginTop={marginTop}
          height={`calc(${heightParam} - 105px)`}
        />
      )
    );
  }

  useEffect(() => {
    if (code) {
      handleGetCountAgent(code);
    }
  }, [code]);

  return (
    <S.Wrapper>
      <S.Container>
        {(isBackOffice && errorBackoffice) ||
        // (loadingBackoffice && loadingFeedback('573px', '100.50px'))}
        loadingDashBoardBackOffice ? (
          loadingFeedback('573px', '100.50px')
        ) : (
          <>
            {isBackOffice && <Components.Breadcrumbs marginBottom="0px" />}
            <S.TypographyContainer isBackOffice={isBackOffice}>
              <D.TmpTypography component="heading" size="large">
                {isBackOffice ? name : 'Seja bem-vindo(a)'}
              </D.TmpTypography>
              {isBackOffice ? (
                <DB2B.TmpMenuOverflow
                  listItems={[
                    {
                      label:
                        getInstitute().status === 'A' ? 'Desativar' : 'Ativar',
                      leadingIcon: <ReactSVG src={I.remover} />,
                      handleClick: () => {
                        drawerStatusChange();
                      },
                    },
                    {
                      label: 'Editar',
                      leadingIcon: <ReactSVG src={I.editar} />,
                      handleClick: () => {
                        navigate('/detalhes-do-cliente/meus-dados');
                      },
                    },
                    // {
                    //   label: 'Excluir',
                    //   leadingIcon: <ReactSVG src={I.excluir} />,
                    //   handleClick: () => {
                    //     handleExcludeInstitute();
                    //   },
                    // },
                  ]}
                />
              ) : (
                <D.TmpTypography component="caption">
                  Fique por dentro dos dados sua Instituição!
                </D.TmpTypography>
              )}
            </S.TypographyContainer>
            <S.CardsContainer>
              <S.CardsSide>
                <Components.Card
                  title={isBackOffice ? 'Dados da Instituição' : 'Meus dados'}
                  showAllAction={() => {
                    navigate(
                      isBackOffice
                        ? '/detalhes-do-cliente/meus-dados'
                        : '/meus-dados'
                    );
                  }}
                  minHeight="187px"
                  showAllCustomText="Ver tudo"
                >
                  <S.MyDataWrapper>
                    {!isBackOffice && (
                      <D.TmpTypography component="paragraph">
                        <S.TextCard fontSize="1rem" fontWeight={500} light>
                          Nome
                        </S.TextCard>
                        <S.TextCard fontSize="1rem" fontWeight={500}>
                          {name}
                        </S.TextCard>
                      </D.TmpTypography>
                    )}
                    <D.TmpTypography component="paragraph">
                      <S.TextCard fontSize="1rem" fontWeight={500} light>
                        CNPJ
                      </S.TextCard>
                      <S.TextCard fontSize="1rem" fontWeight={500}>
                        {Utils.formatCNPJ(cnpj)}
                      </S.TextCard>
                    </D.TmpTypography>
                    {isBackOffice && (
                      <D.TmpTypography component="paragraph">
                        <S.TextCard fontSize="1rem" fontWeight={500} light>
                          Status
                        </S.TextCard>
                        <S.TextCard fontSize="1rem" fontWeight={500}>
                          {getTranslateStatus()}
                        </S.TextCard>
                      </D.TmpTypography>
                    )}
                  </S.MyDataWrapper>
                </Components.Card>
                <Components.Card
                  title="Responsáveis"
                  showAllAction={() => {
                    navigate(
                      isBackOffice
                        ? '/detalhes-do-cliente/responsaveis'
                        : '/responsaveis'
                    );
                  }}
                  minHeight="286px"
                >
                  {agentCount ? (
                    <>
                      <S.ContainerCardsCount>
                        <Components.CardCount
                          title="Usuário(s) ativo(s)"
                          count={Utils.addZeroNumber(agentCount?.active)}
                        />
                        <Components.CardCount
                          title="Usuário(s) inativo(s)"
                          count={Utils.addZeroNumber(agentCount?.inactive)}
                          color="#F05656"
                          borderColorIcon="#FDC4C4"
                        />
                      </S.ContainerCardsCount>

                      <S.ContainerButton color="#2c6ef2" colorHover="#0a3a99">
                        <D.TmpButton
                          startIcon={<ReactSVG src={I.adicionar} />}
                          id="tmp-button-new-agent"
                          type="secondary"
                          handleClick={() =>
                            navigate(
                              isBackOffice
                                ? '/detalhes-do-cliente/dados-do-responsavel'
                                : '/dados-do-responsavel',
                              {
                                state: {
                                  newAgent: true,
                                  isEdit: false,
                                  backRoute: `${
                                    isBackOffice
                                      ? '/detalhes-do-cliente'
                                      : '/responsaveis'
                                  }`,
                                },
                              }
                            )
                          }
                          size="sm"
                        >
                          Novo responsável
                        </D.TmpButton>
                      </S.ContainerButton>
                    </>
                  ) : (
                    loadingFeedback('201px', '25%')
                  )}
                </Components.Card>
              </S.CardsSide>
              <S.CardsMain>
                <Components.Card
                  title="Atendimentos"
                  showAllAction={() => {
                    navigate(
                      isBackOffice
                        ? '/detalhes-do-cliente/atendimentos'
                        : '/atendimentos'
                    );
                  }}
                >
                  <S.ContainerCardsMain>
                    <img
                      src={`${process.env.REACT_APP_S3_IMAGES_STORAGE}warning.png`}
                      alt="Atenção"
                    />
                    <D.TmpTypography component="heading" size="small">
                      Falta pouco!
                    </D.TmpTypography>
                    <D.TmpTypography component="caption">
                      Em breve, você visualizará gráficos mensais sobre os
                      atendimentos da Instituição.
                    </D.TmpTypography>
                  </S.ContainerCardsMain>
                </Components.Card>
              </S.CardsMain>
            </S.CardsContainer>
          </>
        )}
      </S.Container>
    </S.Wrapper>
  );
}

Dashboard.defaultProps = {
  isBackOffice: false,
};
Dashboard.propTypes = {
  isBackOffice: propTypes.bool,
};
