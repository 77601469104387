import styled from 'styled-components';

export const Wrapper = styled.section`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: ${({ marginBottom }) => marginBottom ?? '17px'};
  .tmp-typography__paragraph--small {
    margin-bottom: 4px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    font-family: 'Inter';
    color: #666;
  }
  .tmp-link {
    max-width: max-content;
    font-weight: 400;
  }
`;
export const Separator = styled.span`
  .tmp-typography__paragraph--small {
    line-height: 133%;
  }
`;
export const Link = styled.a`
  color: #4985fd;
  font-size: 14px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  line-height: 133%;
  &:hover {
    cursor: pointer;
  }
`;
