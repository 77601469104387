import { device } from '@b2b/core/src/utils';
import styled from 'styled-components';

export const ModalWrapper = styled.div`
  z-index: 9999;
  > div > div {
    min-height: 500px;
    max-width: 785px;

    @media ${device.tablet} {
      > div > div {
        height: 100vh;
      }
    }
  }
  @media ${device.mobile} {
    > div > div {
      min-width: 312px;
      max-width: 480px;
    }
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 374px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 2.25rem;
`;

export const WrapperInformation = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  .tmp-typography__heading--large {
    font-size: 24px;
  }

  .tmp-typography__paragraph--small {
    font-size: 16px;
    margin-top: 0.5rem;
  }
  .tmp-button {
    margin-top: 1.5rem;
  }
`;

export const ImageView = styled.img`
  width: 285px;
  height: 285px;
  @media ${device.laptop} {
    width: 80%;
    padding: 1.5rem;
  }
`;
