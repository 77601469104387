import * as I from '@tempo/tempo-assets/dist/svgs/icons';
import * as D from '@tempo/tempo-design-system-core/dist';

import propTypes from 'prop-types';
import { ReactSVG } from 'react-svg';

import * as S from './styles';

export function ReloadError({
  loading,
  reload,
  height,
  marginTop,
  customText,
  customTextButton,
  marginHorizontal,
}) {
  return (
    <S.ContainerError
      marginHorizontal={marginHorizontal}
      height={height}
      marginTop={marginTop}
    >
      <div>
        <D.TmpTypography component="paragraph" size="small">
          {customText}
        </D.TmpTypography>
      </div>
      <S.ContainerButton
        loading={loading}
        primary
        noMarginBottom
        color="#fff"
        colorHover="#0a3a99"
      >
        <D.TmpButton
          handleClick={reload}
          typeButton="button"
          loading={loading}
          startIcon={<ReactSVG src={I.retornar} />}
        >
          {customTextButton}
        </D.TmpButton>
      </S.ContainerButton>
    </S.ContainerError>
  );
}
ReloadError.defaultProps = {
  height: '201px',
  customText: 'Falha ao carregar as informações',
  customTextButton: 'Recarregar',
  marginTop: '16px',
  marginHorizontal: 'auto',
};
ReloadError.propTypes = {
  height: propTypes.string,
  marginTop: propTypes.string,
  loading: propTypes.bool.isRequired,
  reload: propTypes.func.isRequired,
  customText: propTypes.string,
  customTextButton: propTypes.string,
  marginHorizontal: propTypes.string,
};
