import React from 'react';

import { Analytics, Hooks, Pages, Routes as RB2B } from '@b2b/core';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import * as P from 'pages';

import { NoAuthRoutes } from './noAuthRoutes';
import { PrivateRoutes } from './privateRoutes';

const routes = [
  { path: '/', element: <P.Home />, isPrivate: true },
  { path: '/meus-dados', element: <P.MyData />, isPrivate: true },
  { path: '/login', element: <P.Login />, isPrivate: false, noAuth: true },
  {
    path: '/codigo-de-acesso',
    element: <P.AccessCode />,
    isPrivate: false,
    noAuth: true,
  },
  { path: '/atendimentos', element: <P.Attendances />, isPrivate: true },
  {
    path: '/informacao-do-sistema',
    element: <Pages.InfoSystem />,
    isPrivate: false,
  },
  {
    path: '/manutencao',
    element: <Pages.Maintenance />,
    isPrivate: false,
  },
  {
    path: '/responsaveis/:changeForPage',
    element: <P.Agents />,
    isPrivate: true,
  },
  {
    path: '/responsaveis',
    element: <P.Agents />,
    isPrivate: true,
  },
  {
    path: '/dados-do-responsavel',
    element: <P.FormAgent />,
    isPrivate: true,
  },
  {
    path: '/sucesso-ao-cadastrar',
    element: <Pages.RegistrationSuccessful />,
    isPrivate: true,
  },
];

function getElementType(route) {
  const { isPrivate, isNoMobile, noAuth, element } = route;
  if (isPrivate && !isNoMobile) return <PrivateRoutes>{element}</PrivateRoutes>;
  if (noAuth && !isNoMobile) return <NoAuthRoutes>{element}</NoAuthRoutes>;
  return element;
}

export function Router() {
  Analytics.initializeGA();
  return (
    <BrowserRouter>
      <Hooks.ScrollToTop />
      <Routes>
        <Route
          path="/"
          element={
            <Hooks.NoInternetConnection>
              <RB2B.NoMobileRoutes>
                <PrivateRoutes>
                  <P.Home />
                </PrivateRoutes>
              </RB2B.NoMobileRoutes>
            </Hooks.NoInternetConnection>
          }
        />
        {routes.map((route, index) => (
          <Route
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            path={route.path}
            element={
              <Hooks.NoInternetConnection>
                <RB2B.NoMobileRoutes>
                  {getElementType(route)}
                </RB2B.NoMobileRoutes>
              </Hooks.NoInternetConnection>
            }
          />
        ))}
      </Routes>
    </BrowserRouter>
  );
}
