import { device } from '@b2b/core/src/utils';
import styled from 'styled-components';

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: rgba(44, 110, 242, 0.02);
  min-height: calc(100vh - 89px);
  @media ${device.laptop} {
    flex-direction: column;
    gap: 0;
    padding: 2rem 1.5rem;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1366px;
  width: 100%;
  padding: 40px 88px;
  box-sizing: border-box;
`;

export const TypographyContainer = styled.div`
  display: flex;
  flex-direction: ${({ isBackOffice }) => (isBackOffice ? 'row' : 'column')};
  justify-content: ${({ isBackOffice }) =>
    isBackOffice ? 'space-between' : 'space-between'};
  align-items: ${({ isBackOffice }) =>
    isBackOffice ? 'center' : 'flex-start'};
  margin-bottom: ${({ isBackOffice }) => (isBackOffice ? '8px' : '16px')};
  gap: 4px;
  .tmp-typography__heading--large {
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 133%;
    color: #292929;
  }
  .tmp-typography__caption {
    font-weight: 400;
    font-size: 14px;
    color: #666666;
  }
`;
export const TextCard = styled.p`
  font-weight: ${({ fontWeight }) => fontWeight ?? 400};
  font-size: ${({ fontSize }) => fontSize ?? '0.875rem'};
  color: ${({ light }) => (light ? '#A3A3A3' : '#666666')};
`;

export const MyDataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
export const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  align-items: flex-start;
  gap: 24px;
  @media ${device.laptop} {
    display: flex;
    flex-direction: column;
  }
`;

export const CardsSide = styled.div`
  min-width: 381px;
  width: 100%;
  gap: 29px;
  display: flex;
  flex-direction: column;
`;
export const CardsMain = styled.div``;
export const ContainerCardsMain = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    margin: 35px auto 18px auto;
  }
  .tmp-typography__heading--small {
    color: #666666;
    font-weight: 700;
    font-size: 16px;
  }
  .tmp-typography__caption {
    color: #666666;
    font-weight: 400;
    font-size: 14px;
    margin: 4px auto 35px auto;
  }
`;
export const ContainerCardsCount = styled.div`
  display: flex;
  margin: 40px auto;
  gap: 40px;
`;
export const ContainerError = styled.div`
  margin-top: 16px;
  display: flex;
  height: 201px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #f5f5f5;
  border-radius: 16px;
  gap: 24px;
  .tmp-typography__paragraph--small {
    color: #666666;
    font-weight: 400;
    font-size: 16px;
  }
`;
export const ContainerButton = styled.div`
  margin-bottom: ${({ noMarginBottom }) => (noMarginBottom ? '0px' : '20px')};
  button {
    .label {
      font-weight: 400;
      font-size: 16px;
    }
    min-width: 100%;
    .icon {
      padding-top: 3px;
      padding-right: 10px;
    }
    svg {
      width: 20px;
      height: 20px;
      path {
        fill: ${({ loading, color }) => (!loading ? color : 'transparent')};
        transition: ${({ loading }) => (!loading ? 'all ease 500ms' : 'auto')};
      }
    }
  }
  button:hover {
    svg {
      path {
        fill: ${({ loading, colorHover }) =>
          !loading ? colorHover : 'transparent'};
      }
    }
  }
`;
