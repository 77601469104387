import * as D from '@tempo/tempo-design-system-core/dist';

import propTypes from 'prop-types';

import * as S from './styles';

export function PatientIdentificationCard({
  patientName,
  showUpdate,
  handleClick,
  margin,
  marginMobile,
}) {
  return (
    <S.CardShape margin={margin} marginMobile={marginMobile}>
      <S.HeaderShape>
        <D.TmpTypography component="heading" size="small">
          Paciente
        </D.TmpTypography>
        {showUpdate && <D.TmpLink onClick={handleClick}>Alterar</D.TmpLink>}
      </S.HeaderShape>
      <D.TmpTypography component="paragraph" size="small">
        {patientName}
      </D.TmpTypography>
    </S.CardShape>
  );
}

PatientIdentificationCard.defaultProps = {
  handleClick: () => {},
  showUpdate: false,
  margin: '6rem 0 1.5rem 0',
  marginMobile: '1rem 0 2rem 0',
};
PatientIdentificationCard.propTypes = {
  patientName: propTypes.string.isRequired,
  handleClick: propTypes.func,
  showUpdate: propTypes.bool,
  margin: propTypes.string,
  marginMobile: propTypes.string,
};
