import { Utils } from '@b2b/core';
import styled from 'styled-components';

export const Wrapper = styled.section`
  padding: 5rem;
  display: flex;
  gap: 12.5rem;
  justify-content: center;
  @media ${Utils.device.laptop} {
    padding: 0rem;
    gap: 0rem;
    flex-direction: column;
    img {
      display: none;
    }
  }
`;

export const InformationWrapper = styled.div`
  margin-top: 2.5rem;
  @media ${Utils.device.laptop} {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 303px;
    margin: 2rem auto;
  }

  p {
    max-width: 381px;
    margin: 1rem 0rem;
    @media ${Utils.device.laptop} {
      max-width: 303px;
    }
  }
`;

export const InformationActions = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 6.125rem;
  gap: 1rem;
  button {
    min-width: auto;
  }
  @media ${Utils.device.laptop} {
    width: 100%;
    margin-top: 12.06rem;
    justify-content: center;
    flex-direction: column;
    button {
      width: 100%;
    }
  }
`;

export const Helper = styled.span`
  display: flex;
  margin-top: 8px;
  color: #ea1f1f;
  font-size: 14px;
  font-weight: 400;
  font-family: Inter;
  line-height: 100%;
  .icon {
    padding-right: 4px;
  }
  span {
    color: #ea1f1f;
  }
`;
