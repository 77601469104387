import * as DB2B from '@tempo/tempo-design-system-b2b/dist';
import * as D from '@tempo/tempo-design-system-core/dist';

import propTypes from 'prop-types';

import {
  Content,
  ImageView,
  ModalWrapper,
  Wrapper,
  WrapperInformation,
} from './styles';

export function ModalSuccessFeedback({ isOpened, handleClose }) {
  return (
    <ModalWrapper>
      <DB2B.TmpModal isOpened={isOpened} handleClose={handleClose} noFooter>
        <Wrapper>
          <Content>
            <ImageView
              src={`${process.env.REACT_APP_S3_IMAGES_STORAGE}successCompletingStep.png`}
            />
            <WrapperInformation>
              <D.TmpTypography component="heading" size="large">
                Relatório de atendimento <br /> enviado com sucesso!
              </D.TmpTypography>
              <D.TmpTypography component="paragraph" size="small">
                Confira no seu e-mail cadastrado.
              </D.TmpTypography>
              <D.TmpButton size="lg" type="primary" handleClick={handleClose}>
                Ok, entendi!
              </D.TmpButton>
            </WrapperInformation>
          </Content>
        </Wrapper>
      </DB2B.TmpModal>
    </ModalWrapper>
  );
}

ModalSuccessFeedback.defaultProps = {
  isOpened: false,
  handleClose: () => {},
};

ModalSuccessFeedback.propTypes = {
  isOpened: propTypes.bool,
  handleClose: propTypes.func,
};
