import { useEffect, useState } from 'react';

import { Hooks, Services, Utils } from '@b2b/core';

export default function useStateList() {
  const { addToast } = Hooks.useToast();
  const { setAllStateList, allStateList } = Hooks.useUser();
  const [states, setStates] = useState(allStateList);
  const [loadingStates, setLoadingStates] = useState(false);
  const [errorStates, setErrorStates] = useState(false);

  function getStates() {
    setLoadingStates(true);
    if (!allStateList.length) {
      Services.getState()
        .then(response => {
          setErrorStates(false);
          const nameStates = response
            ?.sort(function order(a, b) {
              if (a.initials < b.initials) {
                return -1;
              }
              if (a.initials > b.initials) {
                return 1;
              }
              return 0;
            })
            .map(unformattedState => {
              return {
                ...unformattedState,
                description: Utils.getStateName(unformattedState.description),
              };
            });
          setAllStateList(nameStates);
          setStates(nameStates);
        })
        .catch(({ response }) => {
          setErrorStates(true);
          addToast({
            type: 'error',
            title: Utils.getError('title', response),
            message: Utils.getError('message', response) || 'Erro desconhecido',
          });
        })
        .finally(() => {
          setLoadingStates(false);
        });
    }
  }
  function stateDescriptionByInitials(initials) {
    const stateDescription = states?.find(
      state => state.initials === initials
    )?.description;
    return Utils.getStateName(stateDescription);
  }
  function fullyStateByInitials(initials) {
    const fullyState = states?.find(state => state.initials === initials);
    const formattedFullyState = {
      initials: fullyState?.initials,
      description: Utils.getStateName(fullyState?.description),
    };
    return formattedFullyState;
  }

  useEffect(() => {
    getStates();
  }, []);

  return {
    stateDescriptionByInitials,
    fullyStateByInitials,
    states,
    loadingStates,
    errorStates,
  };
}
