import { Components, Hooks } from '@b2b/core';

export function Home() {
  Hooks.useStateList();

  return (
    <Components.Layout>
      <Components.Dashboard isBackOffice={false} />
    </Components.Layout>
  );
}
