import { get, patch, post } from './api_methods';

async function getHealthInsurance(instituteCode) {
  const { data } = await get(
    `institute/health-insurance?instituteCode=${instituteCode}`
  );
  return data.result;
}

async function getHealthInsuranceAgreement() {
  const { data } = await get(`/backoffice/health-insurance`);

  return data.result;
}

async function getHealthPlanCategory(healthInsurance) {
  const { data } = await post(`/backoffice/health-category`, {
    healthInsurance,
  });
  return data.result;
}

async function getHealthPlan(healthInsurance, healthCategory) {
  const { data } = await post(`/backoffice/health-plan`, {
    healthInsurance,
    healthCategory,
  });
  return data.result;
}

async function patchHealthInsurance(
  institudeCode,
  healthInsuranceCode,
  healthCategoryCode,
  healthPlanCode
) {
  const { data } = await patch(
    `/backoffice/change-health-insurance-institute`,
    {
      instituteCode: institudeCode.toString(),
      healthInsurance: healthInsuranceCode.toString(),
      healthCategory: healthCategoryCode.toString(),
      healthPlan: healthPlanCode.toString(),
    }
  );
  return data.result;
}

export {
  getHealthInsurance,
  getHealthInsuranceAgreement,
  getHealthPlanCategory,
  getHealthPlan,
  patchHealthInsurance,
};
