import { Utils } from '@b2b/core';

export default function completeDocumentFields(
  type,
  fieldType,
  fieldDocument,
  values
) {
  const typeDocument = values[fieldType]?.value;
  const document = values[fieldDocument];
  // eslint-disable-next-line no-console
  if (typeDocument === type) {
    return Utils.removeMask(type, document);
  }
  return null;
}
