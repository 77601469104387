import React from 'react';

import brandSirio from '@tempo/tempo-assets/dist/svgs/brands/brand_sirio.svg';
import * as I from '@tempo/tempo-assets/dist/svgs/icons';
import * as D from '@tempo/tempo-design-system-core/dist';

import { Components, Hooks } from '@b2b/core';
import propTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { ReactSVG } from 'react-svg';

import * as S from './styles';

export function Header({ isLogged, titleSEO }) {
  const navigate = useNavigate('/');
  const { getName, getAccountType, clearUser } = Hooks.useUser();
  const useAccountType = getAccountType();

  return (
    <S.WrapperHeader>
      <D.TmpHeaderBase
        logo={<ReactSVG src={brandSirio} />}
        handleClickLogo={() => navigate('/')}
      >
        <S.ContainerHeader>
          <Components.SEO title={titleSEO} />
          <S.Title isLogged={isLogged}>
            <D.TmpTypography component="heading" size="small">
              Pronto Atendimento Digital
            </D.TmpTypography>
          </S.Title>
          {isLogged && (
            <S.Dropdown id="dropdown-profile">
              <D.DropdownProfile
                name={getName(useAccountType)}
                account={useAccountType}
                avatarInitials={getName('firstLetter')}
                title="Voltar"
                listItems={
                  useAccountType !== 'Conta Admin'
                    ? [
                        {
                          leadingIcon: <ReactSVG id="profile" src={I.perfil} />,
                          trailingIcon: (
                            <ReactSVG
                              src={I.seta_direcional_direita}
                              className="trailing-icon"
                            />
                          ),
                          label: 'Meus dados',
                          handleClick: () => {
                            navigate('/meus-dados');
                          },
                        },
                        {
                          leadingIcon: (
                            <ReactSVG id="exit-account" src={I.sair} />
                          ),
                          trailingIcon: (
                            <ReactSVG
                              src={I.seta_direcional_direita}
                              className="trailing-icon"
                            />
                          ),
                          label: 'Sair da minha conta',
                          handleClick: () => {
                            clearUser();
                            navigate('/login');
                          },
                        },
                      ]
                    : [
                        {
                          leadingIcon: (
                            <ReactSVG id="exit-account" src={I.sair} />
                          ),
                          trailingIcon: (
                            <ReactSVG
                              src={I.seta_direcional_direita}
                              className="trailing-icon"
                            />
                          ),
                          label: 'Sair da minha conta',
                          handleClick: () => {
                            clearUser();
                            navigate('/login');
                          },
                        },
                      ]
                }
              />
            </S.Dropdown>
          )}
        </S.ContainerHeader>
      </D.TmpHeaderBase>
    </S.WrapperHeader>
  );
}

Header.defaultProps = {
  titleSEO: 'Pronto Atendimento Digital',
  isLogged: false,
};

Header.propTypes = {
  isLogged: propTypes.bool,
  titleSEO: propTypes.string,
};
