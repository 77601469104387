/* eslint-disable no-nested-ternary */
import { useEffect, useState } from 'react';

import Erro from '@tempo/tempo-assets/dist/react/icons/erro';
import * as I from '@tempo/tempo-assets/dist/svgs/icons';
import * as DB2B from '@tempo/tempo-design-system-b2b/dist';
import * as D from '@tempo/tempo-design-system-core/dist';

import { Analytics, Components, Hooks, Utils } from '@b2b/core';
import {
  getUniqueAgent,
  registerAgent,
  updateAgent,
  updateStatusAgent,
} from '@b2b/core/src/services/agent.service';
import { useFormik } from 'formik';
import propTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import { ReactSVG } from 'react-svg';

import { AgentData } from './agentData';
import { SchemaInitialValues, schemas } from './schemas';
import * as S from './styles';

export function FormAgent({ isBackOffice }) {
  const navigate = useNavigate();
  const params = useLocation();
  const { isEdit, newAgent, agentCode, backRoute, backDetails } = params.state;
  const [editType, setEditType] = useState(isEdit);
  const location = useLocation();

  const [error, setError] = useState(false);
  const [agent, setAgent] = useState(false);
  const [loadingReload, setLoadingReload] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [loadingExclude, setLoadingExclude] = useState(false);
  const { addToast } = Hooks.useToast();
  const { getUser } = Hooks.useUser();
  const { getInstitute } = Hooks.useBackOffice();
  const { code } = isBackOffice ? getInstitute() : getUser();
  const { addDrawer, removeDrawer } = Hooks.useDrawer();

  function handleRegisterAgent(agentValuesRequest) {
    registerAgent(agentValuesRequest)
      .then(() => {
        Analytics.logEvent(
          'Responsável cadastrado',
          {},
          isBackOffice ? 'backoffice' : 'institute'
        );
        navigate('/sucesso-ao-cadastrar', {
          state: {
            route: backRoute ?? '/',
          },
        });
      })
      .catch(({ response }) => {
        Analytics.logEvent(
          'Erro ao cadastrar responsável',
          {},
          isBackOffice ? 'backoffice' : 'institute'
        );

        addToast({
          type: 'error',
          title: Utils.getError('title', response),
          message: Utils.getError('message', response) || 'Erro desconhecido',
        });
      })
      .finally(() => {
        setLoadingButton(false);
      });
  }

  function handleUpdateAgent(agentValuesRequest) {
    updateAgent(agentValuesRequest)
      .then(() => {
        Analytics.logEvent(
          'Responsável editado',
          {},
          isBackOffice ? 'backoffice' : 'institute'
        );

        addToast({
          type: 'success',
          title: 'Dados alterados com sucesso',
          message: 'Suas alterações foram aplicadas em nosso sistema.',
        });
        if (backDetails) {
          setEditType(false);
        } else {
          navigate(backRoute ?? '/');
        }
      })
      .catch(({ response }) => {
        Analytics.logEvent(
          'Erro ao editar responsável',
          {},
          isBackOffice ? 'backoffice' : 'institute'
        );

        addToast({
          type: 'error',
          title: Utils.getError('title', response),
          message: Utils.getError('message', response) || 'Erro desconhecido',
        });
      })
      .finally(() => {
        setLoadingButton(false);
      });
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: SchemaInitialValues(agent),
    validationSchema: schemas(editType),
    onSubmit: values => {
      setLoadingButton(true);

      const newValuesAgent = {
        name: Utils.capitalize(values.name),
        gender: values.gender.value,
        ddiPhoneNumber: values.phoneNumber.ddi,
        dddPhoneNumber: values.phoneNumber.ddd,
        phoneNumber: values.phoneNumber.phone,
        statusCode: values.status.value.toString(),
        email: values.email,
        healthProfessional: values.healthProfessional.value.toString(),
        jobDescription: values.healthProfessional.jobDescription,
        instituteCode: code.toString(),
        identity: null,
        birthDate: '10/01/1990',

        cpf: Utils.completeDocumentFields(
          'cpf',
          'document',
          'numberDocument',
          values
        ),
        rne: Utils.completeDocumentFields(
          'rne',
          'document',
          'numberDocument',
          values
        ),
        passport: Utils.completeDocumentFields(
          'passport',
          'document',
          'numberDocument',
          values
        ),
      };
      if (newAgent) {
        handleRegisterAgent(newValuesAgent);
      } else {
        const oldValuesAgent = {
          agentCode: agentCode?.toString(),
          name: Utils.capitalize(agent?.name),
          gender: agent.gender,
          ddiPhoneNumber: agent.ddiPhoneNumber,
          dddPhoneNumber: agent.dddPhoneNumber,
          phoneNumber: agent.phoneNumber,
          statusCode: agent.status.toString(),
          email: agent.email,
          healthProfessional: agent.healthProfessional.toString(),
          jobDescription: agent.healthProfessional.jobDescription,
          instituteCode: agent.instituteCode.toString(),
          identity: null,
          birthDate: '10/01/1990',
          cpf: agent?.cpf || null,
          rne: agent.rne || null,
          passport: agent?.passport || null,
        };
        const complementNewValues = {
          ...newValuesAgent,
          agentCode: agentCode?.toString(),
        };
        const isEqual = Utils.compareObjects(
          oldValuesAgent,
          complementNewValues
        );

        if (isEqual) {
          // eslint-disable-next-line no-unused-expressions
          backRoute ? navigate(backRoute) : setEditType(false);
          setLoadingButton(false);
        } else {
          handleUpdateAgent(complementNewValues);
        }
      }
    },
  });

  function handleGetAgent() {
    const isEditWithBack = !editType && backDetails;
    const isEditWithoutBack = editType && !backDetails;
    if ((agentCode && isEditWithBack) || (agentCode && isEditWithoutBack)) {
      setLoadingReload(true);
      getUniqueAgent(agentCode)
        .then(response => {
          setError(false);
          setAgent(response);
          formik.setFieldValue('phoneNumber', {
            ddi: response.ddiPhoneNumber,
            ddd: response.dddPhoneNumber,
            phone: response.phoneNumber,
            completeNumber: `${response.ddiPhoneNumber}${response.dddPhoneNumber}${response.phoneNumber}`,
          });
        })
        .catch(({ response }) => {
          setError(true);
          addToast({
            type: 'error',
            title: Utils.getError('title', response),
            message: Utils.getError('message', response) || 'Erro desconhecido',
          });
        })
        .finally(() => {
          setLoadingReload(false);
        });
    }
  }
  const agentData = AgentData(
    handleGetAgent,
    agent && agent,
    formik.values.document.value
  );

  const getTitle = () => {
    if (editType) {
      return 'Editar detalhes do responsável';
    }
    return newAgent ? 'Cadastro de responsável' : 'Detalhes do responsável';
  };

  function isDisabled(name) {
    const fieldsName = ['document', 'numberDocument'];
    if (!newAgent && fieldsName.includes(name)) {
      return true;
    }
    return false;
  }

  function loadingFeedback() {
    return error ? (
      <Components.ReloadError
        marginTop="0px"
        loading={loadingReload}
        reload={() => handleGetAgent()}
        height={editType ? '612px' : '361px'}
      />
    ) : (
      !newAgent && (
        <div className="loadingFeedback">
          <Components.Loading height="380px" />
        </div>
      )
    );
  }

  function excludeAgent() {
    addDrawer({
      title: `Deseja mesmo excluir o responsável?`,
      label: 'Os dados do responsável serão excluídos definitivamente.',
      buttonLabelPrimary: `Sim, quero excluir`,
      buttonLabelSecondary: `Não quero excluir`,
      handleButtonClickPrimary: () => {
        setLoadingExclude(true);
        updateStatusAgent(agentCode, 3)
          .then(() => {
            Analytics.logEvent(
              'Responsável excluído',
              {},
              isBackOffice ? 'backoffice' : 'institute'
            );

            navigate(backRoute ?? '/');
            addToast({
              type: 'success',
              title: 'Responsável excluído com sucesso',
              message:
                'Os dados do responsável foram excluídos definitivamente.',
            });
          })
          .catch(({ response }) => {
            Analytics.logEvent(
              'Erro ao excluir responsável',
              {},
              isBackOffice ? 'backoffice' : 'institute'
            );

            addToast({
              type: 'error',
              title: Utils.getError('title', response),
              message:
                Utils.getError('message', response) || 'Erro desconhecido',
            });
          })
          .finally(() => setLoadingExclude(false));
        removeDrawer();
      },
      handleButtonClickSecondary: () => {
        removeDrawer();
      },
    });
  }

  useEffect(() => {
    Analytics.logPageView(
      `${getTitle()} - ${isBackOffice ? 'BackOffice' : 'Responsável'}`,
      location.pathname
    );
    handleGetAgent();
  }, [editType]);

  useEffect(() => {
    formik.setFieldValue('document', formik.values.document);
  }, [formik.values.document]);

  function getBreadCrumbs() {
    if (newAgent) {
      return null;
    }
    if (editType === true) {
      return (
        <Components.Breadcrumbs
          custom={
            backDetails
              ? isBackOffice
                ? [
                    { action: () => navigate('/'), label: 'Início' },
                    {
                      action: () => navigate('/detalhes-do-cliente'),
                      label: 'Detalhes do cliente',
                    },
                    {
                      action: () => navigate(backRoute ?? `/responsaveis`),
                      label: 'Responsáveis',
                    },
                    {
                      action: () => setEditType(false),
                      label: 'Detalhes do responsável',
                    },
                    {
                      label: 'Editar detalhes do responsável',
                      noLink: true,
                    },
                  ]
                : [
                    { action: () => navigate('/'), label: 'Início' },
                    {
                      action: () => navigate(backRoute ?? `/responsaveis`),
                      label: 'Responsáveis',
                    },
                    {
                      action: () => setEditType(false),
                      label: 'Detalhes do responsável',
                    },
                    {
                      label: 'Editar detalhes do responsável',
                      noLink: true,
                    },
                  ]
              : isBackOffice
              ? [
                  { action: () => navigate('/'), label: 'Início' },
                  {
                    action: () => navigate('/detalhes-do-cliente'),
                    label: 'Detalhes do cliente',
                  },
                  {
                    action: () => navigate(backRoute ?? `/responsaveis`),
                    label: 'Responsáveis',
                  },
                  {
                    label: 'Editar detalhes do responsável',
                    noLink: true,
                  },
                ]
              : [
                  { action: () => navigate('/'), label: 'Início' },
                  {
                    action: () => navigate(backRoute ?? `/responsaveis`),
                    label: 'Responsáveis',
                  },
                  {
                    label: 'Editar detalhes do responsável',
                    noLink: true,
                  },
                ]
          }
        />
      );
    }
    return (
      <Components.Breadcrumbs
        custom={
          isBackOffice
            ? [
                { action: () => navigate('/'), label: 'Início' },
                {
                  action: () => navigate('/detalhes-do-cliente'),
                  label: 'Detalhes do cliente',
                },
                {
                  action: () => navigate(backRoute ?? `/responsaveis`),
                  label: 'Responsáveis',
                },
                {
                  label: 'Detalhes do responsável',
                  noLink: true,
                },
              ]
            : [
                { action: () => navigate('/'), label: 'Início' },

                {
                  action: () => navigate(backRoute ?? `/responsaveis`),
                  label: 'Responsáveis',
                },
                {
                  label: 'Detalhes do responsável',
                  noLink: true,
                },
              ]
        }
      />
    );
  }

  return (
    <S.Wrapper>
      <S.Container>
        {getBreadCrumbs()}
        <S.WrapperTypography
          editType={editType}
          newAgent={newAgent}
          loadingExclude={loadingExclude}
        >
          <div className="containerEditor">
            <D.TmpTypography compoment="heading" size="medium">
              {getTitle()}
            </D.TmpTypography>
            {!newAgent && (
              <D.TmpButtonIcon
                size="lg"
                typeButton="button"
                handleClick={() => setEditType(true)}
                disabled={editType || newAgent}
                icon={<ReactSVG src={I.editar} />}
              />
            )}
          </div>
          {!newAgent && (
            <div className="containerExclude">
              {editType && (
                <D.TmpButton
                  type="secondary"
                  typeButton="button"
                  startIcon={<ReactSVG src={I.excluir} />}
                  handleClick={() => excludeAgent()}
                  loading={loadingExclude}
                >
                  Excluir
                </D.TmpButton>
              )}
            </div>
          )}
        </S.WrapperTypography>
        <form onSubmit={formik.handleSubmit}>
          <S.WrapperCardShape>
            <div className="cardShape">
              <D.TmpShape>
                <S.WrapperGuardianData>
                  <div className="guardianDataTitle">
                    <D.TmpTypography>DADOS DO RESPONSÁVEL</D.TmpTypography>
                  </div>
                  {!loadingReload || newAgent
                    ? agentData.getAgentData.map(item => {
                        if (editType || newAgent) {
                          return item.type === 'paragraph' &&
                            item.typeInput !== 'select' ? (
                            <div
                              className={
                                editType || newAgent
                                  ? item.editClass
                                  : item.class
                              }
                            >
                              {item.type === 'paragraph' &&
                                (item.mask === 'ddiphone' ? (
                                  <DB2B.TmpPhoneInput
                                    id={item.name}
                                    name={item.name}
                                    label={item.label}
                                    placeholder={item.placeholder}
                                    value={formik.values[item.name]}
                                    getValue={value => {
                                      if (value !== formik.values[item.name]) {
                                        formik.setFieldValue(item.name, value);
                                      }
                                    }}
                                    defaultValue={`${
                                      formik.values[item.name].ddi
                                    }${formik.values[item.name].ddd}${
                                      formik.values[item.name].phone
                                    }`}
                                    helperIcon={
                                      formik.touched[item.name] &&
                                      formik.errors[item.name] && <Erro />
                                    }
                                    helperText={
                                      (formik.touched[item.name] &&
                                        formik.errors[item.name]
                                          ?.completeNumber) ||
                                      (formik.touched[item.name] &&
                                        formik.errors[item.name]?.phone)
                                    }
                                    error={
                                      formik.touched[item.name] &&
                                      formik.errors[item.name] &&
                                      formik.errors[item.name]
                                    }
                                    defaultCountry={
                                      formik.values[item.name].ddi !== null
                                        ? null
                                        : 'BR'
                                    }
                                    onBlur={formik.handleBlur}
                                  />
                                ) : (
                                  <D.TmpInput
                                    id={item.name}
                                    name={item.name}
                                    label={item.label}
                                    placeholder={item.placeholder}
                                    value={formik.values[item.name]}
                                    handleChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    mask={item.mask}
                                    helperIcon={
                                      formik.touched[item.name] &&
                                      formik.errors[item.name] && <Erro />
                                    }
                                    helperText={
                                      formik.touched[item.name] &&
                                      formik.errors[item.name]
                                    }
                                    error={
                                      formik.touched[item.name] &&
                                      formik.errors[item.name] &&
                                      formik.errors[item.name]
                                    }
                                    capitalize={item.capitalize}
                                    disabled={isDisabled(item.name)}
                                  />
                                ))}
                            </div>
                          ) : (
                            item.type === 'paragraph' && (
                              <div
                                className={
                                  editType || newAgent
                                    ? item.editClass
                                    : item.class
                                }
                              >
                                <D.TmpSelect
                                  id={item.name}
                                  name={item.name}
                                  label={item.label}
                                  placeholder={item.placeholder}
                                  listItems={
                                    item.listItem ?? [
                                      {
                                        label: item.content,
                                        value: item.content,
                                      },
                                    ]
                                  }
                                  value={formik.values[item.name]}
                                  handleChange={value => {
                                    formik.setFieldValue(item.name, value);
                                    formik.setFieldTouched(item.name, false);
                                    if (item.name === 'document') {
                                      formik.setFieldTouched(
                                        'numberDocument',
                                        false
                                      );
                                      formik.setFieldValue(
                                        'numberDocument',
                                        ''
                                      );
                                    }
                                  }}
                                  error={
                                    formik.touched[item.name] &&
                                    formik.errors[item.name]?.label &&
                                    formik.errors[item.name]?.label
                                  }
                                  helperIcon={
                                    formik.touched[item.name] &&
                                    formik.errors[item.name]?.label && <Erro />
                                  }
                                  helperText={
                                    formik.touched[item.name] &&
                                    formik.errors[item.name]?.label
                                  }
                                  disabled={isDisabled(item.name)}
                                  onBlur={() =>
                                    formik.setFieldTouched(item.name, true)
                                  }
                                />
                              </div>
                            )
                          );
                        }
                        return (
                          <div className={item.class}>
                            <D.TmpTypography component={item.type} size="small">
                              {item.content}
                            </D.TmpTypography>
                          </div>
                        );
                      })
                    : loadingFeedback()}
                </S.WrapperGuardianData>
              </D.TmpShape>
            </div>
          </S.WrapperCardShape>
          {editType || newAgent ? (
            <S.WrapperFooterActions>
              <D.TmpButton
                type="secondary"
                size="lg"
                typeButton="button"
                handleClick={() => {
                  // eslint-disable-next-line no-unused-expressions, no-nested-ternary
                  editType && backDetails
                    ? setEditType(false)
                    : navigate(backRoute ?? '/');
                }}
              >
                Cancelar
              </D.TmpButton>
              <D.TmpButton
                typeButton="submit"
                size="lg"
                loading={loadingButton}
              >
                Salvar
              </D.TmpButton>
            </S.WrapperFooterActions>
          ) : (
            <div />
          )}
        </form>
      </S.Container>
    </S.Wrapper>
  );
}

FormAgent.defaultProps = {
  isBackOffice: false,
};
FormAgent.propTypes = {
  isBackOffice: propTypes.bool,
};
