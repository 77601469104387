import styled from 'styled-components';

export const ContainerError = styled.div`
  margin-top: 16px;
  display: flex;
  height: ${({ height }) => height ?? '201px'};
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #ffff;
  border-radius: 16px;
  gap: 12px;
  padding: 0 32px;
  .tmp-typography__heading--small {
    color: #292929;
    font-weight: 700;
    font-size: 20px;
    line-height: 133%;
    text-align: center;
    margin-bottom: 12px;
  }
  .tmp-typography__paragraph--small {
    color: #666666;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    text-align: center;
  }
`;
