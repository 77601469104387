import { get, post } from './api_methods';

async function getCode(document) {
  const { data } = await get(`institute/authentication?cnpj=${document}`);
  return data.result;
}

async function validateCode(document, responseCode, accessCode) {
  const params = {
    accessValue: document,
    code: responseCode,
    accessCode,
  };
  const { data } = await post(`institute/validate-code`, params);
  return data.result;
}

export { getCode, validateCode };
