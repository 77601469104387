/* eslint-disable no-console */
import { ThemeProvider } from '@tempo/tempo-design-system-core/dist/theme';

import { BackOfficeProvider } from '@b2b/core/src/contexts/BackOfficeContext';
import { DrawerProvider } from '@b2b/core/src/contexts/DrawerContext';
import { LoadingProvider } from '@b2b/core/src/contexts/LoadingContext';
import { ToastProvider } from '@b2b/core/src/contexts/ToastContext';
import { UserProvider } from '@b2b/core/src/contexts/UserContext';
import propTypes from 'prop-types';
import { HelmetProvider } from 'react-helmet-async';

export function GlobalProviders({ children }) {
  if (process.env.REACT_APP_ENVIRONMENT === 'PRODUCTION') {
    console.log = () => {};
    console.error = () => {};
    console.info = () => {};
  }
  return (
    <ThemeProvider brand="hsl" theme="default">
      <HelmetProvider>
        <UserProvider>
          <ToastProvider>
            <BackOfficeProvider>
              <LoadingProvider>
                <DrawerProvider>{children}</DrawerProvider>
              </LoadingProvider>
            </BackOfficeProvider>
          </ToastProvider>
        </UserProvider>
      </HelmetProvider>
    </ThemeProvider>
  );
}

GlobalProviders.propTypes = {
  children: propTypes.node.isRequired,
};
