import styled from 'styled-components';

export const Wrapper = styled.div`
  > div {
    box-shadow: ${({ noShadow }) => !noShadow && '0px 4px 8px 0px #00000014'};
    min-height: auto;
    border: 0;
    min-height: ${({ minHeight }) => minHeight ?? 'auto'};
  }
`;

export const Title = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .tmp-typography__heading--small {
    font-weight: 700;
    font-size: 16px;
    color: #666666;
  }
`;

export const Content = styled.div`
  margin-top: ${({ contentMarginTop }) => {
    return contentMarginTop ?? '1rem';
  }};
`;
