/* eslint-disable no-use-before-define */
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import ToastContext from '@b2b/core/src/contexts/ToastContext';
import * as HealthServices from '@b2b/core/src/services/healthInsurance.service';
import {
  getInstituteData,
  getSpecialtiesBackoffice,
} from '@b2b/core/src/services/institute.service';
import * as Utils from '@b2b/core/src/utils';
import JWTDecode from 'jwt-decode';
import propTypes from 'prop-types';

const BackOfficeContext = createContext();

export default BackOfficeContext;

export function BackOfficeProvider({ children }) {
  const [firstCallHealth, setfirstCallHealth] = useState(false);
  const [instituteData, setInstituteData] = useState(null);
  const [loadingBackoffice, setLoadingBackoffice] = useState(false);
  const [loadingDashBoardBackOffice, setLoadingDashBoardBackOffice] =
    useState(false);

  const [errorBackoffice, setErrorBackoffice] = useState(false);
  const { addToast } = useContext(ToastContext);

  const initialValue = {
    description: '',
    value: '',
    selected: 0,
  };

  const [healthInsuranceData, setHealthInsuranceData] = useState(false);
  const [healthInsuranceFullData, setHealthInsuranceFullData] = useState(false);
  const [isLoading, setIsLoading] = useState({
    healthInsuranceAgreement: true,
    healthCategory: false,
    healthPlan: false,
  });
  const [healthInsuranceAgreement, setHealthInsuranceAgreement] = useState([]);
  const [healthCategory, setHealthCategory] = useState([]);
  const [healthPlan, setHealthPlan] = useState([]);
  const [
    selectedHealthInsuranceAgreement,
    setSelectedHealthInsuranceAgreement,
  ] = useState(initialValue);
  const [selectedHealthCategory, setSelectedHealthCategory] =
    useState(initialValue);
  const [selectedHealthPlan, setSelectedHealthPlan] = useState(initialValue);
  const [specialties, setSpecialties] = useState([]);

  function saveInstitute(institute) {
    setInstituteData(institute);
  }

  function getInstitute() {
    const returnData = {
      ...instituteData,
      specialties,
    };
    return returnData;
  }

  function getTranslateStatus() {
    const translateStatus = {
      A: 'Ativo',
      I: 'Inativo',
    };
    return translateStatus[instituteData?.status];
  }

  function getIdInstitute() {
    return localStorage.getItem('@HSL-B2B:idInstitute');
  }

  function handleInstitute() {
    setLoadingBackoffice(true);
    getInstituteData(getIdInstitute())
      .then(response => {
        setErrorBackoffice(false);
        saveInstitute(response);
      })
      .catch(() => {
        setErrorBackoffice(true);
      })
      .finally(() => {
        setLoadingBackoffice(false);
        setLoadingDashBoardBackOffice(false);
      });
  }

  function filterItems(
    arrayFilter,
    filterDescription,
    descriptionArray,
    selectedArray
  ) {
    return arrayFilter
      .filter(item => item.description === descriptionArray[filterDescription])
      .map(item => {
        return {
          description: item?.description,
          healthCategory: item?.healthCategory,
          healthInsuranceCode: item?.healthInsuranceCode,
          healthPlan: item?.healthPlan,
          selected: selectedArray?.findIndex(
            index => index.description === descriptionArray[filterDescription]
          ),
        };
      })[0];
  }

  async function healthInsurance() {
    const tokenDecoded = JWTDecode(localStorage.getItem('@HSL-B2B:token'));
    const instituteCode = getIdInstitute() || tokenDecoded.data.code;

    // eslint-disable-next-line no-console
    console.log(instituteCode);
    setLoadingBackoffice(true);
    try {
      if (!healthInsuranceData.healthInsurance && !firstCallHealth) {
        await HealthServices.getHealthInsurance(instituteCode)
          .then(response => {
            setErrorBackoffice(false);

            handleSelectHealthInsuranceAgreement(
              filterItems(
                healthInsuranceAgreement,
                'healthInsurance',
                response,
                healthInsuranceAgreement
              )
            );
            setHealthInsuranceData(response);
          })
          .catch(() => {
            setErrorBackoffice(true);
          });
      }
      if (
        healthCategory.length &&
        !firstCallHealth &&
        filterItems(
          healthCategory,
          'healthCategory',
          healthInsuranceData,
          healthCategory
        )
      ) {
        handleSelectHealthCategoryPlan(
          filterItems(
            healthCategory,
            'healthCategory',
            healthInsuranceData,
            healthCategory
          )
        );
      }
      if (
        healthPlan.length &&
        !firstCallHealth &&
        filterItems(healthPlan, 'healthPlan', healthInsuranceData, healthPlan)
      ) {
        setSelectedHealthPlan(
          filterItems(healthPlan, 'healthPlan', healthInsuranceData, healthPlan)
        );
        setHealthInsuranceFullData({
          healthInsurance: filterItems(
            healthInsuranceAgreement,
            'healthInsurance',
            healthInsuranceData,
            healthInsuranceAgreement
          ),
          healthCategory: filterItems(
            healthCategory,
            'healthCategory',
            healthInsuranceData,
            healthCategory
          ),
          healthPlan: filterItems(
            healthPlan,
            'healthPlan',
            healthInsuranceData,
            healthPlan
          ),
        });
      }
    } catch {
      setErrorBackoffice(true);
    } finally {
      setLoadingBackoffice(false);
    }
  }

  function saveIdInstitute(id) {
    setLoadingDashBoardBackOffice(true);
    setInstituteData(previous => ({ ...previous, code: id }));
    localStorage.setItem('@HSL-B2B:idInstitute', id);
    handleInstitute();
  }

  function getAccountType() {
    return (
      localStorage.getItem('@HSL-B2B:accountType') || 'Conta do responsável'
    );
  }

  useEffect(() => {
    if (!instituteData && getAccountType().toLowerCase() === 'conta admin') {
      handleInstitute();
    }
  }, [instituteData]);

  useEffect(() => {
    if (instituteData && healthInsuranceAgreement.length === 0) {
      handleListHealthInsuranceAgreement();
    }
    if (
      instituteData &&
      healthInsuranceAgreement.length > 0 &&
      getAccountType().toLowerCase() === 'conta admin'
    ) {
      healthInsurance();
    }
  }, [instituteData, healthInsuranceAgreement, healthCategory]);

  useEffect(() => {
    if (healthPlan.length) {
      setSelectedHealthPlan(
        filterItems(healthPlan, 'healthPlan', healthInsuranceData, healthPlan)
      );
    }
  }, [healthPlan]);

  function handleListHealthInsuranceAgreement() {
    setIsLoading(previous => ({ ...previous, healthInsuranceAgreement: true }));
    HealthServices.getHealthInsuranceAgreement()
      .then(res => {
        if (res) {
          setHealthInsuranceAgreement(res);
        }
      })
      .catch(({ response }) => {
        callToError('healthInsuranceAgreement', response);
      })
      .finally(() =>
        setIsLoading(previous => ({
          ...previous,
          healthInsuranceAgreement: false,
        }))
      );
  }
  const handleSelectHealthInsuranceAgreement = useCallback(
    HIA => {
      setIsLoading(previous => ({ ...previous, healthCategory: true }));
      setSelectedHealthInsuranceAgreement(HIA);
      setSelectedHealthCategory(initialValue);
      setSelectedHealthPlan(initialValue);
      setHealthCategory([]);
      setHealthPlan([]);
      HealthServices.getHealthPlanCategory(HIA.healthInsuranceCode)
        .then(res => {
          setHealthCategory(res);
        })
        .catch(({ response }) => {
          callToError('healthPlanCategory', response, HIA.healthInsuranceCode);
        })
        .finally(() => {
          setIsLoading(previous => ({
            ...previous,
            healthCategory: false,
          }));
        });
    },
    [selectedHealthCategory, selectedHealthInsuranceAgreement, healthCategory]
  );
  const handleSelectHealthCategoryPlan = useCallback(
    HC => {
      setIsLoading(previous => ({ ...previous, healthPlan: true }));
      setSelectedHealthCategory(HC);
      setSelectedHealthPlan(initialValue);
      setHealthPlan([]);
      HealthServices.getHealthPlan(HC.healthInsuranceCode, HC.healthCategory)
        .then(res => {
          if (res) {
            setHealthPlan(res);
          }
        })
        .catch(({ response }) => {
          callToError('healthPlan', response, HC);
        })
        .finally(() => {
          setIsLoading(previous => ({
            ...previous,
            healthPlan: false,
          }));
        });
    },
    [selectedHealthPlan, selectedHealthCategory, healthPlan]
  );

  function resetValuesOriginalHealth() {
    setSelectedHealthInsuranceAgreement(
      healthInsuranceFullData.healthInsurance
    );
    setSelectedHealthCategory(healthInsuranceFullData.healthCategory);
    setSelectedHealthPlan(healthInsuranceFullData.healthPlan);
  }
  function callToError(type, response, values) {
    const action = {
      healthInsuranceAgreement: () => handleListHealthInsuranceAgreement(),
      healthPlanCategory: () => handleSelectHealthInsuranceAgreement(values),
      healthPlan: () => handleSelectHealthCategoryPlan(values),
    };
    addToast({
      type: 'error',
      title: Utils.getError('title', response),
      message: Utils.getError('message', response) || 'Erro desconhecido',
      keepOpen: true,
      linkAction: action[type],
      linkText: 'Recarregar',
    });
  }

  function getListSpecialties() {
    setLoadingBackoffice(true);
    getSpecialtiesBackoffice()
      .then(res => {
        setErrorBackoffice(false);
        setSpecialties(res);
      })
      .catch(() => {
        setErrorBackoffice(true);
      })
      .finally(() => setLoadingBackoffice(false));
  }

  const valuesBackOfficeProvider = useMemo(
    () => ({
      loadingBackoffice,
      errorBackoffice,
      handleInstitute,
      saveInstitute,
      getInstitute,
      saveIdInstitute,
      getIdInstitute,
      getTranslateStatus,

      isLoading,
      healthInsuranceAgreement,
      healthCategory,
      healthPlan,
      handleSelectHealthInsuranceAgreement,
      handleSelectHealthCategoryPlan,
      selectedHealthInsuranceAgreement,
      selectedHealthCategory,
      selectedHealthPlan,
      setSelectedHealthPlan,
      setSelectedHealthInsuranceAgreement,
      setSelectedHealthCategory,
      setHealthPlan,
      healthInsurance,
      healthInsuranceData,
      healthInsuranceFullData,
      resetValuesOriginalHealth,
      setHealthInsuranceData,
      setfirstCallHealth,
      setLoadingBackoffice,
      setSpecialties,
      loadingDashBoardBackOffice,
      specialties,
      getListSpecialties,
    }),
    [
      setLoadingBackoffice,
      loadingBackoffice,
      errorBackoffice,
      handleInstitute,
      saveInstitute,
      getInstitute,
      saveIdInstitute,
      getIdInstitute,
      getTranslateStatus,

      isLoading,
      healthInsuranceAgreement,
      healthCategory,
      healthPlan,
      handleSelectHealthInsuranceAgreement,
      handleSelectHealthCategoryPlan,
      selectedHealthInsuranceAgreement,
      selectedHealthCategory,
      selectedHealthPlan,
      setSelectedHealthPlan,
      setSelectedHealthInsuranceAgreement,
      setSelectedHealthCategory,
      setHealthPlan,
      healthInsurance,
      healthInsuranceData,
      healthInsuranceFullData,
      resetValuesOriginalHealth,
      setHealthInsuranceData,
      setfirstCallHealth,
      setSpecialties,
      loadingDashBoardBackOffice,
      specialties,
      getListSpecialties,
    ]
  );

  return (
    <BackOfficeContext.Provider value={valuesBackOfficeProvider}>
      {children}
    </BackOfficeContext.Provider>
  );
}

BackOfficeProvider.propTypes = {
  children: propTypes.node.isRequired,
};
