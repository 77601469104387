/* eslint-disable no-unused-expressions */

/* eslint-disable no-console */
import { analyticsInstance } from './main';

const DEBUG_LOG = process.env.REACT_APP_GA_DEBUG === 'true';

export function logPageView(title, page) {
  try {
    DEBUG_LOG &&
      console.log('Analytics Page View Log: ', {
        hitType: 'pageview',
        title,
        page,
      });
    analyticsInstance.send({
      hitType: 'pageview',
      title,
      page,
    });
  } catch (error) {
    console.log('Error LogPageView ===> ', error);
  }
}

export function logEvent(event, params, typeSystem) {
  const returnTypeSystem = {
    agent: null,
    institute: 'Instituição',
    backoffice: 'Backoffice',
  };

  try {
    analyticsInstance.event(
      returnTypeSystem[typeSystem]
        ? `${event} - ${returnTypeSystem[typeSystem]}`
        : `${event}`,
      params
    );
    DEBUG_LOG &&
      console.log(
        'Analytics Event Log: ',
        returnTypeSystem[typeSystem]
          ? `${event} - ${returnTypeSystem[typeSystem]}`
          : `${event}`,
        params
      );
  } catch (error) {
    console.log('Error LogEvent ===> ', error);
  }
}
