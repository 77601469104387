import * as D from '@tempo/tempo-design-system-core/dist';

import propTypes from 'prop-types';

import * as S from './styles';

export function StatusIndicator({ number }) {
  const status = {
    1: 'Ativo',
    2: 'Inativo',
    3: 'Removido',
  };
  return (
    <S.StatusWrapper>
      <S.StatusIndicator active={number === 1} />
      <D.TmpTypography component="paragraph" size="small" color="#292929">
        {status[number]}
      </D.TmpTypography>
    </S.StatusWrapper>
  );
}

StatusIndicator.propTypes = {
  number: propTypes.number.isRequired,
};
