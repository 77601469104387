import styled from 'styled-components';

export const ContainerError = styled.div`
  margin-top: ${({ marginTop }) => marginTop ?? '16px'};
  display: flex;
  grid-column: 1 / 13;
  height: ${({ height }) => height ?? '201px'};
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #f5f5f5;
  border-radius: 16px;
  gap: 24px;
  margin-left: ${({ marginHorizontal }) => marginHorizontal};
  margin-right: ${({ marginHorizontal }) => marginHorizontal};
  .tmp-typography__paragraph--small {
    color: #666666;
    font-weight: 400;
    font-size: 16px;
  }
`;
export const ContainerButton = styled.div`
  margin-bottom: ${({ noMarginBottom }) => (noMarginBottom ? '0px' : '20px')};
  button {
    .label {
      font-weight: 400;
      font-size: 16px;
    }
    min-width: 100%;
    .icon {
      padding-top: 3px;
      padding-right: 10px;
    }
    svg {
      width: 20px;
      height: 20px;
      path {
        fill: ${({ loading, color }) => (!loading ? color : 'transparent')};
        transition: ${({ loading }) => (!loading ? 'all ease 500ms' : 'auto')};
      }
    }
  }
  button:hover {
    svg {
      path {
        fill: ${({ loading, colorHover }) =>
          !loading ? colorHover : 'transparent'};
      }
    }
  }
`;
