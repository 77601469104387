import React from 'react';

import { TmpAlert } from '@tempo/tempo-design-system-core/dist';

import { Hooks } from '@b2b/core';
import propTypes from 'prop-types';

import { ToastContainer } from './styles';

export function Toast({
  isOpened,
  type,
  title,
  message,
  handleClose,
  linkText,
  linkHref,
  linkAction,
}) {
  const { removeToast } = Hooks.useToast();

  return (
    <ToastContainer isOpened={isOpened}>
      <TmpAlert
        isOpened={isOpened}
        type={type}
        title={title}
        message={message}
        handleClose={handleClose || (() => removeToast())}
        linkText={linkText}
        linkHref={linkHref}
        linkAction={linkAction}
      />
    </ToastContainer>
  );
}
Toast.defaultProps = {
  title: 'Sucesso',
  message: 'Parabéns, deu tudo certo!',
  type: 'success',
  handleClose: null,
  linkText: null,
  linkHref: null,
  linkAction: null,
};
Toast.propTypes = {
  isOpened: propTypes.bool.isRequired,
  type: propTypes.oneOf(['success', 'error']),
  title: propTypes.string,
  message: propTypes.string,
  handleClose: propTypes.element,
  linkText: propTypes.string,
  linkHref: propTypes.string,
  linkAction: propTypes.element,
};
