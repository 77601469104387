import { createContext, useMemo, useState } from 'react';

import JWTDecode from 'jwt-decode';
import propTypes from 'prop-types';

const UserContext = createContext();

export default UserContext;

export function UserProvider({ children }) {
  const [allStateList, setAllStateList] = useState([]);
  const [instituteUser, setInstituteUser] = useState([]);
  const [specialties, setSpecialties] = useState([]);
  function saveAccountType(type) {
    localStorage.setItem('@HSL-B2B:accountType', type);
  }

  function saveToken(token) {
    localStorage.setItem('@HSL-B2B:token', token);
  }

  function getUser() {
    const userDecoded = JWTDecode(localStorage.getItem('@HSL-B2B:token'));
    return { ...userDecoded.data, specialties };
  }

  function getAccountType() {
    return (
      localStorage.getItem('@HSL-B2B:accountType') || 'Conta do responsável'
    );
  }

  function clearUser() {
    localStorage.removeItem('@HSL-B2B:accountType');
    localStorage.removeItem('@HSL-B2B:token');
    localStorage.removeItem('@HSL-B2B:idInstitute');
  }

  function getName(type) {
    const username = getUser()?.name;
    if (type === 'first') {
      return username.split(' ')[0];
    }
    if (type === 'firstLetters') {
      const firstLetters = username
        .split(' ')
        .reduce((acc, subname) => acc + subname[0], '');

      return firstLetters;
    }
    if (type === 'firstLetter') {
      const firstLetter = username.split(' ')[0].charAt(0);

      return firstLetter;
    }
    if (
      type.toLowerCase() === 'conta do responsável' ||
      type.toLowerCase() === 'conta admin'
    ) {
      const splitName = username.split(' ');
      const firstName = splitName[0];
      const secondName = splitName[1] || '';
      const twoNames = `${firstName} ${secondName}`;
      return twoNames;
    }
    if (type.toLowerCase() === 'conta institucional') {
      const formattedUsername =
        username.length > 32
          ? `${username.substring(0, 32).trim()}...`
          : username;
      return formattedUsername;
    }
    return username;
  }

  const valuesUserProvider = useMemo(
    () => ({
      getUser,
      getName,
      allStateList,
      setAllStateList,
      saveToken,
      getAccountType,
      saveAccountType,
      clearUser,
      instituteUser,
      setInstituteUser,
      setSpecialties,
      specialties,
    }),
    [
      getUser,
      getName,
      allStateList,
      setAllStateList,
      saveToken,
      getAccountType,
      saveAccountType,
      clearUser,
      instituteUser,
      setInstituteUser,
      setSpecialties,
      specialties,
    ]
  );

  return (
    <UserContext.Provider value={valuesUserProvider}>
      {children}
    </UserContext.Provider>
  );
}

UserProvider.propTypes = {
  children: propTypes.node.isRequired,
};
