/* eslint-disable react/prop-types */
import * as I from '@tempo/tempo-assets/dist/svgs/icons';
import * as D from '@tempo/tempo-design-system-core/dist';

import { ReactSVG } from 'react-svg';

import * as S from './styles';

export function CardList({ linkTitle, linkHref, listItems, handleClick }) {
  return (
    <S.Wrapper>
      <S.Title>
        <D.TmpTypography component="paragraph" color="#A3A3A3">
          Responsáveis
        </D.TmpTypography>
      </S.Title>
      <S.ListItem>
        {listItems.map(item => (
          <S.Item>
            <S.ContentItem>
              <S.Label>
                <D.TmpTypography component="paragraph">
                  {item.name}
                </D.TmpTypography>
              </S.Label>
              <S.SubLabel>
                <D.TmpTypography component="paragraph">
                  ({item.role})
                </D.TmpTypography>
              </S.SubLabel>
            </S.ContentItem>
            <S.ActionItem>
              <D.TmpButtonIcon
                size="sm"
                icon={<ReactSVG src={I.email} />}
                handleClick={() => window.open(`mailto:${item.email}`)}
              />
            </S.ActionItem>
          </S.Item>
        ))}
      </S.ListItem>
      <S.Title>
        <D.TmpTypography component="paragraph" color="#A3A3A3">
          Links
        </D.TmpTypography>
      </S.Title>
      <S.ListItem>
        <S.Item>
          <D.TmpLink onClick={handleClick} href={linkHref} target="_blank" icon>
            {linkTitle}
          </D.TmpLink>
        </S.Item>
      </S.ListItem>
    </S.Wrapper>
  );
}
