export const successMessages = [
  {
    statusCode: 'A',
    title: 'Status alterado com sucesso',
    message:
      'Os dados da instituição estão visíveis e seu acesso está concedido.',
    analyticsEvent: 'Sucesso ao ativar instituição',
    analyticsEventError: 'Falha ao ativar instituição',
  },
  {
    statusCode: 'I',
    title: 'Status alterado com sucesso',
    message:
      'Os dados da instituição ainda ficarão visíveis, caso deseje torná-lo ativo novamente.',
    analyticsEvent: 'Sucesso ao inativar instituição',
    analyticsEventError: 'Falha ao inativar instituição',
  },
  {
    statusCode: 'R',
    title: 'Instituição excluída com sucesso',
    message: 'Os dados da instituição foram excluídos definitivamente.',
    analyticsEvent: 'Sucesso ao excluir instituição',
    analyticsEventError: 'Falha ao excluir instituição',
  },
];
