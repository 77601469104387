import { device } from '@b2b/core/src/utils';
import styled from 'styled-components';

export const Wrapper = styled.section`
  display: flex;
  gap: 3rem;
  padding: 6rem 10rem 6rem 6rem;
  justify-content: center;
  align-items: center;
  @media ${device.laptop} {
    flex-direction: column;
    gap: 0;
    padding: 1.5rem;
  }
`;

export const WrapperImage = styled.div``;

export const ImageView = styled.img`
  @media ${device.laptop} {
    width: 80%;
    padding: 1.5rem;
  }
`;

export const WrapperContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 390px;
  min-width: 312px;
  margin-top: -8rem;
  @media ${device.laptop} {
    max-width: 100%;
    height: auto;
    text-align: center;
    margin-top: 0;
  }
`;

export const WrapperTypography = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media ${device.laptop} {
    margin: 0 2rem;
    width: 250px;
  }
`;

export const WrapperButton = styled.div`
  display: flex;
  justify-content: flex-end;
  height: 48px;
  width: 100%;
  max-width: 850px;
  min-width: 772px;
  margin-top: -18rem;
  @media ${device.laptop} {
    justify-content: center;
    width: 100%;
    max-width: 390px;
    min-width: 312px;
    margin-top: 0;
    button {
      width: 90%;
    }
  }
`;
