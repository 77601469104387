import { GlobalProviders } from '@b2b/core/src/contexts/GlobalProviders';
import { Router } from 'routes';
import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';

const GlobalStyle = createGlobalStyle`
  ${reset}
  * {
    scroll-behavior: smooth;
  }
  .formControlForAutoCompleteDisabled {
    border-color: #666666;
    color: #666666 !important;
    .tmp-select__label {
      color: #666666 !important;
    }
    div:nth-child(2) {
      border-color: #666666;
      color: #666666 !important;
      > button {
        background: #E0E0E0;
        border-color: #666666;
        color: #666666 !important;
      }
    }
  }
  .formControlForSelectDisabled {
    border-color: #666666;
    color: #666666 !important;
    .tmp-select__label {
      color: #666666 !important;
    }
    div:nth-child(2) {
      border-color: #666666;
      color: #666666 !important;
      > button {
        background: #E0E0E0;
        border-color: #666666;
        color: #666666 !important;
      }
    }
  }
  .formControlForInputDisabled, .formControlForSelectDisabled, .formControlForAutoCompleteDisabled, .formControlForDatePickerDisabled, .formControlForPhoneDisabled {
    input:disabled {
      color: #666666 !important;
      border-color: #666666;
    }
    label {
      color: #666666;
    }
    .input-wrapper, .autocomplete-wrapper {
      border-color: #666666;
      color: #666666 !important;
      background: #E0E0E0;
    }
  }
  .helperForInputDisabled, .formControlForSelectDisabled, .formControlForAutoCompleteDisabled, .helperForDatepickerDisabled, .helperForPhoneInputDisabled  {
    span {
      color: #666666;
    }
  }
`;

export function Main() {
  return (
    <GlobalProviders>
      <GlobalStyle />
      <Router />
    </GlobalProviders>
  );
}
