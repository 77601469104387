import { Utils } from '@b2b/core';
import styled from 'styled-components';

export const Wrapper = styled.section`
  display: flex;
  gap: 5.375rem;
  padding: 6.3125rem 18.06rem 10.37rem 11.81rem;
  justify-content: center;
  align-items: center;
  @media ${Utils.device.laptop} {
    img {
      display: none;
    }
    flex-direction: column;
    gap: 0;
    padding: 2rem 1.5rem;
  }
`;

export const InformationWrapper = styled.div`
  margin-top: 8.18rem;
  form > div {
    height: 100px;
  }
  .input-wrapper {
    max-width: 381px;
    width: 100%;
  }

  p {
    margin: 1rem 0rem 1.5rem 0rem;
    max-width: 381px;
  }
  .tmp-typography__heading--large {
    font-size: 32px;
  }
  @media ${Utils.device.laptop} {
    align-items: flex-start;
    margin: 2rem auto;
    display: flex;
    flex-direction: column;
    max-width: 381px;
    form {
      width: 100%;
    }
  }
`;

export const InformationActions = styled.div`
  display: flex;
  justify-content: flex-end;
  height: 56px;
  margin-top: 1.875rem;
  @media ${Utils.device.laptop} {
    width: 100%;
    justify-content: center;
    button {
      width: 100%;
    }
  }
`;
