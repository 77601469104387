import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const ContainerIcon = styled.div`
  width: 33px;
  height: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: ${({ borderColorIcon }) =>
    borderColorIcon && `1px solid ${borderColorIcon}`};
  svg {
    width: 13px;
    height: 13px;
    path {
      fill: ${({ color }) => color && color};
    }
  }
`;

export const ContainerInformation = styled.div`
  margin-left: 1rem;
  display: flex;
  flex-direction: column;
  .tmp-typography__heading--medium {
    color: ${({ color }) => color && color};
    font-weight: 700;
    font-size: 20px;
  }
  .tmp-typography__caption {
    font-weight: 400;
    font-size: 14px;
    color: #666666;
  }
`;
