import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  padding: 16px;
  gap: 8px;
  background: #f5f5f5;
  border-radius: 8px;
  flex-direction: column;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 18px;
  align-items: center;
  .tmp-typography__heading--small {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 133%;
    letter-spacing: -0.01em;
    color: #666666;
  }
  .tmp-icon svg {
    width: 24px;
    height: 24px;
    path {
      fill: #666666;
    }
  }
`;

export const Content = styled.div`
  margin-left: 40px;
  .tmp-typography__paragraph--small {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #666666;
  }
`;
