const routes = [
  {
    path: '/',
    breadcrumb: 'Início',
  },
  {
    path: '/atendimentos',
    breadcrumb: 'Atendimentos',
  },
  {
    path: '/responsaveis',
    breadcrumb: 'Responsáveis',
  },
  {
    path: '/meus-dados',
    breadcrumb: 'Meus Dados',
  },
  {
    path: '/cadastro-instituicao',
    breadcrumb: 'Novo Cliente',
  },
  {
    path: 'detalhes-do-cliente/responsaveis',
    breadcrumb: 'Responsáveis',
  },
];

export default routes;
