/* eslint-disable no-unneeded-ternary */

/* eslint-disable no-nested-ternary */
import { useEffect, useState } from 'react';

import { Hooks, Utils } from '@b2b/core';
import { getHealthInsurance } from '@b2b/core/src/services/healthInsurance.service';

export function InstituteData(isBackOffice) {
  const [insurance, setInsurance] = useState({});
  const {
    getInstitute,
    isLoading,
    selectedHealthInsuranceAgreement,
    healthInsuranceAgreement,
    handleSelectHealthInsuranceAgreement,
    selectedHealthCategory,
    healthCategory,
    handleSelectHealthCategoryPlan,
    selectedHealthPlan,
    healthPlan,
    setSelectedHealthPlan,
    setfirstCallHealth,
  } = Hooks.useBackOffice();
  const { getUser } = Hooks.useUser();
  const {
    accessToken,
    code,
    codeTasy,
    state,
    name,
    fantasyName,
    address,
    addressNumber,
    cep,
    city,
    cnpj,
    complement,
    email,
    ddiPhoneNumber,
    dddPhoneNumber,
    phoneNumber,
    neighborhood,
    status,
    hasCompanionRequired,
    specialties,
  } = isBackOffice ? getInstitute() : getUser();
  const adaptSpecialties = specialties
    .filter(filtered => filtered.selected)
    .map(item => item.description)
    .join(', ');

  const { states, stateDescriptionByInitials } = Hooks.useStateList();

  function maxLengthInputNumber(value) {
    document.querySelectorAll('input[type="number"]').forEach(input => {
      input.oninput = () => {
        if (input.value.length > value)
          input.value = input.value.slice(0, value);
      };
    });
  }
  useEffect(() => {
    if (code) {
      getHealthInsurance(code)
        .then(response => {
          setInsurance({
            convention: response?.healthInsurance || '',
            category: response?.healthCategory || '',
            plan: response?.healthPlan || '',
          });
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.log(error);
        });
    }
  }, [code]);

  const getInstituteData = [
    {
      class: 'instituteDataLabelCorporateName',
      type: 'heading',
      content: 'Razão social',
    },
    {
      class: 'institutionDataCorporateName',
      editClass: 'editInstituteDataCorporateName',
      type: 'paragraph',
      content: name,
      label: 'Razão social',
      name: 'corporateName',
      placeholder: 'Nome da instituição',
      disabled: true,
      capitalize: true,
    },
    {
      class: 'instituteDataLabelName',
      type: 'heading',
      content: 'Nome fantasia',
    },
    {
      class: 'instituteDataName',
      editClass: 'editInstituteDataName',
      type: 'paragraph',
      content: Utils.capitalize(fantasyName),
      label: 'Nome fantasia',
      name: 'fantasyName',
      placeholder: 'Digite o nome fantasia da instituição',
      capitalize: true,
    },
    {
      class: 'instituteDataLabelStatus',
      type: 'heading',
      content: 'Status',
    },
    {
      class: 'instituteDataStatus',
      editClass: 'editInstituteDataStatus',
      type: 'paragraph',
      content: status === 'A' ? 'Ativo' : 'Inativo',
      label: 'Status',
      name: 'status',
      placeholder: 'Selecione o status',
      disabled: isBackOffice ? false : true,
      listItem: [
        {
          label: 'Ativo',
          value: 'A',
        },
        {
          label: 'Inativo',
          value: 'I',
        },
      ],
      typeInput: 'select',
    },
    {
      class: 'instituteDataLabelDocument',
      type: 'heading',
      content: 'Documento',
    },
    {
      class: 'instituteDataDocument',
      editClass: 'editInstituteDataDocument',
      type: 'paragraph',
      content: 'CNPJ',
      label: 'Documento',
      name: 'typeDocument',
      placeholder: 'CNPJ',
      disabled: true,
      typeInput: 'select',
    },
    {
      class: 'instituteDataLabelNumberDocument',
      type: 'heading',
      content: 'Nº do documento',
    },
    {
      class: 'instituteDataNumberDocument',
      editClass: 'editInstituteDataNumberDocument',
      type: 'paragraph',
      content: Utils.formatCNPJ(cnpj),
      label: 'Nº do documento',
      name: 'cnpj',
      placeholder: 'Digite o número do documento',
      disabled: true,
      mask: 'cnpj',
    },
    {
      class: 'instituteDataLabelEmail',
      type: 'heading',
      content: 'E-mail',
    },
    {
      class: 'instituteDataEmail',
      editClass: 'editInstituteDataEmail',
      type: 'paragraph',
      content: email,
      label: 'E-mail',
      name: 'email',
      placeholder: 'Digite o e-mail',
    },
    {
      class: 'instituteDataLabelPhone',
      type: 'heading',
      content: 'Celular',
    },
    {
      class: 'instituteDataPhone',
      editClass: 'editInstituteDataPhone',
      type: 'paragraph',
      content: Utils.formatCellphone(
        `${ddiPhoneNumber}${dddPhoneNumber}${phoneNumber}`
      ),
      label: 'Celular',
      name: 'phoneNumber',
      placeholder: '+55 (00) 00000-0000',
      mask: 'ddiphone',
      maxLength: 19,
    },
    {
      class: 'instituteDataLabelCEP',
      type: 'heading',
      content: 'CEP',
    },
    {
      class: 'instituteDataCEP',
      editClass: 'editInstituteDataCEP',
      type: 'paragraph',
      content: Utils.formatCEP(cep),
      label: 'CEP',
      name: 'cep',
      placeholder: '00000-000',
      mask: 'cep',
      maxLength: 9,
    },
    {
      class: 'instituteDataLabelState',
      type: 'heading',
      content: 'Estado',
    },
    {
      class: 'instituteDataState',
      editClass: 'editInstituteDataState',
      type: 'paragraph',
      content: stateDescriptionByInitials(state),
      label: 'Estado',
      name: 'state',
      placeholder: 'Selecione o Estado',
      typeInput: 'select',
      listItem: states
        ? states?.map(country => ({
            label: country?.description,
            value: country?.initials,
          }))
        : [],
    },
    {
      class: 'instituteDataLabelCity',
      type: 'heading',
      content: 'Cidade',
    },
    {
      class: 'instituteDataCity',
      editClass: 'editInstituteDataCity',
      type: 'paragraph',
      content: Utils.capitalize(city),
      label: 'Cidade',
      name: 'city',
      placeholder: 'Digite a cidade',
      capitalize: true,
    },
    {
      class: 'instituteDataLabelNeighborhood',
      type: 'heading',
      content: 'Bairro',
    },
    {
      class: 'instituteDataNeighborhood',
      editClass: 'editInstituteDataNeighborhood',
      type: 'paragraph',
      content: Utils.capitalize(neighborhood),
      label: 'Bairro',
      name: 'neighborhood',
      placeholder: 'Digite o bairro',
      capitalize: true,
    },
    {
      class: 'instituteDataLabelPublicPlace',
      type: 'heading',
      content: 'Logradouro',
    },
    {
      class: 'instituteDataPublicPlace',
      editClass: 'editInstituteDataAddress',
      type: 'paragraph',
      content: Utils.capitalize(address),
      label: 'Logradouro',
      name: 'address',
      placeholder: 'Digite o logradouro',
      disabled: true,
      capitalize: true,
    },
    {
      class: 'instituteDataLabelNumber',
      type: 'heading',
      content: 'Número',
    },
    {
      class: 'instituteDataNumber',
      editClass: 'editInstituteDataAddressNumber',
      type: 'paragraph',
      content: addressNumber,
      label: 'Número',
      name: 'addressNumber',
      placeholder: 'Digite o número',
      input: 'number',
      maxLength: maxLengthInputNumber(5),
    },
    {
      class: 'instituteDataLabelComplement',
      type: 'heading',
      content: 'Complemento',
    },
    {
      class: 'instituteDataComplement',
      editClass: 'editInstituteDataComplement',
      type: 'paragraph',
      content: Utils.capitalize(complement) || '--',
      label: 'Complemento (opcional)',
      name: 'complement',
      placeholder: 'Digite o complemento',
      capitalize: true,
    },
    {
      class: 'instituteDataLabelConvention',
      type: 'heading',
      content: 'Convênio',
    },
    {
      class: 'instituteDataConvention',
      editClass: 'editInstituteDataConvention',
      type: 'paragraph',
      content: insurance.convention || 'Particular',
      label: 'Convênio',
      name: 'convention',
      placeholder: 'Selecione o convênio',
      typeInput: 'autoComplete',
      value: isBackOffice
        ? selectedHealthInsuranceAgreement
        : { description: insurance.convention },
      listItems: isBackOffice ? healthInsuranceAgreement : [],
      labelKey: 'description',
      disabled: isBackOffice ? false : true,
      loading: isBackOffice ? isLoading.healthInsuranceAgreement : false,
      fieldsToClean: ['category', 'plan'],
      handleChange: HIA => {
        setfirstCallHealth(true);
        handleSelectHealthInsuranceAgreement(HIA);
      },
    },
    {
      class: 'instituteDataLabelCategory',
      type: 'heading',
      content: 'Categoria',
    },
    {
      class: 'instituteDataCategory',
      editClass: 'editInstituteDataCategory',
      type: 'paragraph',
      content: insurance.category || 'Particular',
      label: 'Categoria',
      name: 'category',
      placeholder: 'Selecione a categoria',
      value: isBackOffice
        ? selectedHealthCategory
        : { description: insurance.category },
      typeInput: 'autoComplete',
      listItems: isBackOffice ? healthCategory ?? [] : [],
      labelKey: 'description',
      disabled: isBackOffice ? !healthCategory.length : true,
      loading: isBackOffice ? isLoading.healthCategory : false,
      handleChange: HC => handleSelectHealthCategoryPlan(HC),
      fieldsToClean: ['plan'],
    },
    {
      class: 'instituteDataLabelPlan',
      type: 'heading',
      content: 'Plano',
    },
    {
      class: 'instituteDataPlan',
      editClass: 'editInstituteDataPlan',
      type: 'paragraph',
      content: insurance.plan || 'Particular',
      label: 'Plano',
      name: 'plan',
      value: isBackOffice
        ? selectedHealthPlan
        : { description: insurance.plan },
      placeholder: 'Selecione o plano',
      typeInput: 'autoComplete',
      listItems: isBackOffice ? healthPlan ?? [] : [],
      labelKey: 'description',
      disabled: isBackOffice ? !healthPlan.length : true,
      loading: isBackOffice
        ? selectedHealthCategory
          ? isLoading.healthPlan
          : false
        : false,
      handleChange: HP => setSelectedHealthPlan(HP),
    },
    {
      class: 'instituteDataLabelCompanion',
      type: 'heading',
      content: 'Acompanhante Digital obrigatório?',
    },
    {
      class: 'instituteDataCompanion',
      editClass: 'editInstituteDataCompanion',
      type: 'paragraph',
      content: hasCompanionRequired === 0 ? 'Não' : 'Sim',
      label: 'Acompanhante Digital obrigatório?',
      name: 'hasCompanionRequired',
      placeholder: 'Selecione uma opção',
      disabled: isBackOffice ? false : true,
      listItem: [
        {
          label: 'Não',
          value: '0',
        },
        {
          label: 'Sim',
          value: '1',
        },
      ],
      typeInput: 'select',
    },
    {
      class: 'instituteDataLabelSpecialties',
      type: 'heading',
      content: 'Especialidades atendidas',
    },
    {
      class: 'instituteDataSpecialties',
      editClass: 'editInstituteDataSpecialties',
      type: 'paragraph',
      content:
        adaptSpecialties.length > 0
          ? `${adaptSpecialties}.`
          : 'Nenhuma especialidade selecionada',
      listItems: specialties ?? [],
      mainKey: 'specialtyCode',
      selectedKey: 'selected',
      label: isBackOffice
        ? 'Selecione as especialidades'
        : 'Especialidades atendidas',
      name: 'specialties',
      showSelectedAsFalse: true,
      handleChange: () => {},
      disabled: isBackOffice ? false : true,
      startLines: 3,
      maxColumns: 6,
      typeInput: 'checkboxGroup',
    },
  ];

  return {
    accessToken,
    code,
    codeTasy,
    state,
    getInstituteData,
    name,
    fantasyName,
    address,
    addressNumber,
    cep,
    city,
    cnpj,
    complement,
    email,
    ddiPhoneNumber,
    dddPhoneNumber,
    phoneNumber,
    neighborhood,
    status,
    hasCompanionRequired,
    specialties,
  };
}
