import { Utils } from '@b2b/core';

export default function getStateName(state) {
  const normalizeState = {
    'SAO PAULO': 'São Paulo',
    'ESPIRITO SANTO': 'Espírito Santo',
    'MARANH#O': 'Maranhão',
    BRASILIA: 'Brasília',
    PARANA: 'Paraná',
    AMAPA: 'Amapá',
    CEARA: 'Ceará',
    GOIAS: 'Goiás',
    PARA: 'Pará',
    PARAIBA: 'Paraíba',
    PIAUI: 'Piauí',
    RONDONIA: 'Rondônia',
  };

  if (normalizeState[state]) {
    return normalizeState[state];
  }
  return Utils.capitalize(state?.toLowerCase());
}
