import { device } from '@b2b/core/src/utils';
import styled from 'styled-components';

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: rgba(44, 110, 242, 0.02);
  width: 100%;
  min-height: calc(100vh - 89px);
  @media ${device.laptop} {
    display: inherit;
  }
`;

export const Container = styled.section`
  padding: 1.75rem 5.5rem 3.75rem;
  width: 100%;
  max-width: 1366px;
  box-sizing: border-box;
`;

export const WrapperTypography = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${({ newAgent }) => (newAgent ? 'center' : 'space-between')};
  margin-bottom: 1.5rem;
  .containerEditor {
    display: flex;
    align-items: center;
  }
  .tmp-typography__heading--medium {
    color: #231f20;
    font-weight: 700;
    font-size: 24px;
    margin-left: 0.5rem;
  }
  path {
    fill: ${({ editType }) => (editType ? 'rgb(0, 0, 0)' : '#2C6EF2')};
  }
  @media ${device.laptop} {
    justify-content: space-between;
    margin: 0 1rem;
    width: 95%;
  }
`;

export const WrapperCardShape = styled.div`
  .cardShape {
    grid-column: 1 / 13;
    border-radius: 16px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
    > div {
      border: 0;
      padding: 0;
    }
    @media ${device.laptop} {
      > div {
        padding: 32px 0;
      }
    }
    .input-wrapper {
      min-width: auto;
      input:disabled {
        color: rgba(0, 0, 0, 0.32);
      }
    }
  }
  @media ${device.laptop} {
    width: 100%;
  }
`;

export const WrapperInstituteData = styled.section`
  padding: 2.5rem;
  display: grid;
  align-items: center;
  grid-template-columns: repeat(12, 1fr);
  gap: 0.5rem 1.5rem;
  .loadingFeedback {
    grid-column: 6 / 8;
  }
  .tmp-typography__heading--small {
    color: #231f20;
    font-size: 16px;
  }
  .instituteDataTitle {
    grid-column: 1 / 13;
    margin-bottom: 1.5rem;
    h1 {
      font-size: 18px;
      color: #231f20;
    }
  }
  .instituteDataLabelName {
    grid-row: 2;
    grid-column: 1 / 8;
  }
  .instituteDataName {
    grid-row: 3;
    grid-column: 1 / 8;
    margin-bottom: 1.5rem;
  }
  .editInstituteDataName {
    grid-row: 3;
    grid-column: 1 / 7;
    > div {
      height: 120px;
    }
  }
  .instituteDataLabelStatus {
    grid-row: 2;
    grid-column: 8 / 13;
  }
  .instituteDataStatus {
    grid-row: 3;
    grid-column: 8 / 13;
    margin-bottom: 1.5rem;
  }
  .editInstituteDataStatus {
    grid-row: 3;
    grid-column: 7 / 13;
    > div {
      height: 120px;
    }
    button {
      min-width: auto;
      width: 100%;
    }
  }
  .instituteDataLabelDocument {
    grid-row: 4;
    grid-column: 1 / 4;
  }
  .instituteDataDocument {
    grid-row: 5;
    grid-column: 1 / 4;
    margin-bottom: 1.5rem;
  }
  .editInstituteDataDocument {
    grid-row: 5;
    grid-column: 1 / 4;
    > div {
      height: 120px;
    }
    button {
      min-width: auto;
      width: 100%;
    }
  }
  .instituteDataLabelNumberDocument {
    grid-row: 4;
    grid-column: 4 / 8;
    width: 100%;
    margin-left: 0;
  }
  .instituteDataNumberDocument {
    grid-row: 5;
    grid-column: 4 / 8;
    margin-left: 0;
    margin-bottom: 1.5rem;
  }
  .editInstituteDataNumberDocument {
    grid-row: 5;
    grid-column: 4 / 7;
    margin-left: 0;
    > div {
      height: 120px;
    }
  }
  .instituteDataLabelEmail {
    grid-row: 4;
    grid-column: 8 / 13;
  }
  .instituteDataEmail {
    grid-row: 5;
    grid-column: 8 / 13;
    margin-bottom: 1.5rem;
  }
  .editInstituteDataEmail {
    grid-row: 5;
    grid-column: 7 / 13;
    > div {
      height: 120px;
    }
  }
  .instituteDataLabelPhone {
    grid-row: 6;
    grid-column: 1 / 4;
  }
  .instituteDataPhone {
    grid-row: 7;
    grid-column: 1 / 4;
    margin-bottom: 1.5rem;
  }
  .editInstituteDataPhone {
    grid-row: 7;
    grid-column: 1 / 4;
    > div {
      height: 120px;
    }
  }
  .instituteDataLabelCEP {
    grid-row: 6;
    grid-column: 4 / 7;
  }
  .instituteDataCEP {
    grid-row: 7;
    grid-column: 4 / 7;
    margin-bottom: 1.5rem;
  }
  .editInstituteDataCEP {
    grid-row: 7;
    grid-column: 4 / 7;
    > div {
      height: 120px;
    }
  }
  .editButtonCEPSearch {
    grid-row: 7;
    grid-column: 7 / 10;
    padding-top: 1.1rem;
    margin-bottom: 2rem;
    width: 169px;
    button {
      padding: 0;
      width: 100%;
    }
    path {
      fill: ${props => !props.loadingCEP && '#2c6ef2'};
    }
    button:hover {
      path {
        fill: ${props => !props.loadingCEP && '#0a3a99'};
      }
    }
  }
  .instituteDataLabelState {
    grid-row: 8;
    grid-column: 1 / 4;
  }
  .instituteDataState {
    grid-row: 9;
    grid-column: 1 / 4;
    margin-bottom: 1.5rem;
  }
  .editInstituteDataState {
    grid-row: 9;
    grid-column: 1 / 4;
    > div {
      height: 120px;
    }
    button {
      min-width: auto;
      width: 100%;
    }
    div > div > ol {
      height: 180px;
      overflow: scroll;
      ::-webkit-scrollbar {
        display: none;
      }
    }
  }
  .instituteDataLabelCity {
    grid-row: 8;
    grid-column: 4 / 8;
  }
  .instituteDataCity {
    grid-row: 9;
    grid-column: 4 / 7;
    margin-bottom: 1.5rem;
  }
  .editInstituteDataCity {
    grid-row: 9;
    grid-column: 4 / 7;
    > div {
      height: 120px;
    }
    button {
      min-width: auto;
      width: 100%;
    }
    p {
      margin-left: 1.5rem;
    }
  }
  .instituteDataLabelNeighborhood {
    grid-row: 8;
    grid-column: 8 / 11;
  }
  .instituteDataNeighborhood {
    grid-row: 9;
    grid-column: 8 / 11;
    margin-bottom: 1.5rem;
  }
  .editInstituteDataNeighborhood {
    grid-row: 9;
    grid-column: 7 / 11;
    > div {
      height: 120px;
    }
  }
  .instituteDataLabelPublicPlace {
    grid-row: 10;
    grid-column: 1 / 8;
  }
  .instituteDataPublicPlace {
    grid-row: 11;
    grid-column: 1 / 8;
    margin-bottom: 1.5rem;
  }
  .editInstituteDataAddress {
    grid-row: 11;
    grid-column: 1 / 7;
    > div {
      height: 120px;
    }
  }
  .instituteDataLabelNumber {
    grid-row: 10;
    grid-column: 8 / 10;
  }
  .instituteDataNumber {
    grid-row: 11;
    grid-column: 8 / 10;
    margin-bottom: 1.5rem;
  }
  .editInstituteDataAddressNumber {
    grid-row: 11;
    grid-column: 7 / 9;
    > div {
      height: 120px;
    }
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    /* Firefox */
    input[type='number'] {
      -moz-appearance: textfield;
    }
  }
  .instituteDataLabelComplement {
    grid-row: 10;
    grid-column: 10 / 13;
  }
  .instituteDataComplement {
    grid-row: 11;
    grid-column: 10 / 13;
    margin-bottom: 1.5rem;
  }
  .editInstituteDataComplement {
    grid-row: 11;
    grid-column: 9 / 13;
    > div {
      height: 120px;
    }
  }
  .instituteDataLabelConvention {
    grid-row: 12;
    grid-column: 1 / 7;
  }
  .instituteDataConvention {
    grid-row: 13;
    grid-column: 1 / 7;
    margin-bottom: 1.5rem;
  }
  .editInstituteDataConvention {
    grid-row: 13;
    grid-column: 1 / 7;
    > div {
      height: 120px;
    }
    button {
      min-width: auto;
      width: 100%;
    }
    p {
      margin-left: 1.5rem;
    }
  }
  .instituteDataLabelCategory {
    grid-row: 12;
    grid-column: 8 / 13;
  }
  .instituteDataCategory {
    grid-row: 13;
    grid-column: 8 / 13;
    margin-bottom: 1.5rem;
  }
  .editInstituteDataCategory {
    grid-row: 13;
    grid-column: 7 / 13;
    > div {
      height: 120px;
    }
    button {
      min-width: auto;
      width: 100%;
    }
    p {
      margin-left: 1.5rem;
    }
  }
  .instituteDataLabelPlan {
    grid-row: 14;
    grid-column: 1 / 7;
  }
  .instituteDataPlan {
    grid-row: 15;
    grid-column: 1 / 7;
    margin-bottom: 1.5rem;
  }
  .editInstituteDataPlan {
    grid-row: 15;
    grid-column: 1 / 7;
    > div {
      height: 120px;
    }
    button {
      min-width: auto;
      width: 100%;
    }
    p {
      margin-left: 1.5rem;
    }
  }
`;

export const WrapperFooterActions = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  margin-top: 2.5rem;
  justify-content: flex-end;
  button {
    min-width: auto;
  }
  /* @media ${device.laptop} {
    padding: 0 1.5rem;
    flex-direction: column;
  } */
`;
