import * as D from '@tempo/tempo-design-system-core/dist';

import propTypes from 'prop-types';

import * as S from './styles';

export function EmptyTableError({ height, customTitle, customText }) {
  return (
    <S.ContainerError height={height}>
      <img
        src={`${process.env.REACT_APP_S3_IMAGES_STORAGE}monitoringTable.png`}
        alt="Não há nada por aqui..."
      />
      <div>
        <D.TmpTypography component="heading" size="small">
          {customTitle}
        </D.TmpTypography>
        <D.TmpTypography component="paragraph" size="small">
          {customText}
        </D.TmpTypography>
      </div>
    </S.ContainerError>
  );
}
EmptyTableError.defaultProps = {
  height: '201px',
  customText: 'Até o momento, não temos atendimentos nessa seção.',
  customTitle: 'Não há nada por aqui...',
};
EmptyTableError.propTypes = {
  height: propTypes.string,
  customText: propTypes.string,
  customTitle: propTypes.string,
};
