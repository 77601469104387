import React from 'react';

import * as D from '@tempo/tempo-design-system-core/dist';

import propTypes from 'prop-types';

import * as S from './styles';

export function Card({
  title,
  hideShowAll,
  showAllLink,
  showAllAction,
  children,
  contentMarginTop,
  noShadow,
  minHeight,
  showAllCustomText,
}) {
  return (
    <S.Wrapper noShadow={noShadow} minHeight={minHeight}>
      <D.TmpShape>
        <S.Title>
          <D.TmpTypography component="heading" size="small">
            {title}
          </D.TmpTypography>
          {!hideShowAll && (
            <D.TmpLink icon href={showAllLink} onClick={showAllAction}>
              {showAllCustomText}
            </D.TmpLink>
          )}
        </S.Title>
        <S.Content contentMarginTop={contentMarginTop}>{children}</S.Content>
      </D.TmpShape>
    </S.Wrapper>
  );
}

Card.defaultProps = {
  title: '',
  hideShowAll: false,
  showAllAction: () => {},
  showAllLink: null,
  contentMarginTop: '1rem',
  noShadow: false,
  minHeight: 'auto',
  showAllCustomText: 'Ver todos',
};

Card.propTypes = {
  title: propTypes.string,
  hideShowAll: propTypes.bool,
  showAllAction: propTypes.func,
  showAllLink: propTypes.string,
  children: propTypes.node.isRequired,
  contentMarginTop: propTypes.string,
  noShadow: propTypes.bool,
  minHeight: propTypes.string,
  showAllCustomText: propTypes.string,
};
