import React from 'react';

import * as D from '@tempo/tempo-design-system-core/dist';

import { Components } from '@b2b/core';

import * as S from './styles';

export function NoMobile() {
  return (
    <Components.Layout>
      <S.Wrapper>
        <S.Container>
          <S.ImageContainer>
            <S.ImageView
              src={`${process.env.REACT_APP_S3_IMAGES_STORAGE}monitoring.png`}
            />
          </S.ImageContainer>
          <S.TypographyContainer>
            <D.TmpTypography component="heading" size="large">
              Ooops! Detectamos que você está pelo celular...
            </D.TmpTypography>
            <D.TmpTypography component="caption">
              Nosso atendimento só pode ser utilizado através de um computador
              ou tablet. :)
            </D.TmpTypography>
          </S.TypographyContainer>
        </S.Container>
      </S.Wrapper>
    </Components.Layout>
  );
}
