import * as I from '@tempo/tempo-assets/dist/svgs/icons';
import * as D from '@tempo/tempo-design-system-core/dist';

import { Components } from '@b2b/core';
import propTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { ReactSVG } from 'react-svg';

import * as S from './styles';

export function InfoSystem({ image, title, content, enabledButton }) {
  const navigate = useNavigate();
  return (
    <Components.Layout>
      <S.Wrapper>
        <S.Container>
          <S.ImageContainer>
            <S.ImageView src={image} />
          </S.ImageContainer>
          <S.TypographyContainer>
            <D.TmpTypography component="heading" size="large">
              {title}
            </D.TmpTypography>
            <D.TmpTypography component="caption">{content}</D.TmpTypography>
          </S.TypographyContainer>
          {enabledButton && (
            <div style={{ maxWidth: '390px' }}>
              <D.TmpButton
                startIcon={<ReactSVG src={I.seta_direita} />}
                handleClick={() => navigate('/login')}
              >
                Fazer login
              </D.TmpButton>
            </div>
          )}
        </S.Container>
      </S.Wrapper>
    </Components.Layout>
  );
}

InfoSystem.defaultProps = {
  image: `${process.env.REACT_APP_S3_IMAGES_STORAGE}sessionExpired.png`,
  title: 'Seu tempo de sessão expirou',
  content:
    'Infelizmente seu tempo de sessão expirou. Para continuar o atendimento, terá que iniciar um novo.',
  enabledButton: true,
};

InfoSystem.propTypes = {
  image: propTypes.node,
  title: propTypes.string,
  content: propTypes.string,
  enabledButton: propTypes.bool,
};
