import { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import * as S from './styles';

export function LayoutSeparate({ children, actions, paddingBottomIOS }) {
  const [platform, setPlatform] = useState(false);
  function detectDevice() {
    if (navigator.userAgent.match(/Windows/i)) {
      setPlatform(false);
    }
    if (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i)
    ) {
      setPlatform(true);
    }
  }

  useEffect(() => {
    detectDevice();
  }, [platform]);

  return (
    <S.Wrapper platform={platform}>
      <S.Container>
        <S.Content>{children}</S.Content>
      </S.Container>
      <S.Actions paddingBottomIOS={paddingBottomIOS}>
        <S.ContentActions>{actions}</S.ContentActions>
      </S.Actions>
    </S.Wrapper>
  );
}

LayoutSeparate.defaultProps = {
  children: null,
  actions: null,
  paddingBottomIOS: '32px',
};

LayoutSeparate.propTypes = {
  children: PropTypes.node,
  actions: PropTypes.node,
  paddingBottomIOS: PropTypes.string,
};
