import { info as Info } from '@tempo/tempo-assets/dist';
import * as D from '@tempo/tempo-design-system-core/dist';

import propTypes from 'prop-types';

import * as S from './styles';

export function WarningAlert({ title, content }) {
  return (
    <S.Wrapper>
      <S.TitleContainer>
        <D.TmpIcon size="sm" icon={<Info />} />
        <D.TmpTypography component="heading" size="small">
          {title}
        </D.TmpTypography>
      </S.TitleContainer>
      <S.Content>
        <D.TmpTypography component="paragraph" size="small">
          {content}
        </D.TmpTypography>
      </S.Content>
    </S.Wrapper>
  );
}

WarningAlert.defaultProps = {
  title: 'Atenção',
};

WarningAlert.propTypes = {
  title: propTypes.string,
  content: propTypes.string.isRequired,
};
