import * as I from '@tempo/tempo-assets/dist/svgs/icons';
import * as D from '@tempo/tempo-design-system-core/dist';

import propTypes from 'prop-types';
import { ReactSVG } from 'react-svg';

import * as S from './styles';

export function CardCount({ title, count, icon, color, borderColorIcon }) {
  return (
    <S.Wrapper>
      <S.ContainerIcon color={color} borderColorIcon={borderColorIcon}>
        {icon}
      </S.ContainerIcon>
      <S.ContainerInformation color={color}>
        <D.TmpTypography component="heading" size="medium">
          {count}
        </D.TmpTypography>
        <D.TmpTypography component="caption">{title}</D.TmpTypography>
      </S.ContainerInformation>
    </S.Wrapper>
  );
}

CardCount.defaultProps = {
  color: '#3AA141',
  borderColorIcon: '#D2EFD4',
  icon: <ReactSVG src={I.dependentes} />,
};

CardCount.propTypes = {
  title: propTypes.string.isRequired,
  count: propTypes.number.isRequired,
  icon: propTypes.element,
  color: propTypes.string,
  borderColorIcon: propTypes.string,
};
