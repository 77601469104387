/* eslint-disable react/prop-types */
import * as DB2B from '@tempo/tempo-design-system-b2b/dist';
import * as D from '@tempo/tempo-design-system-core/dist';

import { Components, Hooks, Utils } from '@b2b/core';

import * as S from './styles';

function Items({ columns, currentItems, actionMenu, formatCNPJ = false }) {
  const formattedRows = currentItems.map(item => {
    if (formatCNPJ) {
      return {
        ...item,
        accessValue: Utils.formatCNPJ(item.accessValue),
      };
    }
    return item;
  });
  return (
    currentItems && (
      <S.ContainerTable>
        {actionMenu ? (
          <DB2B.TmpTableBase columns={columns} rows={formattedRows} />
        ) : (
          <D.TmpTableBase columns={columns} rows={formattedRows} />
        )}
      </S.ContainerTable>
    )
  );
}

export function PaginatedItems({
  error,
  handleReload,
  loading,
  currentItems,
  currentPage,
  totalCount,
  handleChangePage,
  isEmpty,
  itensPerPage,
  columns,
  actionMenu,
  customTextEmpty,
  formatCNPJ,
}) {
  // eslint-disable-next-line no-nested-ternary
  return error ? (
    isEmpty ? (
      <Components.EmptyTableError height="392px" customText={customTextEmpty} />
    ) : (
      <Components.ReloadError
        reload={() => handleReload()}
        loading={loading}
        height="392px"
        customText={
          isEmpty
            ? customTextEmpty ?? 'Nenhum atendimento encontrado.'
            : undefined
        }
      />
    )
  ) : (
    <>
      {currentItems && (
        <Items
          currentItems={currentItems}
          columns={columns}
          actionMenu={actionMenu}
          formatCNPJ={formatCNPJ}
        />
      )}

      {!loading && (
        <Hooks.Pagination
          className="pagination-bar"
          currentPage={currentPage}
          totalCount={totalCount}
          pageSize={itensPerPage}
          onPageChange={page => handleChangePage(page)}
        />
      )}
    </>
  );
}
