import { useEffect, useState } from 'react';

import * as I from '@tempo/tempo-assets/dist/svgs/icons';
import * as D from '@tempo/tempo-design-system-core/dist';

import { Analytics, Components, Hooks, Utils } from '@b2b/core';
import {
  getAttendances,
  sendReportAttendance,
} from '@b2b/institute/src/services/attendances.service';
import dayjs from 'dayjs';
import propTypes from 'prop-types';
import { ReactSVG } from 'react-svg';

import { ModalSuccessFeedback } from './ModalSuccessFeedback';
import * as S from './styles';

const pageSize = 5;
const numberOfLines = 20;
const addForFilteredPages = numberOfLines / pageSize;
const filteredPages = [];
let type = null;

export function AllAttendances({ isBackOffice }) {
  const [error, setError] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const { loadingButton, setLoadingButton } = Hooks.useLoading();
  const [currentPage, setCurrentPage] = useState(1);
  const [attendances, setAttendances] = useState([]);
  const { addToast } = Hooks.useToast();
  const { getUser } = Hooks.useUser();
  const { getInstitute, loadingBackoffice, errorBackoffice, handleInstitute } =
    Hooks.useBackOffice();
  const { code, cnpj } = isBackOffice ? getInstitute() : getUser();

  const [showModalSuccessFeedback, setShowModalSuccessFeedback] =
    useState(false);

  const currentItems = attendances?.find(
    item => item.page === currentPage
  )?.items;

  function loadAttendances(initialSearchLine, selectedPage) {
    const newSelectedPage = selectedPage === 0 ? 1 : selectedPage;
    const status = type || null;
    setError(false);
    setLoading(true);
    getAttendances(code, status, initialSearchLine, numberOfLines)
      .then(response => {
        setIsEmpty(false);
        const formattedResponse = response.attendances.map(
          unformattedAttendances => {
            return {
              ...unformattedAttendances,
              healthProfessional:
                unformattedAttendances.healthProfessional ?? '-',
              specialty: unformattedAttendances.specialty ?? '-',
              updatedOn: dayjs(unformattedAttendances.updatedOn).format(
                'DD/MM/YYYY'
              ),
            };
          }
        );
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < addForFilteredPages; i++) {
          const filteredPage = newSelectedPage + i;

          const initialOffset = pageSize * i;

          const endOffset = initialOffset + pageSize;

          setAttendances(previousAttendances => [
            ...previousAttendances,
            {
              page: filteredPage,
              items: formattedResponse.slice(initialOffset, endOffset),
            },
          ]);

          if (!filteredPages.includes(filteredPage)) {
            filteredPages.push(filteredPage);
          }
        }
        setTotalCount(response.totalItems);
      })
      .catch(({ response }) => {
        const parseError = JSON.parse(response?.data?.errorMessage);
        setError(true);
        if (parseError?.errorCode === '#0037' && initialSearchLine === 0) {
          setIsEmpty(true);
        } else {
          setIsEmpty(false);
        }
      })
      .finally(() => setLoading(false));
  }
  useEffect(() => {
    if (code) {
      loadAttendances(0, 1);
    }
  }, [code]);

  function handleReload(isErrorBackoffice) {
    if (isErrorBackoffice) {
      handleInstitute();
    } else {
      loadAttendances(0, 1);
    }
  }

  function handleChangePage(page) {
    const block = Math.ceil(page / (numberOfLines / pageSize));
    const offset = (block - 1) * numberOfLines;
    const pageReq = Math.ceil(offset / pageSize) + 1;
    setCurrentPage(page);
    if (!filteredPages.includes(page)) {
      loadAttendances(offset ?? 0, pageReq);
    }
  }

  function sendEmailReportAttendance() {
    const dateStart = '01/01/2022';
    const instituteCode = code;
    const { name, email } = getUser();

    setLoadingButton(true);
    sendReportAttendance(cnpj, instituteCode, dateStart, email, name)
      .then(() => {
        Analytics.logEvent(
          'Relatório de atendimentos enviado',
          {},
          isBackOffice ? 'backoffice' : 'institute'
        );
        setShowModalSuccessFeedback(true);
      })
      .catch(({ response }) => {
        Analytics.logEvent(
          'Erro ao enviar relatório de atendimento',
          {},
          isBackOffice ? 'backoffice' : 'institute'
        );
        addToast({
          type: 'error',
          title: Utils.getError('title', response),
          message: Utils.getError('message', response) || 'Erro desconhecido',
        });
      })
      .finally(() => {
        setLoadingButton(false);
      });
  }

  function handleCloseModal() {
    setShowModalSuccessFeedback(false);
  }
  return (
    <S.Wrapper>
      <S.Container>
        <Components.Breadcrumbs />
        <S.TitleContainer
          isEmpty={isEmpty || loading || loadingBackoffice}
          loadingReport={loadingButton}
        >
          <D.TmpTypography component="heading" size="large">
            Atendimentos
          </D.TmpTypography>
          <D.TmpButton
            startIcon={<ReactSVG src={I.email} />}
            size="lg"
            type="secondary"
            disabled={isEmpty || loading || loadingBackoffice || loadingButton}
            loading={loadingButton}
            handleClick={() => sendEmailReportAttendance()}
          >
            Enviar relatório de atendimento
          </D.TmpButton>
          {/* <D.TmpInputSearch placeholder="Buscar por paciente, solicitante ou profissional" /> */}
        </S.TitleContainer>
        <D.TmpShape>
          <D.TmpTabGroup
            activedTab={0}
            ariaLabel="tab-group"
            handleActivedTab={({ currentTab }) => {
              filteredPages.splice(0, filteredPages.length);
              setAttendances([]);
              switch (currentTab) {
                case 1:
                  type = 1;
                  handleChangePage(1);
                  break;
                case 2:
                  type = 2;
                  handleChangePage(1);
                  break;
                case 3:
                  type = 3;
                  handleChangePage(1);
                  break;
                default:
                  type = null;
                  handleChangePage(1);
              }
            }}
          >
            <D.TmpTabItem label="Todos" />
            <D.TmpTabItem label="Concluídos" />
            <D.TmpTabItem label="Aguardando atendimento" />
            <D.TmpTabItem label="Cancelados" />
          </D.TmpTabGroup>
          {loading || loadingBackoffice ? (
            <Components.Loading height="470px" />
          ) : (
            <Components.PaginatedItems
              error={error || (isBackOffice && errorBackoffice)}
              handleReload={() => handleReload(errorBackoffice)}
              loading={loading}
              currentItems={currentItems}
              currentPage={currentPage}
              totalCount={totalCount}
              handleChangePage={page => handleChangePage(page)}
              isEmpty={isEmpty}
              itensPerPage={pageSize}
              columns={
                type !== null
                  ? [
                      {
                        field: 'patientName',
                        label: 'Paciente',
                      },
                      {
                        field: 'agentName',
                        label: 'Solicitante',
                      },
                      // {
                      //   field: 'healthProfessional',
                      //   label: 'Profissional',
                      // },
                      // {
                      //   field: 'specialty',
                      //   label: 'Especialidade',
                      // },
                      {
                        field: 'updatedOn',
                        label: 'Data',
                      },
                    ]
                  : [
                      {
                        field: 'patientName',
                        label: 'Paciente',
                      },
                      {
                        field: 'statusAttendance',
                        label: 'Status',
                      },
                      {
                        field: 'agentName',
                        label: 'Solicitante',
                      },
                      // {
                      //   field: 'healthProfessional',
                      //   label: 'Profissional',
                      // },
                      // {
                      //   field: 'specialty',
                      //   label: 'Especialidade',
                      // },
                      {
                        field: 'updatedOn',
                        label: 'Data',
                      },
                    ]
              }
            />
          )}
        </D.TmpShape>
      </S.Container>
      <ModalSuccessFeedback
        isOpened={showModalSuccessFeedback}
        handleClose={() => handleCloseModal()}
      />
    </S.Wrapper>
  );
}

AllAttendances.defaultProps = {
  isBackOffice: false,
};
AllAttendances.propTypes = {
  isBackOffice: propTypes.bool,
};
