import { useEffect } from 'react';

import Erro from '@tempo/tempo-assets/dist/react/icons/erro';
import * as D from '@tempo/tempo-design-system-core/dist';

import { Analytics, Components, Hooks, Utils } from '@b2b/core';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { getCode } from 'services/login.service';
import * as Yup from 'yup';

import * as S from './styles';

export function Login() {
  const navigate = useNavigate();
  const { addToast } = Hooks.useToast();
  const { loadingButton, setLoadingButton } = Hooks.useLoading();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      doc: '',
    },
    validationSchema: Yup.object().shape({
      doc: Yup.string()
        .transform(value => value.replace(/[^\d]/g, ''))
        .required('Campo obrigatório')
        .test('is-cnpj', 'CNPJ inválido', value => {
          if (value !== undefined) {
            Utils.validateCNPJ(Utils.removeMask('cnpj', value.slice(0, 14)));
            return true;
          }
          return false;
        })
        .nullable(),
    }),
    onSubmit: values => {
      setLoadingButton(true);
      getCode(Utils.removeMask('cnpj', values.doc))
        .then(response => {
          navigate('/codigo-de-acesso', {
            state: {
              responseCode: response.code,
              email: response.email,
              phone: response.phoneNumber,
              doc: Utils.removeMask('cnpj', values.doc),
            },
          });
        })
        .catch(({ response }) => {
          Analytics.logEvent('Falha no login', {}, 'institute');
          addToast({
            type: 'error',
            title: Utils.getError('title', response),
            message: Utils.getError('message', response) || 'Erro desconhecido',
          });
        })
        .finally(() => setLoadingButton(false));
    },
  });

  Hooks.ClearCache();

  useEffect(() => {
    Utils.removeCharSpecial('#doc');
  }, [formik.values.doc]);

  return (
    <Components.Layout>
      <S.Wrapper>
        <img
          src={`${process.env.REACT_APP_S3_IMAGES_STORAGE}presentialUnity.png`}
          alt="Instituição presencial"
        />
        <S.InformationWrapper>
          <D.TmpTypography component="heading" size="large">
            Acesso Restrito
          </D.TmpTypography>
          <D.TmpTypography component="paragraph" size="small">
            Preencha o campo abaixo para acessar os atendimentos prestados pela
            sua organização.
          </D.TmpTypography>
          <form onSubmit={formik.handleSubmit}>
            <D.TmpInput
              id="doc"
              name="doc"
              label="CNPJ"
              placeholder="Digite o Nº do CNPJ da empresa"
              value={formik.values.doc}
              mask="cnpj"
              helperIcon={formik.touched.doc && formik.errors.doc && <Erro />}
              helperText={formik.touched.doc && formik.errors.doc}
              onBlur={formik.handleBlur}
              handleChange={formik.handleChange}
              error={
                formik.touched.doc && formik.errors.doc && formik.errors.doc
              }
            />
            <S.InformationActions>
              <D.TmpButton
                size="lg"
                type="primary"
                typeButton="submit"
                loading={loadingButton}
              >
                Entrar
              </D.TmpButton>
            </S.InformationActions>
          </form>
        </S.InformationWrapper>
      </S.Wrapper>
    </Components.Layout>
  );
}
