import * as D from '@tempo/tempo-design-system-core/dist';

import { Components } from '@b2b/core';
import { useLocation, useNavigate } from 'react-router-dom';

import * as S from './styles';

export function RegistrationSuccessful() {
  const navigate = useNavigate();
  const params = useLocation();
  const { route, data } = params.state;
  function actionsContainer() {
    return (
      <S.WrapperButton>
        <D.TmpButton
          size="lg"
          type="primary"
          typeButton="submit"
          handleClick={() =>
            route
              ? navigate(route, {
                  state: data ?? '',
                })
              : null
          }
        >
          Ok, entendi!
        </D.TmpButton>
      </S.WrapperButton>
    );
  }

  return (
    <Components.LayoutSeparate actions={actionsContainer()}>
      <Components.Layout>
        <S.Wrapper>
          <S.WrapperImage>
            <S.ImageView
              src={`${process.env.REACT_APP_S3_IMAGES_STORAGE}successCompletingStep.png`}
            />
          </S.WrapperImage>
          <S.WrapperContent>
            <S.WrapperTypography>
              <D.TmpTypography component="heading" size="large">
                Cadastro realizado com sucesso!
              </D.TmpTypography>
            </S.WrapperTypography>
          </S.WrapperContent>
        </S.Wrapper>
      </Components.Layout>
    </Components.LayoutSeparate>
  );
}
