import { Utils } from '@b2b/core';

export default function verifyTypeDocument(type, patient, values) {
  const typeDocument = patient
    ? values.typeDocument.value
    : values.typeDocumentGuardian.value;
  const document = patient ? values.doc : values.docGuardian;
  if (typeDocument === type) {
    return Utils.removeMask(type, document);
  }
  return null;
}
