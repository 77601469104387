import { device } from '@b2b/core/src/utils';
import styled from 'styled-components';

export const WrapperHeader = styled.div`
  > div {
    padding: 0 1.5rem 0 2.06rem;
    @media ${device.onlyTablet} {
      div:nth-child(2) {
        display: flex;
        justify-content: flex-end;
      }
    }
    .tmp-branding {
      cursor: pointer;
      height: 40px;
      width: auto;
      margin-right: 3.56rem;
      svg {
        width: auto;
      }
    }
    > div {
      @media ${device.mobile} {
        button {
          .tmp-avatar {
            padding-right: 0px;
          }
          > .tmp-icon {
            padding-left: 18px;
          }
        }
      }
    }
  }
`;
export const ContainerHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  .trailing-icon {
    display: none;
  }
  @media ${device.laptop} {
    .trailing-icon {
      display: block;
    }
  }
  @media ${device.mobile768} {
    justify-content: flex-end;
  }
`;

export const Title = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  .tmp-typography__heading--small {
    color: #666666;
    font-size: 16px;
  }
  padding-right: ${props => !props.isLogged && '13.5rem'};
  padding-left: ${props => props.isLogged && '2.5rem'};
  @media ${device.laptop} {
    display: none;
  }
`;

export const Dropdown = styled.div`
  ul {
    z-index: 999;
    left: -29px;
    @media ${device.mobile768} {
      left: 0 !important;
      width: 100%;
      height: 100%;
    }
    @media ${device.laptop} {
      left: -49px;
    }
  }
  @media ${device.laptop} {
    padding: 0;
  }
`;
