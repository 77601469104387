import { device } from '@b2b/core/src/utils';
import styled from 'styled-components';

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(44, 110, 242, 0.02);
  width: 100%;
  min-height: calc(100vh - 89px);
  @media ${device.laptop} {
    flex-direction: column;
    gap: 0;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1366px;
  width: 100vw;
  padding: 40px 88px;
  box-sizing: border-box;
  @media ${device.laptop} {
    padding: 20px 20px;
    align-items: center;
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const ImageView = styled.img``;

export const TypographyContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 16px 8px 0;
  .tmp-typography__heading--large {
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 133%;
    color: #292929;
  }
  .tmp-typography__caption {
    font-weight: 400;
    font-size: 14px;
    color: #666666;
    margin-top: 8px;
  }
`;
