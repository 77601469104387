import { device } from '@b2b/core/src/utils';
import styled from 'styled-components';

function getFillPath(loadingReport, isEmpty, hover) {
  if (isEmpty) {
    return 'rgba(0,0,0,0.08)';
  }
  if (loadingReport) {
    return 'transparent';
  }
  if (!loadingReport && hover) {
    return '#0a3a99';
  }
  return '#2c6ef2';
}

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: rgba(44, 110, 242, 0.02);
  min-height: calc(100vh - 89px);
  overflow-y: hidden;
  @media ${device.laptop} {
    flex-direction: column;
    gap: 0;
    padding: 2rem 1.5rem;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1366px;
  width: 100vw;
  padding: 40px 88px;
  box-sizing: border-box;
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px 0px 24px 0px;
  .tmp-typography__heading--large {
    font-weight: 700;
    font-size: 24px !important;
    color: #231f20;
  }
  > div > div {
    min-width: 440px;
    .tmp-button-icon {
      display: none;
    }
  }
  .tmp-button {
    path {
      fill: ${({ loadingReport, isEmpty }) =>
        getFillPath(loadingReport, isEmpty, false)};
    }
    &:hover {
      path {
        fill: ${({ loadingReport, isEmpty }) =>
          getFillPath(loadingReport, isEmpty, true)};
      }
    }
  }
`;
