import { device } from '@b2b/core/src/utils';
import styled from 'styled-components';

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: rgba(44, 110, 242, 0.02);
  min-height: calc(100vh - 89px);
  @media ${device.laptop} {
    flex-direction: column;
    gap: 0;
    padding: 2rem 1.5rem;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1366px;
  width: 100%;
  padding: 40px 88px;
  box-sizing: border-box;
  .loadingShapeAgents {
    margin-top: 10%;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px 0px 24px 0px;
  .tmp-typography__heading--large {
    font-weight: 700;
    font-size: 24px;
    color: #231f20;
  }
  > div > div {
    min-width: 440px;
    .tmp-button-icon {
      display: none;
    }
  }
`;

export const TitleActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  .tmp-button {
    width: 217px;
    height: 48px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 100%;
    .icon {
      padding-top: 2px;
    }
    path {
      fill: #ffffff;
      transition: all ease 500ms;
    }
    &:hover {
      path {
        fill: #0a3a99;
      }
    }
  }
`;
