export const device = {
  mobile: '(max-width: 648px)',
  mobile768: '(max-width: 768px)',
  mobile500: '(max-width: 500px)',
  mobile425: '(max-width: 425px)',
  mobile395: '(max-width: 395px)',
  mobile375: '(max-width: 375px)',
  mobile360: '(max-width: 360px)',
  mobile320: '(max-width: 320px)',
  mobile280: '(max-width: 280px)',
  tablet: '(max-width: 768px)',
  laptop: '(max-width: 1024px)',
  desktop: '(max-width: 1440px)',
  desktopLarge: '(min-width: 1366px)',
  desktop4K: '(min-width: 2560px)',
  onlyTablet: '(min-width: 648px) and (max-width: 1024px)',
  noMobileOnly: '(min-width: 648px)',
  noLaptop: '(min-width: 1024px)',
  desktop1320: '(max-width: 1320px)',
  onlyS8: '(max-height: 740px) and (max-width: 360px)',
};
