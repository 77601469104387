import React, { useEffect, useState } from 'react';

import * as D from '@tempo/tempo-design-system-core/dist';

import propTypes from 'prop-types';

import { WrapperResend } from './style';

export function ResendTimer({
  initialMinute,
  initialSeconds,
  paddingBottom,
  paddingTop,
  onResend,
}) {
  const [minutes, setMinutes] = useState(initialMinute);
  const [seconds, setSeconds] = useState(initialSeconds);
  const fullTime = `${minutes < 10 ? `0${minutes}` : minutes}:${
    seconds < 10 ? `0${seconds}` : seconds
  }`;

  useEffect(() => {
    const myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });

  function handleResend() {
    setMinutes(initialMinute);
    setSeconds(initialSeconds);
    onResend?.();
  }

  return (
    <WrapperResend paddingBottom={paddingBottom} paddingTop={paddingTop}>
      {minutes === 0 && seconds === 0 ? (
        // eslint-disable-next-line no-alert
        <D.TmpLink onClick={() => handleResend()}>Reenviar código</D.TmpLink>
      ) : (
        <D.TmpTypography component="paragraph" size="small">
          Reenviar código em: {fullTime}.
        </D.TmpTypography>
      )}
    </WrapperResend>
  );
}

ResendTimer.defaultProps = {
  initialMinute: 0,
  initialSeconds: 0,
  paddingBottom: '2rem',
  paddingTop: '0.5rem',
};
ResendTimer.propTypes = {
  initialMinute: propTypes.number,
  initialSeconds: propTypes.number,
  paddingBottom: propTypes.string,
  paddingTop: propTypes.string,
  onResend: propTypes.func.isRequired,
};
