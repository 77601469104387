import { device } from '@b2b/core/src/utils';
import styled from 'styled-components';

export const HeaderShape = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  h1 {
    font-size: 16px;
    color: #666666;
    max-width: 0px !important;
    min-width: 0px !important;
  p {
    font-size: 16px;
    color: #666666;
  }
`;

export const CardShape = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f5f5f5;
  padding: 1.5rem;
  margin: ${props => props.margin ?? '6rem 0 1.5rem 0'};
  border-radius: 8px;
  gap: 0.5rem;
  border: 1px solid #e0e0e0;
  @media ${device.laptop} {
    margin: ${props => props.marginMobile ?? '1rem 0 2rem 0'};
  }
`;
