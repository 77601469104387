import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { Components } from '@b2b/core';
import propTypes from 'prop-types';

const ToastContext = createContext();

export default ToastContext;

export function ToastProvider({ children }) {
  const [toast, setToast] = useState({
    isOpened: false,
  });
  const SECONDS_FOR_DISABLE_TOAST = 10;

  function removeToast() {
    setToast(oldToast => ({ ...oldToast, isOpened: false }));
  }

  const addToast = useCallback(
    item => {
      setToast({ isOpened: true, ...item });
    },
    [setToast]
  );

  const handleLinkAction = () => {
    removeToast();
    toast.linkAction();
  };
  useEffect(() => {
    if (toast.isOpened === true && !toast.keepOpen) {
      const timer = setTimeout(() => {
        removeToast();
      }, SECONDS_FOR_DISABLE_TOAST * 1000);
      return () => clearTimeout(timer);
    }
    return null;
  }, [toast]);

  const valuesToastProvider = useMemo(
    () => ({
      addToast,
      removeToast,
    }),
    [addToast, removeToast]
  );

  return (
    <ToastContext.Provider value={valuesToastProvider}>
      {children}
      <Components.Toast
        isOpened={toast.isOpened}
        type={toast.type}
        title={toast.title}
        message={toast.message}
        handleClose={toast.handleClose}
        linkText={toast.linkText}
        linkHref={toast.linkHref}
        linkAction={handleLinkAction}
      />
    </ToastContext.Provider>
  );
}

ToastProvider.propTypes = {
  children: propTypes.node.isRequired,
};
