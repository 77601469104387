import React, { createContext, useMemo, useState } from 'react';

import { Components } from '@b2b/core';
import propTypes from 'prop-types';

const LoadingContext = createContext();

export default LoadingContext;

export function LoadingProvider({ children }) {
  const [loading, setLoading] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [loadingButtonPrimary, setLoadingButtonPrimary] = useState(false);
  const [loadingButtonSecondary, setLoadingButtonSecondary] = useState(false);

  function handleLoading() {
    setLoading(!loading);
  }

  const valuesLoadingProvider = useMemo(
    () => ({
      handleLoading,
      setLoadingButton,
      setLoadingButtonPrimary,
      setLoadingButtonSecondary,
      loadingButton,
      loadingButtonPrimary,
      loadingButtonSecondary,
    }),
    [
      handleLoading,
      setLoadingButton,
      setLoadingButtonPrimary,
      setLoadingButtonSecondary,
      loadingButton,
      loadingButtonPrimary,
      loadingButtonSecondary,
    ]
  );

  return (
    <LoadingContext.Provider value={valuesLoadingProvider}>
      {children}
      {loading && <Components.Loading />}
    </LoadingContext.Provider>
  );
}

LoadingProvider.propTypes = {
  children: propTypes.node.isRequired,
};
