import React, { useEffect, useState } from 'react';

import { Pages } from '@b2b/core';
import { connectionDropped } from '@b2b/core/src/assets/base64/connectionDropped';
import propTypes from 'prop-types';

const message = {
  image: connectionDropped,
  title: 'Sua conexão caiu',
  content: 'Aguarde o restabelecimento ou tente atualizar sua página.',
};

function NoInternetConnection({ children }) {
  // variável de estado mantém o estado da conexão com a internet
  const [isOnline, setOnline] = useState(true);

  // Na inicialização, defina o estado isOnline.
  useEffect(() => {
    setOnline(navigator.onLine);
  }, []);

  // ouvintes de eventos para atualizar o estado
  window.addEventListener('online', () => {
    setOnline(true);
  });

  window.addEventListener('offline', () => {
    setOnline(false);
  });

  // se o usuário estiver online, retorne o componente filho, caso contrário, retorne um componente personalizado
  if (isOnline) {
    return children;
  }
  return (
    <Pages.InfoSystem
      image={`data:image/jpeg;base64,${message.image}`}
      title={message.title}
      content={message.content}
      enabledButton={false}
    />
  );
}

export default NoInternetConnection;

NoInternetConnection.propTypes = {
  children: propTypes.node.isRequired,
};
