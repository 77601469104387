import { useState } from 'react';

import Erro from '@tempo/tempo-assets/dist/react/icons/erro';
import * as I from '@tempo/tempo-assets/dist/svgs/icons';
import * as DB2B from '@tempo/tempo-design-system-b2b/dist';
import * as D from '@tempo/tempo-design-system-core/dist';

import { Components, Hooks, Services, Utils } from '@b2b/core';
import { useFormik } from 'formik';
import propTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { ReactSVG } from 'react-svg';

import * as S from './styles';

export function FormInstitute({
  isEdit = false,
  newAgent = false,
  route,
  field,
  config,
}) {
  const [editType, setEditType] = useState(isEdit);
  // const [openedDrawer, setOpenedDrawer] = useState(false);
  // const [loading, setLoading] = useState(false);
  const [loadingCEP, setLoadingCEP] = useState(false);
  const [disableFields, setDisableFields] = useState(false);

  const navigate = useNavigate();

  const { addToast } = Hooks.useToast();
  const { states, fullyStateByInitials } = Hooks.useStateList();

  // const { getInstitute } = Hooks.useBackOffice();
  // const { code, name, cnpj, status } = getInstitute();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: config()?.SchemaInitialValues,
    validationSchema: config()?.schemas,
    onSubmit: config()?.onSubmit,
  });

  function callToError(response) {
    addToast({
      type: 'error',
      title: Utils.getError('title', response),
      message: Utils.getError('message', response) || 'Erro desconhecido',
    });
  }

  function handleGetCEP() {
    setLoadingCEP(true);
    Services.getCEP(Utils.removeMask('cep', formik.values?.cep))
      .then(response => {
        const fullyState = fullyStateByInitials(response?.state);
        formik.setFieldValue('state.label', fullyState?.description || '');
        formik.setFieldValue('state.value', fullyState?.initials || '');
        formik.setFieldValue('city', response?.city || '');
        formik.setFieldValue('neighborhood', response?.neighborhood || '');
        formik.setFieldValue('address', response?.address || '');
        setDisableFields(true);
      })
      .catch(({ response }) => {
        setDisableFields(false);
        const { statusCode, errorCode } =
          JSON.parse(response?.data?.errorMessage) || false;
        if (statusCode === 500) {
          callToError(response);
          if (errorCode !== '#0040') {
            callToError(response);
          }
        }
      })
      .finally(() => setLoadingCEP(false));
  }

  return (
    <S.Wrapper>
      <S.Container>
        {field === 'backoffice' && <Components.Breadcrumbs />}
        <S.WrapperTypography editType={editType} newAgent={newAgent}>
          <div className="containerEditor">
            <D.TmpTypography compoment="heading" size="medium">
              {editType
                ? config()?.infoInstitute[field].titleIsEdit
                : config()?.infoInstitute[field].title}
            </D.TmpTypography>
            {!newAgent && (
              <D.TmpButtonIcon
                size="lg"
                typeButton="button"
                handleClick={() => {
                  setEditType(true);
                }}
                disabled={editType}
                icon={<ReactSVG src={I.editar} />}
              />
            )}
          </div>
        </S.WrapperTypography>
        <form onSubmit={formik.handleSubmit}>
          <S.WrapperCardShape>
            <div className="cardShape">
              <D.TmpShape>
                <S.WrapperInstituteData loadingCEP={loadingCEP}>
                  <div className="instituteDataTitle">
                    <D.TmpTypography>DADOS GERAIS</D.TmpTypography>
                  </div>
                  {newAgent &&
                    config()?.infoInstitute[field].inputs.map(item => {
                      if (editType || newAgent) {
                        return item.type === 'paragraph' &&
                          item.typeInput !== 'select' ? (
                          <div
                            className={
                              editType || newAgent ? item.editClass : item.class
                            }
                          >
                            {item.type === 'paragraph' &&
                              (item.mask === 'ddiphone' ? (
                                <DB2B.TmpPhoneInput
                                  id={item.name}
                                  name={item.name}
                                  label={item.label}
                                  placeholder={item.placeholder}
                                  value={formik.values[item.name]}
                                  getValue={value => {
                                    if (value !== formik.values[item.name]) {
                                      formik.setFieldValue(item.name, value);
                                    }
                                  }}
                                  defaultValue={`${
                                    formik.values[item.name].ddi
                                  }${formik.values[item.name].ddd}${
                                    formik.values[item.name].phone
                                  }`}
                                  helperIcon={
                                    formik.touched[item.name] &&
                                    formik.errors[item.name] && <Erro />
                                  }
                                  helperText={
                                    (formik.touched[item.name] &&
                                      formik.errors[item.name]
                                        ?.completeNumber) ||
                                    (formik.touched[item.name] &&
                                      formik.errors[item.name]?.phone)
                                  }
                                  error={
                                    formik.touched[item.name] &&
                                    formik.errors[item.name] &&
                                    formik.errors[item.name]
                                  }
                                  defaultCountry={item.defaultCountry ?? 'BR'}
                                  onBlur={formik.handleBlur}
                                />
                              ) : (
                                <D.TmpInput
                                  id={item.name}
                                  name={item.name}
                                  label={item.label}
                                  placeholder={item.placeholder}
                                  type={item.input}
                                  value={formik.values[item.name]}
                                  handleChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  mask={item.mask}
                                  maxLength={item.maxLength}
                                  helperIcon={
                                    formik.touched[item.name] &&
                                    formik.errors[item.name] && <Erro />
                                  }
                                  helperText={
                                    formik.touched[item.name] &&
                                    formik.errors[item.name]
                                  }
                                  error={
                                    formik.touched[item.name] &&
                                    formik.errors[item.name] &&
                                    formik.errors[item.name]
                                  }
                                  disabled={
                                    item.name === 'neighborhood' ||
                                    item.name === 'address' ||
                                    item.name === 'city'
                                      ? disableFields
                                      : item.disabled
                                  }
                                  capitalize={item.capitalize}
                                />
                              ))}
                          </div>
                        ) : (
                          item.type === 'paragraph' && (
                            <div
                              className={
                                editType || newAgent
                                  ? item.editClass
                                  : item.class
                              }
                            >
                              <D.TmpSelect
                                id={item.name}
                                name={item.name}
                                label={item.label}
                                placeholder={item.placeholder}
                                listItems={
                                  // eslint-disable-next-line no-nested-ternary
                                  item.name === 'state'
                                    ? states?.map(country => ({
                                        label: country?.description,
                                        value: country?.initials,
                                      }))
                                    : item.name === 'status'
                                    ? item.content
                                    : [
                                        {
                                          label: item?.content,
                                          value: item?.content,
                                        },
                                      ]
                                }
                                handleChange={value => {
                                  formik.setFieldValue(item.name, value);
                                  formik.setFieldTouched(item.name, false);
                                }}
                                value={formik.values[item.name]}
                                helperIcon={
                                  states &&
                                  formik.touched[item.name] &&
                                  formik.errors[item.name]?.label && <Erro />
                                }
                                helperText={
                                  states &&
                                  formik.touched[item.name] &&
                                  formik.errors[item.name]?.label
                                }
                                error={
                                  states &&
                                  formik.touched[item.name] &&
                                  formik.errors[item.name]?.label &&
                                  formik.errors[item.name]?.label
                                }
                                disabled={item.disabled || disableFields}
                              />
                            </div>
                          )
                        );
                      }
                      return (
                        <div className={item.class}>
                          <D.TmpTypography component={item.type} size="small">
                            {item.content}
                          </D.TmpTypography>
                        </div>
                      );
                    })}
                  {editType || newAgent ? (
                    <div className="editButtonCEPSearch">
                      <D.TmpButton
                        startIcon={<ReactSVG src={I.busca} />}
                        type="secondary"
                        typeButton="button"
                        size="lg"
                        handleClick={() => handleGetCEP()}
                        loading={loadingCEP}
                      >
                        Buscar CEP
                      </D.TmpButton>
                    </div>
                  ) : (
                    <div />
                  )}
                </S.WrapperInstituteData>
              </D.TmpShape>
            </div>
          </S.WrapperCardShape>
          {editType || newAgent ? (
            <S.WrapperFooterActions>
              <D.TmpButton
                type="secondary"
                size="lg"
                typeButton="button"
                handleClick={() => {
                  // eslint-disable-next-line no-unused-expressions, no-nested-ternary
                  editType ? setEditType(false) : navigate(route ?? '/');
                }}
              >
                Cancelar
              </D.TmpButton>
              <D.TmpButton
                typeButton="submit"
                size="lg"
                loading={config()?.loadingButton}
              >
                {isEdit ? 'Salvar' : 'Cadastrar'}
              </D.TmpButton>
            </S.WrapperFooterActions>
          ) : (
            <div />
          )}
        </form>
      </S.Container>
      {/* <D.TmpDrawerContent
        title="CEP não encontrado"
        label="Deseja tentar novamente ou inserir os dados manualmente?"
        buttonLabelPrimary="Tentar novamente"
        buttonLabelSecondary="Inserir dados manualmente"
        handleButtonClickPrimary={() => {
          setOpenedDrawer(false);
          // handleReset(formik.resetForm);
          // setDisableFields(true);
        }}
        handleButtonClickSecondary={() => {
          setOpenedDrawer(false);
          // setDisableFields(false);
          // handleManuallyEnterZipCode();
        }}
        isOpened={openedDrawer}
      /> */}
    </S.Wrapper>
  );
}

FormInstitute.defaultProps = {
  isEdit: false,
  newAgent: false,
  route: '',
  field: '',
  config: () => {},
};

FormInstitute.propTypes = {
  isEdit: propTypes.bool,
  newAgent: propTypes.bool,
  route: propTypes.string,
  field: propTypes.string,
  config: propTypes.func,
};
