import { Messages } from '@b2b/core';

export default function getError(type, response) {
  const parseError = response?.data
    ? JSON.parse(response?.data?.errorMessage)
    : false;
  const error = parseError
    ? Messages?.errors?.find(item => item.errorCode === parseError?.errorCode)
    : { title: 'Erro desconhecido', message: 'Erro desconhecido' };
  if (type === 'title') {
    return error?.title || 'Erro';
  }
  return error?.message || 'Erro';
}
