import styled from 'styled-components';

export const Wrapper = styled.div``;
export const Title = styled.div`
  padding: 6px 0px;
  margin-bottom: 12px;
  margin-top: 8px;
  border-bottom: 1px solid #e0e0e0;
  .tmp-typography__paragraph--small {
    font-weight: 500;
    font-size: 12px;
    color: #a3a3a3;
  }
`;
export const ListItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-bottom: -2px;
`;
export const Item = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const ContentItem = styled.div`
  display: flex;
  gap: 4px;
`;
export const Label = styled.div`
  .tmp-typography__paragraph--small {
    font-weight: 400;
    font-size: 14px;
    color: #666666;
    line-height: 19.6px;
  }
`;
export const SubLabel = styled.div`
  .tmp-typography__paragraph--small {
    font-weight: 400;
    font-size: 12px;
    color: #a3a3a3;
    line-height: 19.6px;
  }
`;
export const ActionItem = styled.div`
  .tmp-button-icon {
    height: 16px;
    width: 16px;
    padding: 12px;
  }
  .tmp-icon {
    margin-left: 0.25px;
    margin-top: 2.5px;
    svg {
      width: 16px;
      height: 16px;
      path {
        fill: #2c6ef2;
      }
    }
  }
`;
