import { useEffect } from 'react';

import packageJson from '../../package.json';

const ClearCache = () => {
  const version = localStorage.getItem('version');
  useEffect(() => {
    if (version !== packageJson.version) {
      if ('caches' in window) {
        caches.keys().then(names => {
          names.forEach(name => {
            caches.delete(name);
          });
        });

        window.location.reload(true);
      }

      localStorage.clear();
      localStorage.setItem('version', packageJson.version);
    }
  }, []);
};

export default ClearCache;
