import * as DB2B from '@tempo/tempo-design-system-b2b/dist';

import { Utils } from '@b2b/core';
import propTypes from 'prop-types';

import { AccessCodeInputWrapper } from './style';

export function InputAccessCode({
  onChange,
  handleSubmit,
  error,
  handleError,
}) {
  const InputQuantity = 4;
  const inputOnChange = otp => {
    let targetValue = otp.trim();
    targetValue = targetValue.replace(Utils.RE_NODIGIT, '');

    const isTargetValueDigit = Utils.RE_DIGIT.test(targetValue);
    if (!isTargetValueDigit && targetValue !== '') {
      handleError(true, 'Campo inválido');
      return;
    }
    onChange(otp);
    if (otp.length === InputQuantity) {
      handleSubmit(otp);
    }
  };
  return (
    <AccessCodeInputWrapper>
      <DB2B.TmpOTPInput
        autoFocus
        isNumberInput
        length={InputQuantity}
        onChangeOTP={otp => inputOnChange(otp)}
        error={error}
        autoClean
      />
    </AccessCodeInputWrapper>
  );
}
InputAccessCode.defaultProps = {
  error: false,
};
InputAccessCode.propTypes = {
  onChange: propTypes.func.isRequired,
  handleSubmit: propTypes.func.isRequired,
  handleError: propTypes.func.isRequired,
  error: propTypes.bool,
};
