import { get, patch, post, put } from './api_methods';

async function updateInstitute(params) {
  const { data } = await put(`institute/update`, {
    ...params,
    hasCompanionRequired: params.hasCompanionRequired.toString(),
  });

  return data.result;
}

async function getInstituteData(id) {
  const { data } = await get(`institute/id?instituteCode=${id}`);
  return data.result;
}

async function updateStatusInstitute(instituteCode, statusCode) {
  const { data } = await patch(`backoffice/institute/change-status`, {
    instituteCode: instituteCode.toString(),
    statusCode: statusCode.toString(),
  });
  return data.result;
}

async function getSpecialtiesInstitutes(instituteCode) {
  const { data } = await get(
    `institute/specialty?instituteCode=${instituteCode}`
  );
  return data.result;
}

async function getSpecialtiesBackoffice(instituteCode) {
  const instituteCodeParams = instituteCode
    ? `?instituteCode=${instituteCode}`
    : '';

  const { data } = await get(
    `/backoffice/institute/specialty${instituteCodeParams}`
  );
  return data.result;
}

async function postSpecialtiesBackoffice(instituteCode, instituteSpecialties) {
  const { data } = await post(`/backoffice/institute/specialty/register`, {
    instituteCode: instituteCode.toString(),
    instituteSpecialties,
  });
  return data.result;
}

export {
  updateInstitute,
  getInstituteData,
  updateStatusInstitute,
  getSpecialtiesInstitutes,
  getSpecialtiesBackoffice,
  postSpecialtiesBackoffice,
};
