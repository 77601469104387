import { device } from '@b2b/core/src/utils';
import styled from 'styled-components';

export const ToastContainer = styled.div`
  > div {
    white-space: pre-line;
    transform: ${({ isOpened }) => isOpened && 'translate(-50%, 100px)'};
    h3 {
      font-size: 1rem;
    }
    @media ${device.noMobileOnly} {
      width: 312px;
    }
    @media ${device.mobile} {
      transform: ${({ isOpened }) =>
        isOpened ? 'translate(0%, 100px)' : 'translate(0%, -110%)'};
      left: 1.5rem;
      right: 1.5rem;
      z-index: 999;
    }
  }
`;
