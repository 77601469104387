import { Components } from '@b2b/core';
import PropTypes from 'prop-types';

export function Layout({ children }) {
  const isLogged = !!localStorage.getItem('@HSL-B2B:token');

  return (
    <>
      <Components.Header isLogged={isLogged} />
      {children}
    </>
  );
}

Layout.defaultProps = {
  children: null,
};

Layout.propTypes = {
  children: PropTypes.node,
};
