import * as DB2B from '@tempo/tempo-design-system-b2b/dist';

import { ptBR } from 'date-fns/locale';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import PropTypes from 'prop-types';
import { registerLocale } from 'react-datepicker';

import * as S from './styles';

dayjs.extend(customParseFormat);
registerLocale('pt-br', ptBR);

function dateIsValid(value) {
  const valid = dayjs(value, 'DD/MM/YYYY', true).isValid();
  if (valid) return true;
  return false;
}

export function CalendarInput({
  id,
  label,
  placeholder,
  startDate,
  setStartDate,
  disabled,
  onError,
  onCleanError,
  ...props
}) {
  return (
    <S.WrapperDatepicker>
      <DB2B.TmpDatepicker
        id={id}
        data-testid={id}
        type="date"
        disabled={disabled}
        dateFormat="dd/MM/yyyy"
        locale="pt-br"
        selected={startDate}
        label={label}
        placeholder={placeholder}
        minDate={new Date(dayjs().subtract(130, 'year'))}
        maxDate={new Date(dayjs())}
        handleChange={date => setStartDate(date)}
        popperModifiers={[
          {
            name: 'offset',
            options: {
              offset: [-16, 0],
            },
          },
        ]}
        onBlur={e => {
          const dataFormatted = e.target.value;
          if (!!dataFormatted === false) onCleanError();
          if (!!dataFormatted && !dateIsValid(dataFormatted)) {
            onError();
          } else {
            onCleanError();
          }
        }}
        className={disabled && 'formControlForDatePickerDisabled'}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
    </S.WrapperDatepicker>
  );
}

CalendarInput.defaultProps = {
  id: '',
  label: 'Data de Nascimento',
  placeholder: 'dd/mm/aaaa',
  disabled: false,
};

CalendarInput.propTypes = {
  startDate: PropTypes.string.isRequired,
  setStartDate: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  onCleanError: PropTypes.func.isRequired,
  id: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
};
