import { device } from '@b2b/core/src/utils';
import styled from 'styled-components';
import useMobileDetect from 'use-mobile-detect-hook';

const detectMobile = useMobileDetect();
export const Wrapper = styled.div`
  @media ${device.laptop} {
    min-height: ${({ platform }) => (platform ? '90vh' : '100vh')};
    display: flex;
    flex-direction: column;
  }
`;
export const Container = styled.div`
  @media ${device.laptop} {
    display: flex;
    flex: 1;
    justify-content: center;
  }
`;
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: auto;
`;

export const Actions = styled.div`
  @media ${device.laptop} {
    display: flex;
    justify-content: center;
    padding-bottom: ${({ paddingBottomIOS }) =>
      detectMobile.isIos() ? paddingBottomIOS ?? '32px' : '32px'};
  }
`;
export const ContentActions = styled.div`
  display: flex;
  justify-content: center;
  @media ${device.laptop} {
    flex-direction: column;
    align-items: center;
    flex: auto;
  }
`;
