import styled from 'styled-components';

export const WrapperDatepicker = styled.div`
  .react-datepicker {
    button {
      width: auto;
    }
    span {
      height: auto;
    }
  }
  span.tmp-icon {
    height: auto;
  }
`;
