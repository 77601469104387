export const routes = [
  {
    path: '/',
    title: 'Página Inicial',
  },
  {
    path: '/login',
    title: 'Login',
  },
  {
    path: '/codigo-de-acesso',
    title: 'Código de Acesso',
  },
  {
    path: '/meus-dados',
    title: 'Meus Dados',
  },
  {
    path: '/identificacao-do-paciente',
    title: 'Identificação do Paciente',
  },
  {
    path: '/cadastro-de-paciente',
    title: 'Cadastro de Paciente',
  },
  {
    path: '/sucesso-ao-cadastrar',
    title: 'Sucesso ao Cadastrar Paciente',
  },
  {
    path: '/tipo-de-atendimento',
    title: 'Tipo de Atendimento',
  },
  {
    path: '/fila-de-atendimento',
    title: 'Fila de Atendimento',
  },
  {
    path: '/informacao-do-sistema',
    title: 'Informação do sistema',
  },
  {
    path: '/encerrando-atendimento',
    title: 'Encerrando Atendimento',
  },
  {
    path: '/responsaveis',
    title: 'Responsáveis',
  },
  {
    path: '/atendimentos',
    title: 'Atendimentos',
  },
  {
    path: '/cadastro-instituicao',
    title: 'Cadastro de novo cliente',
  },
  {
    path: '/clientes',
    title: 'Clientes',
  },
  {
    path: '/detalhes-do-cliente',
    title: 'Dashboard do Cliente',
  },
  {
    path: '/detalhes-do-cliente/meus-dados',
    title: 'Dados do Cliente',
  },
  {
    path: '/detalhes-do-cliente/responsaveis',
    title: 'Responsáveis do Cliente',
  },
  {
    path: '/detalhes-do-cliente/atendimentos',
    title: 'Atendimentos do Cliente',
  },
];
