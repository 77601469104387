/* eslint-disable jsx-a11y/click-events-have-key-events */

/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

/* eslint-disable react/prop-types */
import React from 'react';

import * as I from '@tempo/tempo-assets/dist/svgs/icons';
import * as D from '@tempo/tempo-design-system-core/dist';

import classnames from 'classnames';
import { ReactSVG } from 'react-svg';

import './pagination.scss';
import { DOTS, usePagination } from './usePagination';

function Pagination(props) {
  const {
    onPageChange,
    totalCount,
    siblingCount = 6,
    currentPage,
    pageSize,
    className,
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  const lastPage = paginationRange[paginationRange.length - 1];
  return (
    <ul
      className={classnames('pagination-container', { [className]: className })}
    >
      <D.TmpButtonIcon
        icon={<ReactSVG src={I.seta_direcional_esquerda} />}
        size="sm"
        handleClick={onPrevious}
        disabled={currentPage === 1}
      />
      {paginationRange.map(pageNumber => {
        if (pageNumber === DOTS) {
          return <li className="pagination-item dots">&#8230;</li>;
        }

        return (
          <li
            className={classnames('pagination-item', {
              selected: pageNumber === currentPage,
            })}
            onClick={() => onPageChange(pageNumber)}
          >
            {pageNumber}
          </li>
        );
      })}
      <D.TmpButtonIcon
        icon={<ReactSVG src={I.seta_direcional_direita} />}
        size="sm"
        handleClick={onNext}
        disabled={currentPage === lastPage}
      />
    </ul>
  );
}

export default Pagination;
