import { useEffect } from 'react';

import { useLocation } from 'react-router-dom';

import { logPageView, routes } from '../index';

const LogPageViewAnalytics = typeSystem => {
  const location = useLocation();
  const excludePaths = ['/dados-do-responsavel'];

  useEffect(() => {
    function getLocation() {
      if (location.pathname.match(/\d+/)) {
        return location.pathname
          .replace(location.pathname.match(/\d+/)[0], '')
          .slice(0, -1);
      }
      return location.pathname;
    }
    const title = routes
      .filter(item => !item.path.includes(excludePaths))
      .find(item => item.path === getLocation())?.title;

    if (title) {
      logPageView(`${title} - ${typeSystem}`, location.pathname);
    }
  }, [location]);
};

export { LogPageViewAnalytics };
