import { useState } from 'react';

import { erro as Error } from '@tempo/tempo-assets/dist';
import * as D from '@tempo/tempo-design-system-core/dist';

import { Analytics, Components, Hooks, Utils } from '@b2b/core';
import { useLocation, useNavigate } from 'react-router-dom';
import { getCode, validateCode } from 'services/login.service';

import {
  Helper,
  InformationActions,
  InformationWrapper,
  Wrapper,
} from './styles';

export function AccessCode() {
  const navigate = useNavigate();
  const params = useLocation();
  const { loadingButton, setLoadingButton } = Hooks.useLoading();
  const { saveToken, saveAccountType } = Hooks.useUser();
  const { addToast } = Hooks.useToast();
  const [otp, setOtp] = useState('');
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState('');

  function handleError(state, message) {
    setError(state);
    setErrorText(message);
  }

  function handleResend() {
    getCode(params.state.doc)
      .then(() => {})
      .catch(({ response }) => {
        addToast({
          type: 'error',
          title: Utils.getError('title', response),
          message: Utils.getError('message', response) || 'Erro desconhecido',
        });
      });
  }

  function handleSubmit(value) {
    const code = value || otp;
    if (!Utils.RE_DIGIT.test(code) || code.length !== 4) {
      setOtp('');
      const firstField = document.querySelector(`input[id=accessCode-0]`);
      firstField.focus();
      handleError(true, 'Campo inválido');
    } else if (code === 0 || code === null || code === undefined) {
      handleError(true, 'Campo obrigatório');
    } else {
      handleError(false);
      setLoadingButton(true);
      validateCode(params.state.doc, params.state.responseCode, code)
        .then(user => {
          saveToken(user.accessToken);
          saveAccountType('Conta Institucional');
          Analytics.logEvent('Sucesso no login', {}, 'institute');
          navigate('/');
        })
        .catch(({ response }) => {
          setOtp('');
          Analytics.logEvent('Falha no código de acesso', {}, 'institute');
          addToast({
            type: 'error',
            title: Utils.getError('title', response),
            message: Utils.getError('message', response) || 'Erro desconhecido',
          });
        })
        .finally(() => setLoadingButton(false));
    }
  }

  return (
    <>
      <Components.Header titleSEO="Pronto Atendimento Digital" />
      <Wrapper>
        <img
          src={`${process.env.REACT_APP_S3_IMAGES_STORAGE}lock.png`}
          alt="Código de Acesso"
        />
        <InformationWrapper>
          <D.TmpTypography component="heading" size="large">
            Código de Acesso
          </D.TmpTypography>
          <D.TmpTypography component="paragraph" size="small">
            Insira o código que enviamos para o e-mail{' '}
            {Utils.splitMask(params.state.email, '@')} e telefone{' '}
            {Utils.splitMask(params.state.phone, '-')}.
          </D.TmpTypography>
          <Components.ResendTimer
            initialMinute={3}
            onResend={() => {
              handleResend();
            }}
          />
          <Components.InputAccessCode
            id="otp"
            name="otp"
            value={otp}
            valueLength={4}
            onChange={e => setOtp(e)}
            handleSubmit={value => handleSubmit(value)}
            handleError={(state, message) => handleError(state, message)}
            error={error}
          />

          {error && (
            <Helper>
              <span className="icon">
                <D.TmpIcon size="sm" icon={<Error />} />
              </span>
              {errorText}
            </Helper>
          )}
          <InformationActions>
            <D.TmpButton
              size="lg"
              type="secondary"
              handleClick={() => {
                navigate('/');
              }}
            >
              Sair
            </D.TmpButton>
            <D.TmpButton
              size="lg"
              loading={loadingButton}
              handleClick={() => handleSubmit()}
            >
              Entrar
            </D.TmpButton>
          </InformationActions>
        </InformationWrapper>
      </Wrapper>
    </>
  );
}
