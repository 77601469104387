import { useEffect, useState } from 'react';

import * as I from '@tempo/tempo-assets/dist/svgs/icons';
import * as D from '@tempo/tempo-design-system-core/dist';

import { Analytics, Components, Hooks, Utils } from '@b2b/core';
import {
  getAgents,
  updateStatusAgent,
} from '@b2b/core/src/services/agent.service';
import propTypes from 'prop-types';
import { useNavigate, useParams } from 'react-router-dom';
import { ReactSVG } from 'react-svg';

import { StatusIndicator } from './components/StatusIndicator';
import { successMessages } from './enums';
import * as S from './styles';

const pageSize = 5;
const numberOfLines = 20;
const addForFilteredPages = numberOfLines / pageSize;
const filteredPages = [];
let pageChanged = 1;
export function Agents({ isBackOffice }) {
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [agents, setAgents] = useState([]);
  const { getUser } = Hooks.useUser();
  const { getInstitute, loadingBackoffice, errorBackoffice, handleInstitute } =
    Hooks.useBackOffice();
  const { addToast } = Hooks.useToast();
  const { addDrawer, removeDrawer } = Hooks.useDrawer();
  const { code } = isBackOffice ? getInstitute() : getUser();

  const { changeForPage } = useParams();
  const currentItems = agents?.find(item => item.page === currentPage)?.items;

  function handleChangeStatus(rowsData, data, statusCode) {
    function getToast(type, status) {
      const findMessage = successMessages.find(
        item => item.statusCode === status
      );
      addToast({
        type,
        title: findMessage.title,
        message: findMessage.message,
      });
    }
    const { agentCode } = data;
    updateStatusAgent(agentCode, statusCode)
      .then(() => {
        filteredPages.splice(0, filteredPages.length);
        setAgents([]);
        // eslint-disable-next-line no-use-before-define
        handleChangePage(pageChanged);
        getToast('success', statusCode);
        Analytics.logEvent(
          successMessages.find(item => item.statusCode === statusCode)
            .analyticsEvent,
          {},
          isBackOffice ? 'backoffice' : 'institute'
        );
      })
      .catch(({ response }) => {
        setError(true);
        Analytics.logEvent(
          successMessages.find(item => item.statusCode === statusCode)
            .analyticsEventError,
          {},
          isBackOffice ? 'backoffice' : 'institute'
        );
        addToast({
          type: 'error',
          title: Utils.getError('title', response),
          message: Utils.getError('message', response) || 'Erro desconhecido',
        });
      });
  }
  function loadAgents(initialSearchLine, selectedPage) {
    const newSelectedPage = selectedPage === 0 ? 1 : selectedPage;
    setError(false);
    setLoading(true);

    getAgents(code, initialSearchLine, numberOfLines)
      .then(response => {
        const formattedResponse = response.agents.map(unformattedAgents => {
          return {
            ...unformattedAgents,
            status: <StatusIndicator number={unformattedAgents.status} />,
            actions: [
              {
                label: 'Detalhes',
                leadingIcon: <ReactSVG src={I.arquivo} />,
                handleClick: () => {
                  navigate(
                    `${
                      isBackOffice
                        ? '/detalhes-do-cliente/dados-do-responsavel'
                        : '/dados-do-responsavel'
                    }`,
                    {
                      state: {
                        newAgent: false,
                        isEdit: false,
                        agentCode: unformattedAgents.agentCode,
                        backDetails: true,
                        backRoute: `${
                          isBackOffice
                            ? `/detalhes-do-cliente/responsaveis/${pageChanged}`
                            : `/responsaveis/${pageChanged}`
                        }`,
                      },
                    }
                  );
                },
              },
              {
                label: 'Editar',
                leadingIcon: <ReactSVG src={I.editar} />,
                handleClick: () => {
                  navigate(
                    `${
                      isBackOffice
                        ? '/detalhes-do-cliente/dados-do-responsavel'
                        : '/dados-do-responsavel'
                    }`,
                    {
                      state: {
                        newAgent: false,
                        isEdit: true,
                        agentCode: unformattedAgents.agentCode,
                        backRoute: `${
                          isBackOffice
                            ? `/detalhes-do-cliente/responsaveis/${pageChanged}`
                            : `/responsaveis/${pageChanged}`
                        }`,
                      },
                    }
                  );
                },
              },
              {
                label: unformattedAgents.status === 1 ? 'Desativar' : 'Ativar',
                leadingIcon: (
                  <ReactSVG
                    src={unformattedAgents.status === 1 ? I.remover : I.sucesso}
                  />
                ),
                handleClick: data => {
                  addDrawer({
                    title: `Deseja mesmo ${
                      unformattedAgents.status === 1 ? 'desativar' : 'ativar'
                    } o responsável?`,
                    label:
                      unformattedAgents.status === 1
                        ? 'Os dados do responsável ainda ficarão visíveis para a instituição, caso deseje torná-lo ativo novamente.'
                        : 'Os dados do responsável ficarão visíveis para a instituição e seu acesso será concedido.',
                    buttonLabelPrimary: `Sim, quero ${
                      unformattedAgents.status === 1 ? 'desativar' : 'ativar'
                    }`,
                    buttonLabelSecondary: `Não quero ${
                      unformattedAgents.status === 1 ? 'desativar' : 'ativar'
                    }`,
                    handleButtonClickPrimary: () => {
                      if (unformattedAgents.status === 1) {
                        handleChangeStatus(data, unformattedAgents, 2);
                        removeDrawer();
                      } else {
                        handleChangeStatus(data, unformattedAgents, 1);
                        removeDrawer();
                      }
                    },
                    handleButtonClickSecondary: () => {
                      removeDrawer();
                    },
                  });
                },
              },
              {
                label: 'Excluir',
                leadingIcon: <ReactSVG src={I.excluir} />,
                handleClick: () => {
                  addDrawer({
                    title: 'Deseja mesmo excluir o responsável?',
                    label:
                      'Os dados do responsável serão excluídos definitivamente.',
                    buttonLabelPrimary: 'Sim, quero excluir',
                    buttonLabelSecondary: 'Não quero excluir',
                    handleButtonClickPrimary: data => {
                      handleChangeStatus(data, unformattedAgents, 3);
                      removeDrawer();
                    },
                    handleButtonClickSecondary: () => {
                      removeDrawer();
                    },
                  });
                },
              },
            ],
          };
        });
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < addForFilteredPages; i++) {
          const filteredPage = newSelectedPage + i;

          const initialOffset = pageSize * i;

          const endOffset = initialOffset + pageSize;

          setAgents(previousAgents => [
            ...previousAgents,
            {
              page: filteredPage,
              items: formattedResponse.slice(initialOffset, endOffset),
            },
          ]);

          if (!filteredPages.includes(filteredPage)) {
            filteredPages.push(filteredPage);
          }
        }
        setTotalCount(response.totalItems);
      })
      .catch(({ response }) => {
        const parseError = JSON.parse(response?.data?.errorMessage);
        setError(true);
        if (parseError?.errorCode === '#0034' && initialSearchLine === 0) {
          setIsEmpty(true);
        } else {
          setIsEmpty(false);
        }
      })
      .finally(() => setLoading(false));
  }

  function handleReload(isErrorBackoffice) {
    if (isErrorBackoffice) {
      handleInstitute();
    } else {
      loadAgents(0, 1);
    }
  }

  function handleChangePage(page) {
    const block = Math.ceil(page / (numberOfLines / pageSize));
    const offset = (block - 1) * numberOfLines;
    const pageReq = Math.ceil(offset / pageSize) + 1;
    setCurrentPage(page);
    pageChanged = page;
    if (!filteredPages.includes(page)) {
      loadAgents(offset ?? 0, pageReq);
    }
  }

  useEffect(() => {
    if (code) {
      loadAgents(0, 1);
    }
  }, [code]);

  useEffect(() => {
    if (changeForPage && totalCount > 0) {
      filteredPages.splice(0, filteredPages.length);
      handleChangePage(parseInt(changeForPage, 10));
    }
  }, [totalCount]);
  return (
    <S.Wrapper>
      <S.Container>
        <Components.Breadcrumbs />
        <S.TitleContainer>
          <D.TmpTypography component="heading" size="large">
            Responsáveis
          </D.TmpTypography>
          <S.TitleActionContainer>
            {/* <D.TmpInputSearch placeholder="Buscar por nome, Nº de documento ou status" /> */}
            <D.TmpButton
              type="primary"
              size="sm"
              startIcon={<ReactSVG src={I.adicionar} />}
              handleClick={() =>
                navigate(
                  `${
                    isBackOffice
                      ? '/detalhes-do-cliente/dados-do-responsavel'
                      : '/dados-do-responsavel'
                  }`,
                  {
                    state: {
                      newAgent: true,
                      isEdit: false,
                      backRoute: `${
                        isBackOffice
                          ? '/detalhes-do-cliente/responsaveis'
                          : '/responsaveis'
                      }`,
                    },
                  }
                )
              }
            >
              Novo responsável
            </D.TmpButton>
          </S.TitleActionContainer>
        </S.TitleContainer>
        <D.TmpShape>
          {loading || loadingBackoffice ? (
            <Components.Loading height="470px" />
          ) : (
            <Components.PaginatedItems
              error={error || (isBackOffice && errorBackoffice)}
              handleReload={() => handleReload(errorBackoffice)}
              loading={loading}
              currentItems={currentItems}
              currentPage={currentPage}
              totalCount={totalCount}
              handleChangePage={page => handleChangePage(page)}
              isEmpty={isEmpty}
              itensPerPage={pageSize}
              columns={[
                {
                  field: 'name',
                  label: 'Nome',
                },
                {
                  field: 'accessValue',
                  label: 'N° de Documento',
                },
                {
                  field: 'status',
                  label: 'Status',
                },
                {
                  field: 'actions',
                  label: '',
                },
              ]}
              actionMenu
              customTextEmpty="Até o momento, não temos responsáveis cadastrados."
            />
          )}
        </D.TmpShape>
      </S.Container>
    </S.Wrapper>
  );
}
Agents.defaultProps = {
  isBackOffice: false,
};
Agents.propTypes = {
  isBackOffice: propTypes.bool,
};
