import styled from 'styled-components';

export const StatusWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  justify-content: flex-start;
  .tmp-typography__paragraph--small {
    color: #292929;
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
  }
`;

export const StatusIndicator = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: ${props => (props.active ? '#3AA141' : '#CCCCCC')};
`;
