import styled from 'styled-components';

export const Wrapper = styled.section`
  height: ${({ height }) => height ?? '201px'};
  margin-top: ${({ marginTop }) => marginTop ?? '16px'};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;
