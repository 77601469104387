import { Utils } from '@b2b/core';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import * as Yup from 'yup';

import { getDocumentType, getGender, getHealthProfessional } from './enums';

const fieldsSchemas = Yup.object().shape({
  value: Yup.string().required('Campo obrigatório').nullable(),
  label: Yup.string().required('Campo obrigatório').nullable(),
});

export const schemas = isEdit =>
  Yup.object().shape({
    name: Yup.string()
      .min(6, 'Mínimo de 6 caracteres')
      .required('Campo obrigatório'),
    status: fieldsSchemas,
    gender: fieldsSchemas,
    document: fieldsSchemas,
    numberDocument: !isEdit
      ? Yup.string()
          .when('document[value]', {
            is: 'rne',
            then: Yup.string()
              .min(6, 'Mínimo de 6 caracteres')
              .required('Campo obrigatório'),
            otherwise: Yup.string(),
          })
          .when('document[value]', {
            is: 'passport',
            then: Yup.string()
              .min(6, 'Mínimo de 6 caracteres')
              .required('Campo obrigatório'),
            otherwise: Yup.string(),
          })
          .when('document[value]', {
            is: 'cpf',
            then: Yup.string()
              .transform(value => value.replace(/[^\d]/g, ''))
              .min(11, 'CPF inválido')
              .max(11, 'CPF inválido')
              .test('is-cpf', 'CPF inválido', value => {
                if (!value) {
                  return true;
                }
                return Utils.validateCPF(Utils.removeMask('cpf', value));
              })
              .required('Campo obrigatório')
              .nullable(),
            otherwise: Yup.string().nullable(),
          })
          .required('Campo Obrigatório')
      : Yup.string(),
    email: Yup.string('Campo inválido')
      .email('Campo inválido')
      .required('Campo obrigatório'),
    healthProfessional: fieldsSchemas,
    phoneNumber: Yup.object()
      .shape({
        ddd: Yup.string(),
        ddi: Yup.string(),
        phone: Yup.string().test('valid-celular', 'Campo inválido', value => {
          return value ? !Utils.allEqual(value) : true;
        }),
        completeNumber: Yup.string()
          .test('required-cell', 'Campo obrigatório', value => {
            if (!value || value.length < 1) {
              return false;
            }
            return true;
          })
          .test('valid-celular', 'Campo inválido', value => {
            if (value) {
              const isValid = isPossiblePhoneNumber(`+${value}`);
              if (isValid) return true;
              return false;
            }
            return false;
          }),
      })
      .required('Campo obrigatório'),
  });

export const SchemaInitialValues = agent => {
  const {
    name,
    status,
    gender,
    email,
    ddiPhoneNumber,
    dddPhoneNumber,
    phoneNumber,
    healthProfessional,
    jobDescription,
  } = agent;
  const { document, typeDocument } = Utils.completeDocument(agent);

  const documentFormatted =
    typeDocument === 'CPF' ? Utils.formatCPF(document) : document;

  const initialValues = {
    name: name ?? '',
    phoneNumber: {
      ddi: ddiPhoneNumber ?? '55',
      ddd: dddPhoneNumber ?? ' ',
      phone: phoneNumber ?? '',
    },
    status: status
      ? {
          label: status === 1 ? 'Ativo' : 'Inativo',
          value: status,
          selected: 0,
        }
      : {
          label: 'Ativo',
          value: 1,
          selected: 0,
        },

    document: typeDocument
      ? {
          label: typeDocument,
          value: getDocumentType(typeDocument),
        }
      : {
          label: 'CPF',
          value: 'cpf',
        },
    numberDocument: documentFormatted ?? '',
    email: email ?? '',
    healthProfessional: healthProfessional?.toString()
      ? {
          label: getHealthProfessional(jobDescription),
          value: healthProfessional,
        }
      : {
          label: null,
          value: null,
        },
    gender: gender
      ? {
          label: getGender(gender),
          value: gender,
        }
      : {
          label: null,
          value: null,
        },
  };
  return initialValues;
};
