import { useEffect } from 'react';

import { Services } from '@b2b/core';
import { useLocation } from 'react-router-dom';

const logout = (expired = false) => {
  localStorage.clear();
  if (expired) {
    window.location.href = '/informacao-do-sistema';
  } else {
    window.location.href = '/login';
  }
};
const parseJwt = token => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};

const AuthVerify = () => {
  const location = useLocation();

  useEffect(() => {
    const token = localStorage.getItem('@HSL-B2B:token');

    if (token) {
      const decodedJwt = parseJwt(token);
      const expireTokenTime = decodedJwt.exp * 1000;
      const expiredToken = expireTokenTime < Date.now();
      const timeYetToPass = 25 * 60 * 1000;

      if (expiredToken && location.key === 'default') {
        logout();
      }

      if (
        location.key !== 'default' &&
        location.pathname === '/fila-de-atendimento'
      ) {
        setInterval(() => {
          Services.refreshToken().then(refresh => {
            localStorage.setItem('@HSL-B2B:token', refresh.newAccessToken);
          });
        }, [timeYetToPass]);
      }

      if (
        expiredToken &&
        location.key !== 'default' &&
        location.pathname !== '/fila-de-atendimento'
      ) {
        logout(true);
      }
    }
  }, [location]);
};

export default AuthVerify;
