import * as D from '@tempo/tempo-design-system-core/dist';

import { useLocation, useNavigate } from 'react-router-dom';
import useBreadcrumbs from 'use-react-router-breadcrumbs';

import routes from './routes';
import * as S from './styles';

// eslint-disable-next-line react/prop-types
export function Breadcrumbs({ custom, marginBottom }) {
  const navigate = useNavigate();
  const location = useLocation();
  const breadcrumbs = useBreadcrumbs(routes, {
    excludePaths: [
      '/responsaveis/:page',
      '/detalhes-do-cliente/responsaveis/:page',
    ],
  });
  return (
    <S.Wrapper marginBottom={marginBottom}>
      {custom
        ? // eslint-disable-next-line react/prop-types, array-callback-return
          custom.map(({ label, action, noLink }) =>
            noLink ? (
              <D.TmpTypography key={location.pathname} component="paragraph">
                {label}
              </D.TmpTypography>
            ) : (
              <>
                <D.TmpTypography key={label} component="paragraph">
                  <S.Link onClick={action}>{label}</S.Link>
                </D.TmpTypography>
                <S.Separator key={label}>
                  <D.TmpTypography component="paragraph">
                    &nbsp;&gt;&nbsp;
                  </D.TmpTypography>
                </S.Separator>
              </>
            )
          )
        : breadcrumbs.map(({ match, breadcrumb }, index) =>
            breadcrumbs.length === index + 1 ? (
              <D.TmpTypography key={location.pathname} component="paragraph">
                {breadcrumb}
              </D.TmpTypography>
            ) : (
              <>
                <D.TmpTypography key={match.pathname} component="paragraph">
                  <S.Link onClick={() => navigate(match.pathname)}>
                    {breadcrumb}
                  </S.Link>
                </D.TmpTypography>
                <S.Separator key={match.pathname}>
                  <D.TmpTypography component="paragraph">
                    &nbsp;&gt;&nbsp;
                  </D.TmpTypography>
                </S.Separator>
              </>
            )
          )}
    </S.Wrapper>
  );
}
