import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';

import * as D from '@tempo/tempo-design-system-core/dist';

import propTypes from 'prop-types';

import LoadingContext from './LoadingContext';

const DrawerContext = createContext();

export default DrawerContext;

export function DrawerProvider({ children }) {
  const { loadingButtonPrimary, loadingButtonSecondary } =
    useContext(LoadingContext);
  const [drawer, setDrawer] = useState({
    isOpened: false,
    loadingButtonPrimary: true,
    loadingButtonSecondary: true,
  });
  const refDrawer = useRef(null);
  function isLoading() {
    if (loadingButtonPrimary || loadingButtonSecondary) return true;
    return false;
  }

  function removeDrawer() {
    setDrawer(oldDrawer => ({ ...oldDrawer, isOpened: false }));
  }

  const addDrawer = useCallback(
    item => {
      setDrawer({ isOpened: true, ...item });
    },
    [setDrawer]
  );

  const valuesDrawerProvider = useMemo(
    () => ({
      drawer,
      addDrawer,
      removeDrawer,
    }),
    [drawer, addDrawer, removeDrawer]
  );

  return (
    <DrawerContext.Provider value={valuesDrawerProvider}>
      {children}
      <D.TmpDrawerContent
        ref={refDrawer}
        title={drawer?.title}
        label={drawer?.label}
        buttonLabelPrimary={drawer?.buttonLabelPrimary}
        buttonLabelSecondary={drawer?.buttonLabelSecondary}
        buttonDisabledPrimary={loadingButtonSecondary}
        buttonDisabledSecondary={loadingButtonPrimary}
        handleButtonClickPrimary={
          !loadingButtonSecondary ? drawer?.handleButtonClickPrimary : () => {}
        }
        handleButtonClickSecondary={
          !loadingButtonPrimary ? drawer?.handleButtonClickSecondary : () => {}
        }
        loadingButtonPrimary={loadingButtonPrimary}
        loadingButtonSecondary={loadingButtonSecondary}
        isDoubleAction={!!drawer?.buttonLabelSecondary}
        isOpened={drawer?.isOpened}
        closeOnClickOutside={isLoading() ? false : drawer?.closeOnClickOutside}
        closeButtonPrimaryOnClick={drawer?.closeButtonPrimaryOnClick || false}
        closeButtonSecondaryOnClick={
          drawer?.closeButtonSecondaryOnClick || false
        }
        handleClose={() => {
          removeDrawer();
        }}
      />
    </DrawerContext.Provider>
  );
}

DrawerProvider.propTypes = {
  children: propTypes.node.isRequired,
};
