export default function validateCPF(strCPF) {
  let Soma;
  let Resto;
  Soma = 0;
  if (strCPF === '00000000000') return false;

  // eslint-disable-next-line no-plusplus
  for (let i = 1; i <= 9; i++)
    Soma += parseInt(strCPF?.substring(i - 1, i), 10) * (11 - i);
  Resto = (Soma * 10) % 11;

  if (Resto === 10 || Resto === 11) Resto = 0;
  if (Resto !== parseInt(strCPF?.substring(9, 10), 10)) return false;

  Soma = 0;
  // eslint-disable-next-line no-plusplus
  for (let i = 1; i <= 10; i++)
    Soma += parseInt(strCPF?.substring(i - 1, i), 10) * (12 - i);
  Resto = (Soma * 10) % 11;

  if (Resto === 10 || Resto === 11) Resto = 0;
  if (Resto !== parseInt(strCPF?.substring(10, 11), 10)) return false;
  return true;
}
