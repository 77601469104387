/* eslint-disable no-nested-ternary */
import { Hooks, Utils } from '@b2b/core';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import * as Yup from 'yup';

const fieldsHealthInsurance = Yup.object()
  .required('Campo obrigatório')
  .shape({
    description: Yup.string().required('Campo obrigatório'),
  })
  .nullable();

const fieldsSchemas = Yup.object()
  .required('Campo obrigatório')
  .shape({
    label: Yup.string().required('Campo obrigatório').nullable(),
    value: Yup.string().required('Campo obrigatório').nullable(),
  });

export const schemas = isBackOffice =>
  Yup.object().shape({
    fantasyName: Yup.string()
      .min(6, 'Mínimo 6 caracteres')
      .required('Campo obrigatório')
      .nullable(),
    corporateName: Yup.string()
      .min(6, 'Mínimo 6 caracteres')
      .required('Campo obrigatório')
      .nullable(),
    email: Yup.string('Campo inválido')
      .email('Campo inválido')
      .required('Campo obrigatório'),
    phoneNumber: Yup.object()
      .shape({
        ddd: Yup.string(),
        ddi: Yup.string(),
        phone: Yup.string().test('valid-celular', 'Campo inválido', value => {
          return value ? !Utils.allEqual(value) : true;
        }),
        completeNumber: Yup.string()
          .test('required-cell', 'Campo obrigatório', value => {
            if (!value || value.length < 1) {
              return false;
            }
            return true;
          })
          .test('valid-celular', 'Campo inválido', value => {
            if (value) {
              const isValid = isPossiblePhoneNumber(`+${value}`);
              if (isValid) return true;
              return false;
            }
            return false;
          }),
      })
      .required('Campo obrigatório'),
    cep: Yup.string()
      .test('valid-cep', 'Campo inválido', value => {
        const isValid = Utils.validateCEP(value);
        if (isValid) return true;
        return false;
      })
      .min(8, 'Campo inválido')
      .transform(value => value.replace(/[^\d]/g, ''))
      .required('Campo obrigatório')
      .nullable(),
    state: fieldsSchemas,
    city: Yup.string()
      .min(3, 'Campo inválido')
      .max(255, 'Excedeu o máximo de caracteres')
      .required('Campo obrigatório')
      .nullable(),
    neighborhood: Yup.string()
      .min(3, 'Campo inválido')
      .max(255, 'Excedeu o máximo de caracteres')
      .required('Campo obrigatório')
      .nullable(),
    address: Yup.string()
      .min(3, 'Campo inválido')
      .max(255, 'Excedeu o máximo de caracteres')
      .required('Campo obrigatório')
      .nullable(),
    addressNumber: Yup.number()
      .typeError('Campo inválido')
      .moreThan(1, 'Campo inválido')
      .required('Campo obrigatório')
      .positive('Campo inválido')
      .integer('Campo inválido')
      .nullable(),
    complement: Yup.string()
      .max(255, 'Excedeu o máximo de caracteres')
      .nullable(),
    convention: isBackOffice && fieldsHealthInsurance,
    category: isBackOffice && fieldsHealthInsurance,
    plan: isBackOffice && fieldsHealthInsurance,
    hasCompanionRequired: fieldsSchemas,
  });

export function SchemaInitialValues(
  isBackOffice,
  name,
  fantasyName,
  address,
  addressNumber,
  cep,
  state,
  city,
  cnpj,
  complement,
  email,
  ddiPhoneNumber,
  dddPhoneNumber,
  phoneNumber,
  neighborhood,
  hasCompanionRequired,
  status,
  specialties,
  convention,
  category,
  plan
) {
  const {
    selectedHealthPlan,
    selectedHealthCategory,
    selectedHealthInsuranceAgreement,
  } = Hooks.useBackOffice();

  const initialValues = {
    corporateName: name ?? '',
    fantasyName: fantasyName ?? '',
    address: address ?? '',
    addressNumber: addressNumber ?? '',
    cep: cep ?? '',
    state: state
      ? {
          label: state.description,
          value: state.initials,
          selected: 0,
        }
      : {
          label: null,
          value: null,
          selected: 0,
        },
    city: city ?? '',
    cnpj: cnpj ?? '',
    complement: complement ?? '',
    email: email ?? '',
    phoneNumber: {
      ddi: ddiPhoneNumber ?? '55',
      ddd: dddPhoneNumber ?? ' ',
      phone: phoneNumber ?? '',
    },
    neighborhood: neighborhood ?? '',
    status: status
      ? {
          label: status === 'A' ? 'Ativo' : 'Inativo',
          value: status,
          selected: status === 'A' ? 0 : 1,
        }
      : {
          label: null,
          value: null,
          selected: '',
        },
    convention: isBackOffice
      ? selectedHealthInsuranceAgreement
      : convention
      ? {
          label: convention,
          value: convention,
          selected: 0,
        }
      : {
          label: null,
          value: null,
          selected: 0,
        },
    category: isBackOffice
      ? selectedHealthCategory
      : category
      ? {
          label: category,
          value: category,
          selected: 0,
        }
      : {
          label: null,
          value: null,
          selected: 0,
        },
    plan: isBackOffice
      ? selectedHealthPlan
      : plan
      ? {
          label: plan,
          value: plan,
          selected: 0,
        }
      : {
          label: null,
          value: null,
          selected: 0,
        },
    hasCompanionRequired: hasCompanionRequired?.toString()
      ? {
          label: hasCompanionRequired === 0 ? 'Não' : 'Sim',
          value: hasCompanionRequired,
          selected: hasCompanionRequired,
        }
      : {
          label: null,
          value: null,
          selected: '',
        },
    specialties: specialties ?? {
      label: null,
      value: null,
      selected: '',
    },
  };

  return initialValues;
}
