import { post } from './api_methods';

async function getAttendances(
  instituteCode,
  attendanceStatus,
  initialSearchLine,
  numberOfLines
) {
  const { data } = await post(`attendance/all-attendances`, {
    instituteCode: instituteCode.toString(),
    attendanceStatus:
      attendanceStatus !== null
        ? attendanceStatus.toString()
        : attendanceStatus,
    initialSearchLine: initialSearchLine.toString(),
    numberOfLines: numberOfLines.toString(),
  });
  return data.result;
}

async function sendReportAttendance(
  cnpj,
  instituteCode,
  dateStart,
  email,
  name
) {
  const { data } = await post(`attendance/report`, {
    document: cnpj.toString(),
    instituteCode: instituteCode.toString(),
    dateStart: new Date(dateStart).toISOString(),
    dateEnd: new Date().toISOString(),
    email,
    name,
  });

  return data.result;
}

export { getAttendances, sendReportAttendance };
