/* eslint-disable no-console */
import React from 'react';

import { Hooks, Pages, Utils } from '@b2b/core';
import propTypes from 'prop-types';

export function NoMobileRoutes({ children }) {
  const { width } = Hooks.useWindowDimensions();
  return Utils.verifyMobile() || width < 1024 ? <Pages.NoMobile /> : children;
}

NoMobileRoutes.defaultProps = {
  children: null,
};

NoMobileRoutes.propTypes = {
  children: propTypes.node,
};
