import { device } from '@b2b/core/src/utils';
import styled from 'styled-components';

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: rgba(44, 110, 242, 0.02);
  width: 100%;
  min-height: calc(100vh - 89px);
  @media ${device.laptop} {
    display: inherit;
  }
`;

export const Container = styled.section`
  padding: 1.75rem 5.5rem 3.75rem;
  width: 100%;
  max-width: 1366px;
  box-sizing: border-box;
`;

export const WrapperTypography = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${({ newAgent }) => (newAgent ? 'center' : 'space-between')};
  margin-bottom: 1.5rem;
  .containerEditor {
    display: flex;
    align-items: center;
  }
  .tmp-typography__heading--medium {
    color: #231f20;
    font-weight: 700;
    font-size: 24px;
    margin-left: 0.5rem;
  }
  path {
    fill: ${({ editType }) => (editType ? 'rgb(0, 0, 0)' : '#2C6EF2')};
  }
  .containerExclude {
    width: 131px;
    button {
      width: 100%;
      min-width: auto;
    }
    path {
      fill: ${({ loadingExclude }) => (loadingExclude ? '#FFFFFF' : '#2C6EF2')};
    }
  }
  @media ${device.laptop} {
    justify-content: space-between;
    margin: 0 1rem;
    width: 95%;
  }
`;

export const WrapperCardShape = styled.div`
  .cardShape {
    grid-column: 1 / 13;
    border-radius: 16px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
    > div {
      border: 0;
      padding: 0;
    }
    @media ${device.laptop} {
      > div {
        padding: 32px 0;
      }
    }
    .input-wrapper {
      min-width: auto;
      input:disabled {
        color: rgba(0, 0, 0, 0.32);
      }
    }
  }
  @media ${device.laptop} {
    width: 100%;
  }
`;

export const WrapperGuardianData = styled.section`
  padding: 2.5rem;
  display: grid;
  align-items: center;
  grid-template-columns: repeat(12, 1fr);
  gap: 0.5rem 1.5rem;
  .loadingFeedback {
    grid-column: 6 / 8;
  }
  .tmp-typography__heading--small {
    color: #231f20;
    font-size: 16px;
  }
  .guardianDataTitle {
    grid-column: 1 / 13;
    margin-bottom: 1.5rem;
    h1 {
      font-size: 18px;
      color: #231f20;
    }
  }
  .guardianDataLabelName {
    grid-row: 2;
    grid-column: 1 / 8;
  }
  .guardianDataName {
    grid-row: 3;
    grid-column: 1 / 8;
    margin-bottom: 1.5rem;
  }
  .editGuardianDataName {
    grid-row: 3;
    grid-column: 1 / 8;
    > div {
      height: 120px;
    }
  }

  .guardianDataLabelStatus {
    grid-row: 2;
    grid-column: 8 / 13;
  }
  .guardianDataStatus {
    grid-row: 3;
    grid-column: 8 / 13;
    margin-bottom: 1.5rem;
  }
  .editGuardianDataStatus {
    grid-row: 3;
    grid-column: 8 / 13;
    > div {
      height: 120px;
    }
    button {
      min-width: auto;
      width: 100%;
    }
  }

  .guardianDataLabelGender {
    grid-row: 4;
    grid-column: 1 / 4;
  }
  .guardianDataGender {
    grid-row: 5;
    grid-column: 1 / 4;
    margin-bottom: 1.5rem;
  }
  .editGuardianDataGender {
    grid-row: 5;
    grid-column: 1 / 4;
    > div {
      height: 120px;
    }
    button {
      min-width: auto;
      width: 100%;
    }
  }

  .guardianDataLabelDocument {
    grid-row: 4;
    grid-column: 4 / 8;
  }
  .guardianDataDocument {
    grid-row: 5;
    grid-column: 4 / 8;
    margin-bottom: 1.5rem;
  }
  .editGuardianDataDocument {
    grid-row: 5;
    grid-column: 4 / 8;
    > div {
      height: 120px;
    }
    button {
      min-width: auto;
      width: 100%;
    }
  }

  .guardianDataLabelNumberDocument {
    grid-row: 4;
    grid-column: 8 / 13;
    width: 100%;
    margin-left: 0;
  }
  .guardianDataNumberDocument {
    grid-row: 5;
    grid-column: 8 / 13;
    margin-left: 0;
    margin-bottom: 1.5rem;
  }
  .editGuardianDataNumberDocument {
    grid-row: 5;
    grid-column: 8 / 13;
    margin-left: 0;
    > div {
      height: 120px;
    }
  }

  .guardianDataLabelPhone {
    grid-row: 6;
    grid-column: 1 / 4;
  }
  .guardianDataPhone {
    grid-row: 7;
    grid-column: 1 / 4;
    margin-bottom: 1.5rem;
  }
  .editGuardianDataPhone {
    grid-row: 7;
    grid-column: 1 / 4;
    > div {
      height: 120px;
    }
  }

  .guardianDataLabelEmail {
    grid-row: 6;
    grid-column: 4 / 8;
  }
  .guardianDataEmail {
    grid-row: 7;
    grid-column: 4 / 8;
    margin-bottom: 1.5rem;
  }
  .editGuardianDataEmail {
    grid-row: 7;
    grid-column: 4 / 8;
    > div {
      height: 120px;
    }
  }

  .guardianDataLabelHealthProfessional {
    grid-row: 6;
    grid-column: 8 / 13;
  }
  .guardianDataHealthProfessional {
    grid-row: 7;
    grid-column: 8 / 13;
    margin-bottom: 1.5rem;
  }
  .editGuardianDataHealthProfessional {
    grid-row: 7;
    grid-column: 8 / 13;
    > div {
      height: 120px;
    }
    button {
      min-width: auto;
      width: 100%;
    }
  }
`;

export const WrapperFooterActions = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  margin-top: 2.5rem;
  justify-content: flex-end;
  button {
    min-width: auto;
  }
  @media ${device.laptop} {
    padding: 0 1.5rem;
    flex-direction: column;
  }
`;
