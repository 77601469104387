import { device } from '@b2b/core/src/utils';
import styled from 'styled-components';

export const AccessCodeInputWrapper = styled.div`
  display: flex;
  gap: 13px;
  .input-wrapper {
    min-width: auto;
    width: 66px;
    height: 66px;
    input {
      text-align: center;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
    }
    @media ${device.laptop} {
      input {
        font-size: 32px;
      }
    }
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
  @media ${device.laptop} {
    width: 100%;
    .input-wrapper {
      display: flex;
      width: 100%;
      max-width: 86px;
      height: 86px;
    }
    div {
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }
  }
`;
