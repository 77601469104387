import { get } from './api_methods';

async function getState() {
  const { data } = await get(`location/states`);
  return data.result;
}

async function getCEP(params) {
  const { data } = await get(`location/zipcode?zipcode=${params}`);
  return data.result;
}

export { getCEP, getState };
