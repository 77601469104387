import { get, patch, post, put } from './api_methods';

async function getAgentCount(instituteCode) {
  const { data } = await get(
    `institute/agent-count?instituteCode=${instituteCode}`
  );
  return data.result;
}

async function getAgents(instituteCode, initialSearchLine, numberOfLines) {
  const { data } = await post(`institute/all-agents`, {
    instituteCode: instituteCode.toString(),
    initialSearchLine: initialSearchLine.toString(),
    numberOfLines: numberOfLines.toString(),
  });
  return data.result;
}

async function getUniqueAgent(agentCode) {
  const { data } = await get(`agent/id?agentCode=${agentCode}`);
  return data.result;
}

async function registerAgent(params) {
  const { data } = await post(`agent/register`, params);
  return data.result;
}

async function updateAgent(params) {
  const { data } = await put(`agent/update`, params);
  return data.result;
}

async function updateStatusAgent(agentCode, statusCode) {
  const { data } = await patch(`agent/change-status`, {
    agentCode: agentCode.toString(),
    statusCode: statusCode.toString(),
  });
  return data.result;
}

export {
  getAgentCount,
  getAgents,
  getUniqueAgent,
  registerAgent,
  updateAgent,
  updateStatusAgent,
};
