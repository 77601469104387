import React from 'react';

import { TmpLoading } from '@tempo/tempo-design-system-core/dist';

import propTypes from 'prop-types';

import * as S from './styles';

export function Loading({ height, marginTop, label }) {
  return (
    <S.Wrapper height={height} marginTop={marginTop}>
      <div>
        <TmpLoading label={label} />
      </div>
    </S.Wrapper>
  );
}

Loading.defaultProps = {
  height: '201px',
  label: 'Carregando...',
  marginTop: '16px',
};
Loading.propTypes = {
  height: propTypes.string,
  marginTop: propTypes.string,
  label: propTypes.string,
};
