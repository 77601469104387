export const successMessages = [
  {
    statusCode: 1,
    title: 'Status alterado com sucesso',
    message:
      'Os dados do responsável estão  visíveis para a instituição e seu acesso está concedido.',
    analyticsEvent: 'Responsável ativado',
    analyticsEventError: 'Erro ao ativar responsável',
  },
  {
    statusCode: 2,
    title: 'Status alterado com sucesso',
    message:
      'Os dados do responsável ainda ficarão visíveis para a instituição, caso deseje torná-lo ativo novamente.',
    analyticsEvent: 'Responsável desativado',
    analyticsEventError: 'Erro ao desativar responsável',
  },
  {
    statusCode: 3,
    title: 'Responsável excluído com sucesso',
    message: 'Os dados do responsável foram excluídos definitivamente.',
    analyticsEvent: 'Responsável excluído',
    analyticsEventError: 'Erro ao excluir responsável',
  },
];
