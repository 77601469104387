import styled from 'styled-components';

export const ContainerTable = styled.div`
  margin-top: 16px;
  ul {
    min-width: 276px;
  }
  .tmp-list-item__leading-icon {
    padding-top: 6px;
  }
`;
