/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';

import Erro from '@tempo/tempo-assets/dist/react/icons/erro';
import * as I from '@tempo/tempo-assets/dist/svgs/icons';
import * as DB2B from '@tempo/tempo-design-system-b2b/dist';
import * as D from '@tempo/tempo-design-system-core/dist';

import { Analytics, Components, Hooks, Services, Utils } from '@b2b/core';
import * as CEPService from '@b2b/core/src/services/cep.service';
import {
  getHealthInsurance,
  patchHealthInsurance,
} from '@b2b/core/src/services/healthInsurance.service';
import {
  getSpecialtiesBackoffice,
  getSpecialtiesInstitutes,
  postSpecialtiesBackoffice,
  updateInstitute,
} from '@b2b/core/src/services/institute.service';
import { useFormik } from 'formik';
import propTypes from 'prop-types';
import { ReactSVG } from 'react-svg';

import { InstituteData } from './instituteData';
import { SchemaInitialValues, schemas } from './schemas';
import * as S from './styles';

export function FormDataInstitute({ isBackOffice }) {
  const { addToast } = Hooks.useToast();
  const { saveToken } = Hooks.useUser();
  const {
    saveInstitute,
    loadingBackoffice,
    errorBackoffice,
    handleInstitute,
    selectedHealthPlan,
    selectedHealthCategory,
    selectedHealthInsuranceAgreement,
    healthInsuranceData,
    healthInsurance,
    setHealthInsuranceData,
    setSelectedHealthPlan,
    setSelectedHealthCategory,
    setSelectedHealthInsuranceAgreement,
  } = Hooks.useBackOffice();
  const { setSpecialties } = isBackOffice
    ? Hooks.useBackOffice()
    : Hooks.useUser();
  const { states, fullyStateByInitials, stateDescriptionByInitials } =
    Hooks.useStateList();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingCEP, setLoadingCEP] = useState(false);
  const [loadingHealthInsurance, setLoadingHealthInsurance] = useState(false);
  const [disableFields, setDisableFields] = useState(true);
  const [editType, setEditType] = useState(false);
  const [openedDrawer, setOpenedDrawer] = useState(false);
  const [previousValuesInsurance, setPreviousValuesInsurance] = useState(false);
  const instituteData = InstituteData(isBackOffice);
  const [previousValues, setPreviousValues] = useState(false);
  const {
    accessToken,
    code,
    codeTasy,
    name,
    fantasyName,
    address,
    addressNumber,
    cep,
    state,
    city,
    cnpj,
    complement,
    email,
    ddiPhoneNumber,
    dddPhoneNumber,
    phoneNumber,
    neighborhood,
    status,
    hasCompanionRequired,
    specialties,
  } = instituteData;

  const formik = useFormik({
    enableReinitialize: false,
    validationSchema: schemas(isBackOffice),
    initialValues:
      previousValues.cnpj?.length > 0
        ? previousValues
        : SchemaInitialValues(
            isBackOffice,
            name,
            fantasyName,
            address,
            addressNumber,
            cep,
            fullyStateByInitials(state),
            city,
            cnpj,
            complement,
            email,
            ddiPhoneNumber,
            dddPhoneNumber,
            phoneNumber,
            neighborhood,
            hasCompanionRequired,
            status,
            specialties
          ),
    onSubmit: values => {
      const initial = {
        instituteCode: code.toString(),
        fantasyName,
        status,
        ddiPhoneNumber,
        dddPhoneNumber,
        phoneNumber,
        email,
        cep,
        state,
        city,
        neighborhood,
        address,
        hasCompanionRequired: String(hasCompanionRequired),
        addressNumber: addressNumber.toString(),
        complement: complement !== null ? complement : '',
      };
      const initialSpecialties = isBackOffice
        ? {
            specialties: specialties
              .filter(filtered => filtered.selected)
              .map(item => item.specialtyCode),
          }
        : { specialties: specialties.map(item => item.specialtyCode) };

      const initialHealth = {
        healthInsurance: healthInsuranceData?.healthInsurance,
        healthCategory: healthInsuranceData?.healthCategory,
        healthPlan: healthInsuranceData?.healthPlan,
      };

      const updateTextDescriptionsCompare = {
        healthInsurance: selectedHealthInsuranceAgreement?.description,
        healthCategory: selectedHealthCategory?.description,
        healthPlan: selectedHealthPlan?.description,
      };

      const updateHealthValues = {
        healthInsurance: selectedHealthInsuranceAgreement?.healthInsuranceCode,
        healthCategory: selectedHealthCategory?.healthCategory,
        healthPlan: selectedHealthPlan?.healthPlan,
      };

      const updateSpecialties = isBackOffice
        ? values.specialties
            .filter(filtered => filtered.selected)
            .map(item => item.specialtyCode)
        : specialties;
      function getUpdate(returnString, onlyCode, addStorage) {
        const storage = addStorage && {
          name,
          cnpj,
          accessToken,
          codeTasy,
          complement,
        };

        const addressNumberInstitute = addStorage
          ? { addressNumber: values?.addressNumber }
          : {
              addressNumber: returnString
                ? values?.addressNumber.toString()
                : values?.addressNumber,
            };
        const codeUser = onlyCode
          ? { code: returnString ? code.toString() : code }
          : { instituteCode: returnString ? code.toString() : code };
        const params = {
          ...codeUser,
          fantasyName: Utils.capitalize(values?.fantasyName),
          ...storage,
          status: isBackOffice ? values?.status?.value : initial.status,
          email: values?.email,
          ddiPhoneNumber: values?.phoneNumber.ddi,
          dddPhoneNumber: values?.phoneNumber.ddd,
          phoneNumber: values?.phoneNumber.phone,
          cep: values?.cep,
          ...addressNumberInstitute,
          address: Utils.capitalize(values?.address),
          complement: Utils.capitalize(values?.complement),
          neighborhood: Utils.capitalize(values?.neighborhood),
          city: Utils.capitalize(values?.city),
          state: values?.state.value,
          hasCompanionRequired: isBackOffice
            ? String(values?.hasCompanionRequired.value)
            : String(initial.hasCompanionRequired),
        };

        return params;
      }

      const userUpdateValues = getUpdate(true, false, false);
      const isEqual = Utils.compareObjects(initial, userUpdateValues);
      const isEqualHealth = Utils.compareObjects(
        initialHealth,
        updateTextDescriptionsCompare
      );
      const isEqualSpecialties = Utils.compareObjects(
        initialSpecialties,
        updateSpecialties
      );

      if (isBackOffice && isEqual && isEqualHealth && isEqualSpecialties) {
        setEditType(false);
      } else if (!isBackOffice && isEqual) {
        setEditType(false);
      } else {
        setLoading(true);
        updateInstitute(userUpdateValues)
          .then(async res => {
            if (res && !isBackOffice) {
              await Services.refreshToken().then(refresh => {
                saveToken(refresh.newAccessToken);
              });
            } else if (res && isBackOffice) {
              saveInstitute(null);
              if (!isEqualHealth) {
                await patchHealthInsurance(
                  code,
                  updateHealthValues.healthInsurance,
                  updateHealthValues.healthCategory,
                  updateHealthValues.healthPlan
                )
                  .then(() => {
                    setHealthInsuranceData(updateTextDescriptionsCompare);
                    setPreviousValues(false);
                  })
                  .catch(errorHealth => {
                    setHealthInsuranceData(false);
                    setSelectedHealthInsuranceAgreement(
                      previousValuesInsurance.selectedHealthInsuranceAgreement
                    );
                    setSelectedHealthCategory(
                      previousValuesInsurance.selectedHealthCategory
                    );
                    setSelectedHealthPlan(
                      previousValuesInsurance.selectedHealthPlan
                    );

                    return Promise.reject(errorHealth);
                  });
              }
            }
          })
          .then(() => {
            if (!isEqualSpecialties && isBackOffice) {
              postSpecialtiesBackoffice(code, updateSpecialties)
                .then(() => {
                  setSpecialties(updateSpecialties);
                })
                .catch(errorSpecialties => Promise.reject(errorSpecialties));
            }
          })
          .then(() => {
            setPreviousValues(false);
            Analytics.logEvent(
              'Dados da instituição editado',
              {},
              isBackOffice ? 'backoffice' : 'institute'
            );
            addToast({
              type: 'success',
              title: 'Dados alterados com sucesso',
              message: 'Suas alterações foram aplicadas em nosso sistema.',
            });
          })
          .catch(({ response }) => {
            Analytics.logEvent(
              'Erro ao editar dados da instituição',
              {},
              isBackOffice ? 'backoffice' : 'institute'
            );
            setHealthInsuranceData(false);
            addToast({
              type: 'error',
              title: Utils.getError('title', response),
              message:
                Utils.getError('message', response) || 'Erro desconhecido',
            });
          })
          .finally(() => {
            healthInsurance();
            setEditType(false);
            setLoading(false);
          });
      }
    },
  });

  function callToError(response) {
    addToast({
      type: 'error',
      title: Utils.getError('title', response),
      message: Utils.getError('message', response) || 'Erro desconhecido',
    });
  }

  function handleFieldsGetCEP(disable, response, fieldsControl) {
    setDisableFields(disable);
    fieldsControl.setFieldValue('city', Utils.capitalize(response?.city) || '');
    fieldsControl.setFieldValue(
      'neighborhood',
      Utils.capitalize(response?.neighborhood) || ''
    );
    fieldsControl.setFieldValue(
      'address',
      Utils.capitalize(response?.address) || ''
    );
  }

  function handleManuallyEnterZipCode() {
    formik.setFieldValue('state.label', '');
    formik.setFieldValue('state.value', '');
    formik.setFieldValue('city', '');
    formik.setFieldValue('neighborhood', '');
    formik.setFieldValue('address', '');
    formik.setFieldValue('addressNumber', '');
    formik.setFieldValue('complement', '');
  }

  function handleReset(resetForm) {
    resetForm();
  }

  function handleGetCEP() {
    setLoadingCEP(true);
    setDisableFields(true);
    CEPService.getCEP(Utils.removeMask('cep', formik.values?.cep))
      .then(response => {
        const fullyState = fullyStateByInitials(response?.state);
        formik.setFieldValue('state.label', fullyState?.description || '');
        formik.setFieldValue('state.value', fullyState?.initials || '');
        handleFieldsGetCEP(true, response, formik);
        formik.setFieldValue('addressNumber', '');
        formik.setFieldValue('complement', '');
      })
      .catch(({ response }) => {
        setOpenedDrawer(true);
        const { statusCode, errorCode } =
          JSON.parse(response?.data?.errorMessage) || false;
        if (statusCode === 500) {
          if (errorCode !== '#0040') {
            callToError(response);
          }
        } else {
          handleFieldsGetCEP(false, response, formik);
          formik.setFieldValue(
            'state.label',
            stateDescriptionByInitials(state)
          );
          formik.setFieldValue('city', Utils.capitalize(city));
          formik.setFieldValue('neighborhood', Utils.capitalize(neighborhood));
          formik.setFieldValue('address', Utils.capitalize(address));
        }
      })
      .finally(() => {
        formik.setFieldValue('convention', selectedHealthInsuranceAgreement);
        formik.setFieldValue('category', selectedHealthCategory);
        formik.setFieldValue('plan', selectedHealthPlan);
        setLoadingCEP(false);
      });
  }

  function handleChangeAddress(e) {
    const value = e.target.value.replace(/\D/g, '');

    formik.setFieldValue('addressNumber', value);
  }

  function getListSpecialties() {
    if (isBackOffice) {
      getSpecialtiesBackoffice(code)
        .then(res => {
          // eslint-disable-next-line no-console
          setSpecialties(res);
        })
        .catch(() => {
          setError(true);
        })
        .finally(() => setLoadingHealthInsurance(false));
    } else {
      getSpecialtiesInstitutes(code)
        .then(response => {
          const adaptSpecialties = response.map(item => {
            return {
              ...item,
              selected: true,
            };
          });
          setSpecialties(adaptSpecialties);
        })
        .catch(() => {
          setError(true);
        })
        .finally(() => setLoadingHealthInsurance(false));
    }
  }

  function healthInsuranceInstitute() {
    setLoadingHealthInsurance(true);
    getHealthInsurance(code)
      .then(response => {
        setError(false);
        formik.setFieldValue(
          'convention.label',
          response?.healthInsurance || ''
        );
        formik.setFieldValue('category.label', response?.healthCategory || '');
        formik.setFieldValue('plan.label', response?.healthPlan || '');
      })
      .catch(() => {
        setError(true);
      })
      .finally(() => getListSpecialties());
  }

  function isLoaded() {
    if (isBackOffice && loadingBackoffice) {
      return false;
    }
    if (loadingHealthInsurance) {
      return false;
    }
    return true;
  }

  useEffect(() => {
    if (code) {
      healthInsuranceInstitute();
    }
  }, [code]);

  useEffect(() => {
    setPreviousValuesInsurance({
      selectedHealthInsuranceAgreement,
      selectedHealthCategory,
      selectedHealthPlan,
    });
    formik.setFieldValue('convention', selectedHealthInsuranceAgreement);
    formik.setFieldValue('category', selectedHealthCategory);
    formik.setFieldValue('plan', selectedHealthPlan);
    if (previousValues?.cnpj?.length > 0) {
      formik.setValues(previousValues);
    }
  }, [editType]);

  useEffect(() => {
    if (
      previousValues?.cnpj?.length === 0 ||
      !previousValues ||
      !previousValues.cnpj
    ) {
      setPreviousValues(formik.values);
    }
  }, [formik]);

  useEffect(() => {
    formik.setFieldValue('convention', selectedHealthInsuranceAgreement);
    formik.setFieldValue('category', selectedHealthCategory);
    formik.setFieldValue('plan', selectedHealthPlan);
  }, [
    selectedHealthInsuranceAgreement,
    selectedHealthCategory,
    selectedHealthPlan,
  ]);
  function loadingFeedback() {
    if (isBackOffice && errorBackoffice) {
      return (
        <Components.ReloadError
          data-testid="message-toast"
          marginTop="0px"
          loading={loading}
          reload={() => {
            handleInstitute();
          }}
        />
      );
    }
    if (error) {
      return (
        <Components.ReloadError
          data-testid="message-toast"
          marginTop="0px"
          loading={loading}
          reload={() => {
            healthInsuranceInstitute();
          }}
          height={editType ? '612px' : '361px'}
        />
      );
    }

    return (
      <div className="loadingFeedback">
        <Components.Loading height="573px" />
      </div>
    );
  }
  return (
    <S.Wrapper>
      <S.Container>
        <div className="containerBreadcrumbs">
          <Components.Breadcrumbs />
        </div>
        <S.WrapperTypography editType={editType}>
          <D.TmpTypography component="heading" size="medium">{`${
            editType ? 'Editar meus dados' : 'Meus dados'
          }`}</D.TmpTypography>
          <D.TmpButtonIcon
            size="lg"
            handleClick={() => setEditType(true)}
            disabled={editType}
            icon={<ReactSVG src={I.editar} />}
          />
        </S.WrapperTypography>
        <form onSubmit={formik.handleSubmit}>
          <S.WrapperCardShape>
            <div className="cardShape">
              <D.TmpShape>
                <S.WrapperInstituteData loadingCEP={loadingCEP}>
                  <div className="instituteDataTitle">
                    <D.TmpTypography>DADOS GERAIS</D.TmpTypography>
                  </div>
                  {!editType && !isLoaded()
                    ? loadingFeedback()
                    : instituteData.getInstituteData.map(item => {
                        if (editType) {
                          return item.type === 'paragraph' &&
                            item.typeInput !== 'select' &&
                            item.typeInput !== 'checkboxGroup' ? (
                            <div
                              className={editType ? item.editClass : item.class}
                            >
                              {item.type === 'paragraph' &&
                                // eslint-disable-next-line no-nested-ternary
                                (item.mask === 'ddiphone' ? (
                                  <DB2B.TmpPhoneInput
                                    id={item.name}
                                    name={item.name}
                                    label={item.label}
                                    placeholder={item.placeholder}
                                    value={formik.values[item.name]}
                                    getValue={value => {
                                      if (value !== formik.values[item.name]) {
                                        formik.setFieldValue(item.name, value);
                                      }
                                    }}
                                    defaultValue={`${
                                      formik.values[item.name].ddi
                                    }${formik.values[item.name].ddd}${
                                      formik.values[item.name].phone
                                    }`}
                                    helperIcon={
                                      formik.touched[item.name] &&
                                      formik.errors[item.name] && <Erro />
                                    }
                                    helperText={
                                      (formik.touched[item.name] &&
                                        formik.errors[item.name]
                                          ?.completeNumber) ||
                                      (formik.touched[item.name] &&
                                        formik.errors[item.name]?.phone)
                                    }
                                    error={
                                      formik.touched[item.name] &&
                                      formik.errors[item.name] &&
                                      formik.errors[item.name]
                                    }
                                    defaultCountry={
                                      formik.values[item.name].ddi !== null
                                        ? null
                                        : 'BR'
                                    }
                                    onBlur={formik.handleBlur}
                                  />
                                ) : item.typeInput === 'autoComplete' ? (
                                  <DB2B.TmpAutoCompleteInput
                                    listItems={item?.listItems}
                                    id={item.name}
                                    name={item.name}
                                    label={item.label}
                                    placeholder={item.placeholder}
                                    labelKey={item.labelKey}
                                    loading={item.loading}
                                    value={item.value}
                                    key={item.name}
                                    handleChange={value => {
                                      formik.setFieldValue(item.name, value);
                                      formik.setFieldTouched(item.name, false);
                                      item.handleChange(value);
                                    }}
                                    helperIcon={
                                      states &&
                                      !item.disabled &&
                                      formik.touched[item.name] &&
                                      formik.errors[item.name]?.description && (
                                        <Erro />
                                      )
                                    }
                                    helperText={
                                      states &&
                                      !item.disabled &&
                                      formik.touched[item.name] &&
                                      formik.errors[item.name]?.description
                                    }
                                    error={
                                      states &&
                                      !item.disabled &&
                                      formik.touched[item.name] &&
                                      formik.errors[item.name]?.description &&
                                      formik.errors[item.name]?.description
                                    }
                                    disabled={item.disabled}
                                  />
                                ) : (
                                  <D.TmpInput
                                    id={item.name}
                                    name={item.name}
                                    label={item.label}
                                    placeholder={item.placeholder}
                                    type={item.input}
                                    disabled={
                                      item.name === 'neighborhood' ||
                                      item.name === 'address' ||
                                      item.name === 'city'
                                        ? disableFields
                                        : item.disabled
                                    }
                                    value={formik.values[item.name]}
                                    handleChange={
                                      item.name === 'addressNumber'
                                        ? e => handleChangeAddress(e)
                                        : formik.handleChange
                                    }
                                    mask={item.mask}
                                    maxLength={item.maxLength}
                                    helperIcon={
                                      formik.touched[item.name] &&
                                      formik.errors[item.name] && <Erro />
                                    }
                                    helperText={
                                      formik.touched[item.name] &&
                                      formik.errors[item.name]
                                    }
                                    error={
                                      formik.touched[item.name] &&
                                      formik.errors[item.name] &&
                                      formik.errors[item.name]
                                    }
                                    onBlur={formik.handleBlur}
                                    capitalize={item.capitalize}
                                  />
                                ))}
                            </div>
                          ) : (
                            item.type === 'paragraph' && (
                              <div
                                className={
                                  editType ? item.editClass : item.class
                                }
                              >
                                {item.typeInput === 'select' && (
                                  <D.TmpSelect
                                    id={item.name}
                                    name={item.name}
                                    label={item.label}
                                    placeholder={item.placeholder}
                                    listItems={
                                      item.listItem ?? [
                                        {
                                          label: item.content,
                                          value: item.content,
                                        },
                                      ]
                                    }
                                    handleChange={value => {
                                      formik.setFieldValue(item.name, value);
                                      formik.setFieldTouched(item.name, false);
                                    }}
                                    value={formik.values[item.name]}
                                    disabled={
                                      item.name === 'state'
                                        ? disableFields
                                        : item.disabled
                                    }
                                    helperIcon={
                                      states &&
                                      formik.touched[item.name] &&
                                      formik.errors[item.name]?.label && (
                                        <Erro />
                                      )
                                    }
                                    helperText={
                                      states &&
                                      formik.touched[item.name] &&
                                      formik.errors[item.name]?.label
                                    }
                                    error={
                                      states &&
                                      formik.touched[item.name] &&
                                      formik.errors[item.name]?.label &&
                                      formik.errors[item.name]?.label
                                    }
                                    // onBlur={() =>
                                    //   formik.setFieldTouched(item.name, true)
                                    // }
                                  />
                                )}
                                {item.typeInput === 'checkboxGroup' && (
                                  <DB2B.TmpCheckboxGroup
                                    listItems={item?.listItems}
                                    mainKey={item.mainKey}
                                    selectedKey={item.selectedKey}
                                    label={item.label}
                                    name={item.name}
                                    showSelectedAsFalse={
                                      item.showSelectedAsFalse
                                    }
                                    handleChange={value => {
                                      formik.setFieldValue(item.name, value);
                                      formik.setFieldTouched(item.name, false);
                                      item.handleChange(value);
                                    }}
                                    helperIcon={
                                      states &&
                                      formik.touched[item.name] &&
                                      formik.errors[item.name]?.description && (
                                        <Erro />
                                      )
                                    }
                                    helperText={
                                      states &&
                                      formik.touched[item.name] &&
                                      formik.errors[item.name]?.description
                                    }
                                    helperTitle={item.helperTitle}
                                    error={
                                      states &&
                                      formik.touched[item.name] &&
                                      formik.errors[item.name]?.description &&
                                      formik.errors[item.name]?.description
                                    }
                                    disabled={item.disabled}
                                    startLines={item.startLines}
                                    maxColumns={item.startLines}
                                  />
                                )}
                              </div>
                            )
                          );
                        }
                        return (
                          <div className={item.class}>
                            <D.TmpTypography component={item.type} size="small">
                              {item.content}
                            </D.TmpTypography>
                          </div>
                        );
                      })}
                  {editType && (
                    <div className="editButtonCEPSearch">
                      <D.TmpButton
                        startIcon={<ReactSVG src={I.busca} />}
                        type="secondary"
                        typeButton="button"
                        size="lg"
                        handleClick={() => handleGetCEP()}
                        loading={loadingCEP}
                      >
                        Buscar CEP
                      </D.TmpButton>
                    </div>
                  )}
                </S.WrapperInstituteData>
              </D.TmpShape>
            </div>
          </S.WrapperCardShape>
          {editType && (
            <S.WrapperFooterActions>
              <D.TmpButton
                type="secondary"
                size="lg"
                typeButton="button"
                handleClick={() => {
                  setEditType(false);
                  handleReset(formik.resetForm);
                  setDisableFields(true);
                }}
              >
                Cancelar
              </D.TmpButton>
              <D.TmpButton typeButton="submit" size="lg" loading={loading}>
                Salvar
              </D.TmpButton>
            </S.WrapperFooterActions>
          )}
        </form>
      </S.Container>
      <D.TmpDrawerContent
        title="CEP não encontrado"
        label="Deseja tentar novamente ou inserir os dados manualmente?"
        buttonLabelPrimary="Tentar novamente"
        buttonLabelSecondary="Inserir dados manualmente"
        handleButtonClickPrimary={() => {
          setOpenedDrawer(false);
          handleReset(formik.resetForm);
          setDisableFields(true);
        }}
        handleButtonClickSecondary={() => {
          setOpenedDrawer(false);
          setDisableFields(false);
          handleManuallyEnterZipCode();
        }}
        isOpened={openedDrawer}
      />
    </S.Wrapper>
  );
}

FormDataInstitute.defaultProps = {
  isBackOffice: false,
};
FormDataInstitute.propTypes = {
  isBackOffice: propTypes.bool,
};
