import { Pages } from '@b2b/core';

export function Maintenance() {
  return (
    <Pages.InfoSystem
      image={`${process.env.REACT_APP_S3_IMAGES_STORAGE}maintenance.png`}
      title="Nosso sistema está indisponível no momento"
      content="Pedimos desculpas pelo ocorrido. Estamos trabalhando para que ele retorne o mais breve possível."
      enabledButton={false}
    />
  );
}
