import propTypes from 'prop-types';
import { QRCodeCanvas } from 'qrcode.react';

export function QRCode({ url }) {
  return (
    <QRCodeCanvas
      id="QRCode"
      value={url}
      size={152}
      bgColor="#FFF"
      fgColor="#292929"
      level="L"
    />
  );
}

QRCode.propTypes = {
  url: propTypes.string.isRequired,
};
