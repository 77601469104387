import { useEffect } from 'react';

import { Utils } from '@b2b/core';

import { getGender, getHealthProfessional } from './enums';

export function AgentData(handleGetAgent, agent, maskType) {
  const isNew = !handleGetAgent && true;
  const {
    name,
    status,
    gender,
    email,
    ddiPhoneNumber,
    dddPhoneNumber,
    phoneNumber,
    healthProfessional,
    jobDescription,
  } = agent;
  const { document, typeDocument } = Utils.completeDocument(agent);
  const documentFormatted =
    typeDocument === 'CPF' ? Utils.formatCPF(document) : document;

  useEffect(() => {
    if (!isNew) {
      handleGetAgent();
    }
  }, []);
  const getAgentData = [
    {
      class: 'guardianDataLabelName',
      type: 'heading',
      content: 'Nome',
    },
    {
      class: 'guardianDataName',
      editClass: 'editGuardianDataName',
      type: 'paragraph',
      content: isNew ? '' : Utils.capitalize(name),
      label: 'Nome',
      name: 'name',
      placeholder: 'Digite o nome completo',
      capitalize: true,
    },
    {
      class: 'guardianDataLabelStatus',
      type: 'heading',
      content: 'Status',
    },
    {
      class: 'guardianDataStatus',
      editClass: 'editGuardianDataStatus',
      type: 'paragraph',
      // eslint-disable-next-line no-nested-ternary
      content: isNew ? '' : status === 1 ? 'Ativo' : 'Inativo',
      listItem: [
        {
          label: 'Ativo',
          value: '1',
        },
        {
          label: 'Inativo',
          value: '2',
        },
      ],
      label: 'Status',
      name: 'status',
      placeholder: 'Selecione o status',
      typeInput: 'select',
    },
    {
      class: 'guardianDataLabelGender',
      type: 'heading',
      content: 'Sexo',
    },
    {
      class: 'guardianDataGender',
      editClass: 'editGuardianDataGender',
      type: 'paragraph',
      content: isNew ? '' : getGender(gender),
      listItem: [
        {
          label: 'Masculino',
          value: 'M',
        },
        {
          label: 'Feminino',
          value: 'F',
        },
      ],
      label: 'Sexo',
      name: 'gender',
      placeholder: 'Selecione o sexo',
      typeInput: 'select',
    },
    {
      class: 'guardianDataLabelDocument',
      type: 'heading',
      content: 'Documento',
    },
    {
      class: 'guardianDataDocument',
      editClass: 'editGuardianDataDocument',
      type: 'paragraph',
      content: isNew ? '' : typeDocument,
      listItem: [
        {
          label: 'CPF',
          value: 'cpf',
        },
        {
          label: 'RNE',
          value: 'rne',
        },
        {
          label: 'Passaporte',
          value: 'passport',
        },
      ],
      label: 'Documento',
      name: 'document',
      placeholder: 'Selecione o documento',
      typeInput: 'select',
    },
    {
      class: 'guardianDataLabelNumberDocument',
      type: 'heading',
      content: 'Nº do documento',
    },
    {
      class: 'guardianDataNumberDocument',
      editClass: 'editGuardianDataNumberDocument',
      type: 'paragraph',
      content: isNew ? '' : documentFormatted,
      mask: maskType === 'cpf' ? 'cpf' : null,
      label: 'Nº do documento',
      name: 'numberDocument',
      placeholder: 'Digite o N° do documento',
    },
    {
      class: 'guardianDataLabelPhone',
      type: 'heading',
      content: 'Celular',
    },
    {
      class: 'guardianDataPhone',
      editClass: 'editGuardianDataPhone',
      type: 'paragraph',
      content: isNew
        ? ''
        : Utils.formatCellphone(
            `${ddiPhoneNumber}${dddPhoneNumber}${phoneNumber}`
          ),
      label: 'Celular',
      name: 'phoneNumber',
      mask: 'ddiphone',
      placeholder: '+55 (00) 00000-0000',
    },
    {
      class: 'guardianDataLabelEmail',
      type: 'heading',
      content: 'E-mail',
    },
    {
      class: 'guardianDataEmail',
      editClass: 'editGuardianDataEmail',
      type: 'paragraph',
      content: isNew ? '' : email,
      label: 'E-mail',
      name: 'email',
      placeholder: 'Digite o e-mail',
    },
    {
      class: 'guardianDataLabelHealthProfessional',
      type: 'heading',
      content: 'É profissional de saúde?',
    },
    {
      class: 'guardianDataHealthProfessional',
      editClass: 'editGuardianDataHealthProfessional',
      type: 'paragraph',
      content: isNew ? '' : getHealthProfessional(jobDescription),
      listItem: [
        {
          label: 'Não',
          value: 0,
        },
        {
          label: 'Sim, médico(a)',
          value: 1,
          jobDescription: 'médico(a)',
        },
        {
          label: 'Sim, enfermeiro(a)',
          value: 1,
          jobDescription: 'enfermeiro(a)',
        },
      ],
      label: 'É profissional de saúde?',
      name: 'healthProfessional',
      placeholder: 'Selecione uma opção',
      typeInput: 'select',
    },
  ];
  return {
    getAgentData,
    name,
    status,
    gender,
    email,
    ddiPhoneNumber,
    dddPhoneNumber,
    phoneNumber,
    healthProfessional,
    document,
    typeDocument,
  };
}
