import styled from 'styled-components';

export const WrapperResend = styled.div`
  padding-bottom: ${props =>
    props.paddingBottom ? props.paddingBottom : '2rem'};
  padding-top: ${props => (props.paddingTop ? props.paddingTop : '0.5rem')};
  .tmp-typography__paragraph--small {
    margin: 0 !important;
  }
`;
